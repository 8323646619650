import LvColorpicker from 'lightvue/color-picker';

export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            url: this.$serverURL + this.$api.componentUI.landingPageURL,
            cmsData: {},
            dataLoaded: false,
            colors: [
                "#E91E63",
                "#F44336",
                "#9C27B0",
                "#673AB7",
                "#3F51B5",
                "#2196F3",
                "#03A9F4",
                "#00BCD4",
                "#009688",
                "#4CAF50",
                "#8BC34A",
                "#CDDC39",
                "#FFEB3B",
                "#FFC107",
                "#FF9800",
                "#795548"
            ]
        }
    },
    async created() {
        document.title = "Dispo - Landing Page";
        await this.getData();
        this.dataLoaded = true;
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async update() {
            try {
                let data = {
                    backgroundColor: this.cmsData.backgroundColor ? this.cmsData.backgroundColor : '#FFFFFF',
                    pageTitleFontColor: this.cmsData.pageTitleFontColor ? this.cmsData.pageTitleFontColor : '#212530',
                    fontColor: this.cmsData.fontColor ? this.cmsData.fontColor : '#8b6ea9',
                    checkboxFontColor: this.cmsData.checkboxFontColor ? this.cmsData.checkboxFontColor : '#035f74',
                    buttonColor: this.cmsData.buttonColor ? this.cmsData.buttonColor : '#6cba40',
                    buttonFontColor: this.cmsData.buttonFontColor ? this.cmsData.buttonFontColor : '#212322',
                }
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}