<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Retailer</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Retailer Service</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
					<div class="btn-group">
						<button type="button" class="btn btn-primary" @click="$router.push('/default-retailer')">
							Default Retailer
						</button>
					</div>
				</div>
      </div>
      <h6 class="mb-0 text-uppercase">Retailer List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Image</th>
                  <th>Contact No</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ row.retailerName }}</td>
                  <td>{{ (row.email && row.email!='null') ? row.email : "N/A" }}</td>
                  <td>
                    <img :src="row.featuredImage" height="80" />
                  </td>
                  <td>{{ (row.retailerMobileNumber && row.retailerMobileNumber != "null") ? row.retailerMobileNumber : "N/A" }}</td>
                  <td>{{ $filters.formatDate(row.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="`edit-retailer/${row.id}`"
                        @click.prevent="$router.push(`edit-retailer/${row.id}`)"
                        class="edit-button"
                      >
                        <i class="bx bxs-edit"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Image</th>
                  <th>Contact No</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Retailer</h5>
            <button
              type="button"
              class="btn-close"
              id="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Retailer Name:</label>
              <input type="text" disabled v-model="singleData.retailerName" class="form-control" />
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Contact Number:</label>
              <input type="text" v-model="singleData.retailerMobileNumber" class="form-control" />
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Email:</label>
              <input type="email" v-model="singleData.email" class="form-control" />
            </div>
            <div class="mb-3">
              <label
                class="form-label"
              >Image: [Preferred Image Size: 510X500, Max Upload Limit: 1MB]</label>
              <input class="form-control" @change="onImageChange" type="file" ref="image" />
            </div>
            <div class="mb-3">
              <div class="col-md-12">
                <img
                  v-if="previewImage"
                  class="img-thumbnail"
                  :src="previewImage"
                  style="height: 150px;"
                />
                <img
                  v-else-if="singleData.retailerImage"
                  class="img-thumbnail"
                  :src="singleData.retailerImage"
                  style="height: 150px;"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="update">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>

<script src="../js/retailer.js"></script>