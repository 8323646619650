<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Contact Us Section One</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Contact Us Section One</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Add New Section</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label">Retailer Name:</label>
                    <input type="text" v-model="retailerName" class="form-control" disabled />
                  </div>
                  <div class="mb-3">
                    <label
                      class="form-label"
                    >Icon: [Preferred Iocn Size: 71X71, Max Upload Limit: 1MB]</label>
                    <input class="form-control" @change="onIconChange" type="file" ref="icon" />
                  </div>
                  <div class="mb-3">
                    <div class="col-md-3">
                      <img v-if="previewIcon" :src="previewIcon" />
                      <img v-else src="../../../../assets/images/default.png" height="100" />
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Title:</label>
                    <input type="text" v-model="title" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Subtitle:</label>
                    <textarea type="text" v-model="subTitle" class="form-control" rows="5"></textarea>
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Type:</label>
                    <select v-model="selectedDepartment" class="form-select form-control mb-3">
                      <option
                        class="p-1"
                        v-for="(el, index) in typeList"
                        :key="index"
                        :value="el"
                      >{{ el }}</option>
                    </select>
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Button Text:</label>
                    <input type="text" v-model="buttonText" class="form-control" rows="5" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Button Link:</label>
                    <input type="text" v-model="buttonLink" class="form-control" rows="5" />
                  </div>
                  <div class="d-grid">
                    <button v-on:click="save" type="button" class="btn btn-primary">Create</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/add-contact-us-section-one.js"></script>