import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.home.heroSectionLeftCmsDefalut,
            cmsData: {},
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            }
        }
    },
    async created() {
        document.title = "Dispo - Home | Here Section Left CMS Default";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
                if (Object.keys(this.cmsData).length == 0) {
                    this.cmsData.retailerName = this.name;
                }
            }
            else {
                this.cmsData = {};
            }
        },
        async updateCms() {
            try {
                let data = {
                    'title': this.cmsData.title,
                    'description': this.cmsData.description,
                    'buttonText': this.cmsData.buttonText,
                    'buttonLink': this.cmsData.buttonLink,
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}