import { authHeader } from "../../../auth";
import Chart from 'chart.js/auto';
import LinkShortcut from "../../layouts/template/LinkShortcut";
import jwt_decode from "jwt-decode";

export default {
    components: {
        LinkShortcut
    },
    data() {
        return {
            setURL: this.$serverURL + this.$api.dashboard.requestURL,
            totalRetailer: 0,
            totalCareer: 0,
            totalCareerApplicant: 0,
            totalSubscriber: 0,
            totalArticle: 0,
            totalContact: 0,
            topRetailer: {},
            todayTotalCareerApplicant: 0,
            todayTotalContact: 0,

            role: 0,
            isAdmin: false,
        }
    },
    async created() {
        document.title = "Dispo - Dashboard";
        const token = localStorage?.getItem('token');
        const decoded = jwt_decode(token);
        this.role = decoded?.role;
        this.isAdmin = this.role == 2;
        this.isHR = this.role == 4;

        await this.getDashBoardData();
        await this.loadJS();
        await this.getPieChart();

    },
    methods: {
        async loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js",
                "/core/assets/plugins/metismenu/js/metisMenu.min.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        async getPieChart() {
            new Chart(document.getElementById("retailerChart"), {
                type: 'doughnut',
                data: {
                    labels: this.topRetailer.labels,
                    datasets: [{
                        label: 'Top Retailer',
                        data: this.topRetailer.data,
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(50, 168, 68)',
                            'rgb(50, 131, 168)'
                        ],
                        hoverOffset: 4
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    parsing: {
                        key: 'nested.value'
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Top Five Retailers',
                            padding: {
                                top: 10,
                                bottom: 30
                            }
                        }
                    }
                }
            });
        },
        async getDashBoardData() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.totalRetailer = response.data.data.totalRetailer;
                    this.totalCareer = response.data.data.totalCareer;
                    this.totalCareerApplicant = response.data.data.totalCareerApplicant;
                    this.totalSubscriber = response.data.data.totalSubscriber;
                    this.totalArticle = response.data.data.totalArticle;
                    this.totalContact = response.data.data.totalContact;
                    this.topRetailer = response.data.data.topFiveRetailer;
                    this.todayTotalCareerApplicant = response.data.data.todayTotalCareerApplicant;
                    this.todayTotalContact = response.data.data.todayTotalContact;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!" + ' ' + error
                });
            });
        }
    }
}