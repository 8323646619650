<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Mobile Banner CMS</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">Mobile Banner CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Mobile Banner CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-lg-12 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="col-6 mb-3">
												<label class="form-label">Today Special Mobile Banner Link:</label>
												<input type="text" v-model="cmsData.todaySpecialMobileBannerLink"
													class="form-control" id="inputPageHeaderTitle"
													placeholder="Enter today special mobile banner link" />
												<label for="inputProductTitle" class="form-label mt-3">
													Today Special Mobile Banner Image:
													<strong>
														[Preferred Image Size: 333X102, Max Upload Limit:
														1MB]
													</strong>
												</label>
												<div class="col-12">
													<input class="form-control mt-3" type="file" ref="ref1" accept="image/*"
														@change="handleUploadImage('todaySpecialMobileBannerImage', 'imageOne', 'ref1', $event)" />
												</div>
												<div class="mt-3">
													<img style="position: relative; left: 11px" v-if="preview.imageOne"
														:src="preview.imageOne" height="100" />
													<img style="position: relative; left: 11px"
														v-else-if="cmsData.todaySpecialMobileBannerImage"
														:src="cmsData.todaySpecialMobileBannerImage" height="100" />
												</div>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Common Promotional Banner Two Link:</label>
												<input type="text" v-model="cmsData.commonPromotionalBannerTwoLink"
													class="form-control" id="inputPageHeaderTitle"
													placeholder="Enter common promotional banner two link" />

												<label for="inputProductTitle" class="form-label mt-3">
													Common Promotional Banner Two Image:
													<strong>
														[Preferred Image Size: 355X145, Max Upload Limit:
														1MB]
													</strong>
												</label>
												<div class="col-12">
													<input class="form-control mt-3" type="file" ref="ref3" accept="image/*"
														@change="handleUploadImage('commonPromotionalBannerTwoImage', 'imageThree', 'ref3', $event)" />
												</div>
												<div class="mt-3">
													<img style="position: relative; left: 11px" v-if="preview.imageThree"
														:src="preview.imageThree" height="100" />
													<img style="position: relative; left: 11px"
														v-else-if="cmsData.commonPromotionalBannerTwoImage"
														:src="cmsData.commonPromotionalBannerTwoImage" height="100" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="d-grid">
									<button type="submit" class="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/mobile-banner-cms.js"></script>