import { authHeader } from '../../../auth';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "EditCareer",
    data() {
        return {
            editData: [],
            jobType: '',
            title: '',
            location: '',
            description: '',
            salary: '',
            careerId: '',
            editor: ClassicEditor,
            careerDepartment: "",
            department: {}
        }
    },
    async created() {
        document.title = "Dispo - Career";
        await this.getCareerDepartment();
    },
    mounted() {
        if(this.$route.params.id != undefined) {
            this.careerId = this.$route.params.id;
        }
        this.getCareerData(this.careerId);
    },
    methods: {
        getCareerDepartment: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.career.careerDepartmentURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.careerDepartment = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getCareerData: async function(id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.career.careerURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.editData = response.data.data[0];
                    this.jobType = this.editData.jobType;
                    this.title = this.editData.title;
                    this.location = this.editData.location;
                    this.description = this.editData.description;
                    this.salary = this.editData.salary;
                    this.department = {
                        id: this.editData.departmentId,
                        name: this.editData.departmentName
                    };
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        update: async function() {
            let token = localStorage.getItem('token');
            const formData = {
                jobType: this.jobType,
                title: this.title,
                location: this.location,
                description: this.description,
                salary: this.salary,
                departmentId: this.department.id,
                departmentName: this.department.name
            }
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.career.careerURL + '/' + this.careerId,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.jobType = '';
                this.title = '';
                this.location = '';
                this.description = '';
                this.salary = '';
                this.$router.push("/career");
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}