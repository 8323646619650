import { authHeader } from "../../../auth";
export default {

    data() {
        return {
            retailerMetaList: [],
            siteUrl: this.$serverURL + this.$api.retailer.meta,
        }
    },
    async created() {
        document.title = "Dispo - Retailer Meta List";
        this.retailerMetaList = await this.getDataList(this.siteUrl);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        deleteRetailerMeta: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.siteUrl + '/' + id,
                            headers: {
                                ...authHeader(),
                            }
                        }
                        await this.deleteData(config);
                        this.retailerMetaList = await this.getDataList(this.siteUrl);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        }
    }
}