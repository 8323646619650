<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Common CMS</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">Common CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Common CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-lg-8 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="col-12 mb-3">
												<label class="form-label">Brand Section Title:</label>
												<input type="text" v-model="cmsData.brandSectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter brand section title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Brand Section Subtitle:</label>
												<textarea type="text" v-model="cmsData.brandSectionSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter brand section subtitle" rows="3"></textarea>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Categroy Section Title:</label>
												<input type="text" v-model="cmsData.categorySectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter category section title"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Popular Flower Section Title:</label>
												<input type="text" v-model="cmsData.popularFlowerSectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter popular flower section title"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Popular Flower Section Subtitle:</label>
												<textarea type="text" v-model="cmsData.popularFlowerSectionSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter popular flower section subtitle" rows="3"></textarea>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Popular Flower Section Button Text:</label>
												<input type="text" v-model="cmsData.popularFlowerSectionButtonText" class="form-control" id="inputPageHeaderTitle" placeholder="Enter popular flower section Button Text"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Popular Flower Section Button Link: (Category name must be uppercase)</label>
												<input type="text" v-model="cmsData.popularFlowerSectionButtonLink" class="form-control" id="inputPageHeaderTitle" placeholder="Enter popular flower section Button Link"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Popular Vaporizers Section Title:</label>
												<input type="text" v-model="cmsData.popularVaporizersSectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter popular vaporizers section title"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Popular Vaporizers Section Subtitle:</label>
												<textarea type="text" v-model="cmsData.popularVaporizersSectionSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter popular vaporizers section subtitle" rows="3"></textarea>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Popular Vaporizers Section Button Text:</label>
												<input type="text" v-model="cmsData.popularVaporizersSectionButtonText" class="form-control" id="inputPageHeaderTitle" placeholder="Enter popular vaporizers section Button Text"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Popular Vaporizers Section Button Link: (Category name must be uppercase)</label>
												<input type="text" v-model="cmsData.popularVaporizersSectionButtonLink" class="form-control" id="inputPageHeaderTitle" placeholder="Enter popular vaporizers section Button Link"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Today's Special Section Title:</label>
												<input type="text" v-model="cmsData.todaysSpecialSectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter today's special section title"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Today's Special Section Subtitle:</label>
												<textarea type="text" v-model="cmsData.todaysSpecialSectionSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter today's special section subtitle" rows="3"></textarea>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Today's Special Section Button Text:</label>
												<input type="text" v-model="cmsData.todaysSpecialSectionButtonText" class="form-control" id="inputPageHeaderTitle" placeholder="Enter today's special section Button Text"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Today's Special Section Shop All Button Text:</label>
												<input type="text" v-model="cmsData.todaysSpecialSectionShopAllButtonText" class="form-control" id="inputPageHeaderTitle" placeholder="Enter today's special section shop all button text"/>
											</div>
										</div>
									</div>
								</div>
								<div class="d-grid col-12 col-lg-8">
									<button type="submit" class="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/common-cms.js"></script>