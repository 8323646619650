// import { authHeader } from '../../../auth';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    name: "AIQ Personas",
    components: {
        VPagination
    },
    data() {
        return {
            dataList: [],
            singleData: {},
            retailerId: "",
            page: 1,
            totalPage: 1,
            offset: 0,
            limit: 10,
            searchKey: "",
            delayTimeout: null,
            totalDataCount: 0
        }
    },
    async created() {
        document.title = "Dispo - AIQ Personas";
    },
    async mounted() {
        if (this.$route.params.id != undefined) {
            await this.getData();
        }
    },
    computed: {
        showingFrom() {
            return this.offset + 1;
        },
        showingTo() {
            return parseInt(this.offset) + parseInt(this.limit);
        }
    },
    methods: {
        async getData() {
            this.retailerId = this.$route.params.id;
            let setURL = this.$serverURL + this.$api.aiqPersonas.allPersonasURL + '?retailerId=' + this.retailerId + '&page=' + this.offset + '&limit=' + this.limit + '&search=' + this.searchKey;
            const aiqData = await this.getDataList(setURL);
            this.dataList = aiqData.results;
            this.totalDataCount = aiqData.total;
            this.totalPage = Math.ceil(aiqData.total / this.limit);
        },
        calculateOffset() {
            this.offset = (this.page - 1) * this.limit;
        },
        async handlePaginate() {
            this.$swal.showLoading();
            this.calculateOffset();
            await this.getData();
            this.$swal.close();
        },
        async handleShowRow() {
            this.$swal.showLoading();
            this.dataList = []
            this.page = 1;
            this.calculateOffset();
            await this.getData();
            this.$swal.close();
        },
        singlePersonas: async function (contactID) {
            let url = this.$serverURL + this.$api.aiqPersonas.detailsPersonasURL + '?retailerId=' + this.retailerId + '&contactId=' + contactID;
            const singlePersonas = await this.getSingleData(url);
            this.singleData = singlePersonas.data;
        }
    },

    watch: {
        searchKey: async function () {
            this.offset = 0;
            this.page = 1;
            clearTimeout(this.delayTimeout);
            this.delayTimeout = setTimeout(async () => {
                await this.getData();
            }, 1000);

        }
    }
}