<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Advertisement Banner
            </li>
          </ol>
        </nav>

        <div class="ms-auto">
          <div class="btn-group">
            <button type="button" class="btn btn-primary btn-sm m-1" @click="$router.push('/advertisement-banner/add')">
              <i class="bx bxs-plus-square"></i>Add New Advertisement Banner
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Advertisement Banner List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Image</th>
                  <th>Link</th>
                  <th>Priority</th>
                  <th>Timer</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img v-if="data.image" :src="data.image" alt="" width="90" />
                    <img v-else src="/core/assets/images/400.png" alt="" width="90" />
                  </td>
                  <td>{{ data.link }}</td>
                  <td>{{ data.priority }}</td>
                  <td>{{ data.delay }} seconds</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a href="javascript:void(0);" @click="$router.push('/advertisement-banner/' + data.id)"
                        class="edit-button"><i class="bx bxs-edit text-white"></i></a>
                      <a href="javascript:void(0);" @click="deleteContent(data.id)" class="ms-3 delete-button"><i
                          class="bx bxs-trash text-white"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Image</th>
                  <th>Link</th>
                  <th>Priority</th>
                  <th>Timer</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/advertisement-banner.js'></script>


<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}

.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}

.metaModal:hover {
  cursor: pointer;
  color: #09f;
}
</style>