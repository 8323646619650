import { authHeader } from '../../../auth';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "AddCareer",
    data() {
        return {
            jobType: '',
            title: '',
            location: '',
            description: '',
            salary: '',
            selectedDepartment: {},
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            careerDepartment: ""
        }
    },
    async created() {
        document.title = "Dispo - Add Career";
        await this.getCareerDepartment();
    },
    methods: {
        getCareerDepartment: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.career.careerDepartmentURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.careerDepartment = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        validate: function() {
            if (!this.jobType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter job type!"
                });
                return false;
            }
            if(Object.keys(this.selectedDepartment).length === 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select career department!"
                });
                return false;
            }
            if(!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if(!this.location) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter location!"
                });
                return false;
            }
            if(!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
                return false;
            }
            if(!this.salary) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter salary!"
                });
                return false;
            }
            return true;
        },
        save: async function() {
            if(this.validate() == true) {
                let token = localStorage.getItem('token');
                let formData = {
                    departmentId: this.selectedDepartment.id,
                    departmentName: this.selectedDepartment.name,
                    jobType: this.jobType,
                    title: this.title,
                    location: this.location,
                    description: this.description,
                    salary: this.salary
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.career.careerURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.jobType = '';
                    this.title = '';
                    this.location = '';
                    this.description = '';
                    this.salary = '';
                    this.selectedDepartment = {};
                    this.$router.push("/career");
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}