<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Canonical Tags</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Canonical Tags</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Canonical Tags</h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Signin Page:</label>
                                                <input type="text" v-model="cmsData.signinPage" class="form-control"
                                                    placeholder="Signin page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Signup Page:</label>
                                                <input type="text" v-model="cmsData.signupPage" class="form-control"
                                                    placeholder="Signup page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page:</label>
                                                <input type="text" v-model="cmsData.homePage" class="form-control"
                                                    placeholder="Home page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Page:</label>
                                                <input type="text" v-model="cmsData.shopPage" class="form-control"
                                                    placeholder="Shop page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Product Details Page:</label>
                                                <input type="text" v-model="cmsData.productDetailsPage"
                                                    class="form-control" placeholder="Product details page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Wishlist Page:</label>
                                                <input type="text" v-model="cmsData.wishlistPage" class="form-control"
                                                    placeholder="Wishlist page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page:</label>
                                                <input type="text" v-model="cmsData.cartPage" class="form-control"
                                                    placeholder="Cart page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">About Us Page:</label>
                                                <input type="text" v-model="cmsData.aboutUsPage" class="form-control"
                                                    placeholder="About Us page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Categories Page:</label>
                                                <input type="text" v-model="cmsData.categoriesPage"
                                                    class="form-control" placeholder="Categories page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Brand Page:</label>
                                                <input type="text" v-model="cmsData.brandPage" class="form-control"
                                                    placeholder="Brand page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Brand Page:</label>
                                                <input type="text" v-model="cmsData.shopBrandPage" class="form-control"
                                                    placeholder="Shop Brand page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Exclusive Brand Page:</label>
                                                <input type="text" v-model="cmsData.exclusiveBrandPage"
                                                    class="form-control" placeholder="Exclusive Brand page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Featured Brand Page:</label>
                                                <input type="text" v-model="cmsData.featuredBrandPage"
                                                    class="form-control" placeholder="Featured Brand page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Featured Brand Details Page:</label>
                                                <input type="text" v-model="cmsData.featuredBrandDetailsPage"
                                                    class="form-control" placeholder="Featured Brand Details page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Category Page:</label>
                                                <input type="text" v-model="cmsData.shopCategoryPage"
                                                    class="form-control" placeholder="Shop Category page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Specials Page:</label>
                                                <input type="text" v-model="cmsData.specialsPage" class="form-control"
                                                    placeholder="Specials page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Deals Special Page:</label>
                                                <input type="text" v-model="cmsData.dealsSpecialPage"
                                                    class="form-control" placeholder="Deals special page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Location Page:</label>
                                                <input type="text" v-model="cmsData.locationPage" class="form-control"
                                                    placeholder="Location page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Us Page:</label>
                                                <input type="text" v-model="cmsData.contactUsPage" class="form-control"
                                                    placeholder="Contact Us page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Page:</label>
                                                <input type="text" v-model="cmsData.faqPage" class="form-control"
                                                    placeholder="FAQ page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page:</label>
                                                <input type="text" v-model="cmsData.careerPage" class="form-control"
                                                    placeholder="Career page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Department Page:</label>
                                                <input type="text" v-model="cmsData.careerDepartmentPage"
                                                    class="form-control" placeholder="Career Department page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Details Page:</label>
                                                <input type="text" v-model="cmsData.careerDetailsPage"
                                                    class="form-control" placeholder="Career Details page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page:</label>
                                                <input type="text" v-model="cmsData.blogPage" class="form-control"
                                                    placeholder="Blog page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Details Page:</label>
                                                <input type="text" v-model="cmsData.blogDetailsPage"
                                                    class="form-control" placeholder="Blog Details page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page:</label>
                                                <input type="text" v-model="cmsData.privacyPage" class="form-control"
                                                    placeholder="Privacy page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Terms & Conditions Page:</label>
                                                <input type="text" v-model="cmsData.termsPage" class="form-control"
                                                    placeholder="Terms & Conditions page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Profile Page:</label>
                                                <input type="text" v-model="cmsData.profilePage" class="form-control"
                                                    placeholder="Profile page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <!-- <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Success Page:</label>
                                                <input type="text" v-model="cmsData.successPage" class="form-control"
                                                    placeholder="Success page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr /> -->
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Retailer Page:</label>
                                                <input type="text" v-model="cmsData.retailerPage" class="form-control"
                                                    placeholder="Retailer page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Forgot Password Page:</label>
                                                <input type="text" v-model="cmsData.forgotPasswordPage"
                                                    class="form-control" placeholder="Forgot Password page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <!-- <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Choose Place Page:</label>
                                                <input type="text" v-model="cmsData.choosePlacePage"
                                                    class="form-control" placeholder="Choose Place page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr /> -->
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Reset Password Page:</label>
                                                <input type="text" v-model="cmsData.resetPasswordPage"
                                                    class="form-control" placeholder="Reset Password page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <!-- <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Invoice Page:</label>
                                                <input type="text" v-model="cmsData.invoicePage" class="form-control"
                                                    placeholder="Invoice page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr> -->
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Store Page:</label>
                                                <input type="text" v-model="cmsData.storePage" class="form-control"
                                                    placeholder="Store page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Order Page:</label>
                                                <input type="text" v-model="cmsData.orderPage" class="form-control"
                                                    placeholder="Order page">
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Sitemap Page:</label>
                                                <input type="text" v-model="cmsData.sitemapPage" class="form-control"
                                                    placeholder="Sitemap page">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="updateCms()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/canonical-tags.js"></script>