import { authHeader } from '../../../auth';
import draggable from 'vuedraggable';

export default {
    name: "Category",
    components: {
        draggable
    },
    data() {
        return {
            dataList: [],
            setURL: this.$serverURL + this.$api.category.requestURL,
            singleData: {},
            previewIcon: "",
            updateIcon: "",
            previewImage: "",
            updateImage: "",
            dragging: false,
            tempDataList: [],
            searchKey: ""
        }
    },
    async created() {
        document.title = "Dispo - Category";
        await this.getData();
        await this.updateOrder();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        async getData() {
            const response = await this.getDataList(this.setURL);
            this.dataList = response;
            this.tempDataList = response;
        },
        searchCategory() {
            let newArr = [...this.dataList];
            let searchVal = this.searchKey.toUpperCase();
            let filteredArr = [];
            newArr.forEach((el) => {
                let categoryName = el?.categoryName?.toUpperCase();
                if (categoryName.includes(searchVal)) {
                    filteredArr.push(el);
                }
            });
            this.tempDataList = filteredArr;
        },
        editData(data) {
            this.singleData = data;
        },
        onIconChange(event) {
            this.updateIcon = event.target.files[0];
            let input = this.$refs.icon;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewIcon = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onImageChange(event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function () {
            const formData = new FormData();
            formData.append("image", this.updateIcon ? this.updateIcon : this.singleData.image);
            formData.append("featuredImage", this.updateImage ? this.updateImage : this.singleData.featuredImage);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.category.requestURL + '/' + this.singleData.id,
                data: formData,
                headers: authHeader()
            };
            this.$swal.showLoading();
            await this.$axios(config).then(async (response) => {
                this.$swal.close();
                if (response.data.statusCode == 200) {
                    this.singleData = {};
                    this.$refs.image.value = "";
                    this.$refs.icon.value = "";
                    this.updateIcon = "";
                    this.previewIcon = "";
                    this.updateImage = "";
                    this.previewImage = "";
                    this.dataList = [];
                    this.tempDataList = [];
                    await this.getData();
                    document.getElementById("close-btn").click();
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async updateOrder() {
            try {
                const newOrderList = this.tempDataList.map((el, index) => {
                    return { id: el.id, position: index + 1 }
                })
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.category.updateOrderUrl,
                    headers: authHeader(),
                    data: {
                        newOrderList
                    },
                }
                await this.postData(config).then(async (response) => {
                    if (response.statusCode == 200) {
                        await this.getData();
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        async onDragUpdate(event) {
            event;
            await this.updateOrder();
        },
    },
    watch: {
        searchKey: function () {
            this.searchCategory();
        }
    }
}