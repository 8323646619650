import { authHeader } from '../../../../auth';

export default {
    name: "SocialLinks",
    data() {
        return {
            singleSocialLink: {},
            iconClassName: "",
            link: "",
            setURL: this.$serverURL + this.$api.cms.socialLinkURL,
            dataList: []
        }
    },
    async created() {
        document.title = "Dispo - Social Links";
        this.dataList = await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addSocialLink: async function () {
            if (!this.iconClassName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter icon class name!"
                });
            }
            else if(!this.link) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter link!"
                });
            }
            else {
                let data = {
                    iconClassName: this.iconClassName,
                    link: this.link
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: authHeader()
                };
                let response = await this.postData(config);
                if (response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.iconClassName = '';
                this.link = '';
                this.dataList = await this.getDataList(this.setURL);
                document.getElementById("closeButton").click();
            }
        },
        editSocialLink: async function (data) {
            this.singleSocialLink = data;
        },
        updateSocialLink: async function () {
            if (!this.singleSocialLink.iconClassName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter icon class name!"
                });
            }
            else if (!this.singleSocialLink.link) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter link!"
                });
            }
            else {
                let data = {
                    iconClassName: this.singleSocialLink.iconClassName,
                    link: this.singleSocialLink.link
                };
                let config = {
                    method: "PATCH",
                    url: this.setURL + '/' + this.singleSocialLink.id,
                    data: data,
                    headers: authHeader()
                };
                let response = await this.updateData(config);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.singleSocialLink = {};
                this.dataList = await this.getDataList(this.setURL);
                document.getElementById("buttonClose").click();
            }
        },
        deleteSocialLink: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: this.setURL + '/' + id,
                                headers: authHeader()
                            }
                            await this.deleteData(config);
                            this.dataList = await this.getDataList(this.setURL);
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        }
                    }
            })
            
        }
    }
}