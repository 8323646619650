import { createRouter, createWebHistory } from "vue-router";
import adminGuard from "../admin-guard";
import guard from "../guard";
import Login from "../components/auth/template/Login";
import Dashboard from "../components/dashboard/template/Dashboard";
import Error404 from "../components/global/error_404/template/Error404";
import Unauthorized from "../components/global/unauthorized/template/Unauthorized";
import Store from "../components/store/template/Store";
import StoreDetailsCMS from "../components/cms/store-details-cms/template/StoreDetailsCMS";
import LandingPageCms from "../components/cms/landing-page/template/LandingPageCms";
import SigninPageCms from "../components/cms/signin-page/template/SigninPageCMS";
import SignupPageCms from "../components/cms/signup-page/template/SignupPageCMS";
import ChoosePlace from "../components/cms/choose-place//template/ChoosePlace";
import RetailerPageCms from "../components/cms/retailer-page/template/RetailerPageCMS";
import LocationPageCms from "../components/cms/location-page/template/LocationPageCMS";
import CareerListPageCms from "../components/cms/career-list-page/template/CareerListPageCMS";
import CareerDetailsPageCms from "../components/cms/career-details-page/template/CareerDetailsPageCMS";
import CareerApplyModalPageCms from "../components/cms/career-apply-modal-page/template/CareerApplyModalPageCMS";
import ScheduleVendorDayBannerCms from "../components/cms/vendor-banner-page/template/VendorBannerPageCMS";
import ContactUsPageCms from "../components/cms/contact-us/template/ContactUsPageCMS";
import NewsletterBannerCms from "../components/cms/newsletter-banner/template/NewsletterBannerPageCMS";
import PrivacyPolicyCms from "../components/cms/privacy-policy/template/PrivacyPolicyCMS";
import TermsAndConditionCms from "../components/cms/terms-and-condition/template/TermsAndConditonCMS";
import CommonCms from "../components/cms/common-cms/template/CommonCMS";
import ContactUsSectionOne from "../components/cms/contact-us-section-one/template/ContactUsSectionOne";
import SectionContentList from "../components/cms/contact-us-section-one/template/SectionContentList"
import SectionContentListDefault from "../components/cms/contact-us-section-one/template/SectionContentListDefault"
import AddContactUsSectionOne from "../components/cms/contact-us-section-one/template/AddContactUsSectionOne";
import EditContactUsSectionOne from "../components/cms/contact-us-section-one/template/EditContactUsSectionOne";
import CareerApplicant from "../components/career-applicant/template/CareerApplicant";
import CareerDepartment from "../components/career-department/template/CareerDepartment";
import AddCareerDepartment from "../components/career-department/template/AddCareerDepartment";
import EditCareerDepartment from "../components/career-department/template/EditCareerDepartment";
import Career from "../components/career/template/Career";
import AddCareer from "../components/career/template/AddCareer";
import EditCareer from "../components/career/template/EditCareer";
import WhyJoinUs from "../components/why-join-us/template/WhyJoinUs";
import AddWhyJoinUs from "../components/why-join-us/template/AddWhyJoinUs";
import EditWhyJoinUs from "../components/why-join-us/template/EditWhyJoinUs";
import BlogCategory from "../components/blog/template/BlogCategory";
import BlogTag from "../components/blog/template/BlogTag";
import BlogPost from "../components/blog/template/BlogPost";
import AddBlogPost from "../components/blog/template/AddBlogPost";
import EditBlogPost from "../components/blog/template/EditBlogPost";
import Faq from "../components/faq/template/Faq";
import FaqPageCMS from "../components/cms/faq-page/template/FaqPageCMS";
import FaqPageSectionTwo from "../components/faq-page-section-two/template/FaqPageSectionTwo";
import AboutUsPageCms from "../components/cms/about-us-page/template/AboutUsPageCMS";
import AboutUsSectionOneCms from "../components/cms/about-us-section-one/template/AboutUsSectionOneCMS";
import AboutUsSectionTwoCms from "../components/cms/about-us-section-two/template/AboutUsSectionTwoCMS";
import AboutUsDispoDifferenceCms from "../components/cms/about-us-dispo-difference/template/AboutUsDispoDifferenceCMS";
import Category from "../components/category/template/Category";
import Effect from "../components/effects/template/Effects.vue";
import Retailer from "../components/retailer/template/Retailer";
import EditRetailer from "../components/retailer/template/EditRetailer";
import DefaultRetailer from "../components/retailer/template/DefaultRetailer";
import Contact from "../components/contact/template/Contact";
import Newsletter from "../components/newsletter/template/Newsletter";
import ReeferFriendCms from "../components/cms/reefer-friend-cms/template/ReeferFriendCMS";
import ReeferFriend from "../components/cms/reefer-friend/template/ReeferFriend";
import HeaderBanner from "../components/header-banner/template/HeaderBanner";
import HeaderBannerList from "../components/header-banner/template/HeaderBannerList";
import HeroSectionSlider from "../components/cms/home-hero-section-slider/template/HeroSectionSlider";
import HeroSectionSliderList from "../components/cms/home-hero-section-slider/template/HeroSectionSliderList";
import AddHeroSectionSlider from "../components/cms/home-hero-section-slider/template/AddHeroSectionSlider";
import EditHeroSectionSlider from "../components/cms/home-hero-section-slider/template/EditHeroSectionSlider";
import Admin from "../components/user/template/Admin";
import HR from "../components/user/template/HR";
import Customer from "../components/user/template/Customer";
import HomeV2BannerCMS from "../components/cms/home-v2-banner-cms/template/HomeV2BannerCMS.vue";
import HomeV2SectionTwoCMS from "../components/cms/home-v2-banner-slider/template/HomeV2SectionTwoCMS.vue";
import HomeSpecialSlider from "../components/cms/home-v2-special-slider/template/HomeSpecialSlider.vue";
import HomeV2Effect from "../components/cms/home-v2-effect-cms/template/HomeV2Effect.vue";
import HomeV2AboutDispensaryCMS from "../components/cms/home-v2-about-dispensary-cms/template/HomeV2AboutDispensaryCms.vue";
import HomeV2WhyChooseOurDispensary from "../components/cms/home-v2-why-choose-our-dispensary/template/HomeV2WhyChooseOurDispensary.vue";
import HomeV2WhyChooseDespensaryCMSFaq from "../components/cms/home-v2-why-choose-dispensary/template/HomeV2WhyChooseDespensaryCMSFaq.vue";
import HomeV2LevelProgramCMS from "../components/cms/home-v2-levelup-section-cms/template/HomeV2LevelProgramCMS.vue";
import HomeV2CustomerFeedbackCMS from "../components/cms/home-v2-customer-feedback-cms/template/HomeV2CustomerFeedbackCMS.vue";
import HomeV2LookingForDispensary from "../components/cms/home-v2-looking-for-dispensary-cms/template/HomeLookingForDispensaryCms.vue";
import HomeV2BestDispensary from "../components/cms/home-v2-best-dispensary-cms/template/HomeBestDispensaryCms.vue";
import HomeV2BlogSectionCMS from "../components/cms/home-v2-blog-section-cms/template/HomeBlogSectionCms.vue";
import CustomerFeedbackCRUD from "../components/cms/home-v2-customer-feedback-crud/template/CustomerFeedbackCRUD.vue";
import HomePageCms from "../components/cms/home-page-cms/template/HomePageCMS";
import HeaderCms from "../components/cms/header-cms/template/HeaderCMS";
import FooterCms from "../components/cms/footer-cms/template/FooterCMS";
import SocialLinks from "../components/cms/social-links/template/SocialLinks";
import ProductReview from "../components/product-review/template/ProductReview";
import Synchronize from "../components/synchronize/template/Synchronize";
import HeaderBannerCMS from "../components/cms/header-banner-cms/template/HeaderBannerCMS";
import AIQPersonas from "../components/aiq-personas/template/AIQPersonas";
import SitePageTitleCMS from "../components/cms/site-page-title-cms/template/SitePageTitleCMS";
import SitePageMetaCMS from "../components/cms/site-page-meta-cms/template/SitePageMetaCMS";
import CanonicalTags from "../components/cms/canonical-tags/template/CanonicalTags";
import HomeComponentUI from "../components/component-ui-cms/home/template/HomeComponentUI";
import ShopComponentUI from "../components/component-ui-cms/shop/template/ShopComponentUI";
import AboutComponentUI from "../components/component-ui-cms/about-us/template/AboutComponentUI";
import CategoriesComponentUI from "../components/component-ui-cms/categories/template/CategoriesComponentUI";
import BrandComponentUI from "../components/component-ui-cms/brand/template/BrandComponentUI";
import SpecialComponentUI from "../components/component-ui-cms/special/template/SpecialComponentUI";
import LocationComponentUI from "../components/component-ui-cms/location/template/LocationComponentUI";
import ContactComponentUI from "../components/component-ui-cms/contact/template/ContactComponentUI";
import FAQComponentUI from "../components/component-ui-cms/faq/template/FaqComponentUI";
import PrivacyComponentUI from "../components/component-ui-cms/privacy-policy/template/PrivacyComponentUI";
import TermsComponentUI from "../components/component-ui-cms/terms-condition/template/TermsComponentUI";
import CareerComponentUI from "../components/component-ui-cms/career/template/CareerComponentUI";
import CareerDetailsComponentUI from "../components/component-ui-cms/career-details/template/CareerDetailsComponentUI";
import CareerDepartmentComponentUI from "../components/component-ui-cms/career-department/template/CareerDepartmentComponentUI";
import BlogComponentUI from "../components/component-ui-cms/blog/template/BlogComponentUI";
import BlogDetailsComponentUI from "../components/component-ui-cms/blog-details/template/BlogDetailsComponentUI";
import NewsletterComponentUI from "../components/component-ui-cms/newsletter/template/NewsletterComponentUI";
import TopTickerComponentUI from "../components/component-ui-cms/top-ticker/template/TopTickerComponentUI";
import LandingComponentUI from "../components/component-ui-cms/landing-page/template/LandingComponentUI";
import CommonComponentUI from "../components/component-ui-cms/common/template/CommonComponentUI";
import AiqService from "../components/AIQ-service/template/AiqService";
import FooterImages from "../components/cms/footer-images/template/FooterImages";
import AddFooterImages from "../components/cms/footer-images/template/AddFooterImages";
import EditFooterImages from "../components/cms/footer-images/template/EditFooterImages";

import HomeHeroSectionLeftCms from "../components/cms/home-hero-section-left/template/HomeHeroSectionLeftCMS";
import HomeHeroSectionLeftCMSDefault from "../components/cms/home-hero-section-left/template/HomeHeroSectionLeftCMSDefault";
import HomeHeroSectionLeftCMSList from "../components/cms/home-hero-section-left/template/HomeHeroSectionLeftCMSList";

import BrandStore from "../components/Brands/template/BrandStore";
import BrandList from "../components/Brands/template/BrandList";

import RetailerComponentUI from "../components/component-ui-cms/retailer/template/RetailerComponentUI";
import ChoosePlaceComponentUI from "../components/component-ui-cms/choose-place/template/ChoosePlaceComponentUI";
import StoreDetailsComponentUi from "../components/component-ui-cms/store-details/template/StoreDetailsComponentUi";
import ChangePassword from "../components/profile/template/ChangePassword";

import ExclusiveBrandSectionOneCMS from "../components/cms/exclusive-brand-page/exclusive-brand-section-one-cms/template/ExclusiveBrandSectionOneCMS";
import ExclusiveBrandSectionTwoCMS from "../components/cms/exclusive-brand-page/exclusive-brand-section-two-cms/template/ExclusiveBrandSectionTwoCMS";
import ExclusiveBrandSectionThreeCMS from "../components/cms/exclusive-brand-page/exclusive-brand-section-three-cms/template/ExclusiveBrandSectionThreeCMS";
import ExclusiveBrandPageCMS from "../components/cms/exclusive-brand-page/exclusive-brand-page-cms/template/ExclusiveBrandPageCMS";

import NewsletterPopUpCms from "../components/cms/newsletter-pop-up/template/NewsletterPopUpCms";
import OfferPopUpCms from "../components/cms/offer-pop-up/template/OfferPopUpCms";

import NewsletterSubscriber from "../components/newsletter-subscriber/template/NewsletterSubscriber";

import OfferPopup from "../components/offer-popup/template/OfferPopup";
import OfferPopupDetails from "../components/offer-popup/template/OfferPopupDetails";

import NewsletterPopup from "../components/newsletter-popup/template/NewsletterPopup";
import NewsletterPopupDetails from "../components/newsletter-popup/template/NewsletterPopupDetails";

import HeaderComponentUI from "../components/header/template/HeaderComponentUI";

import SiteMapCMS from "../components/cms/site-map/template/SiteMapCMS";
import RobotTxt from "../components/cms/robot-txt/template/RobotTxt";

import MobileBannerCms from "../components/cms/mobile-banner-cms/template/MobileBannerCms";

import DealsSpecialCms from "../components/cms/deals-special-cms/template/DealsSpecialCms";

import HeaderSpecialSectionCms from "../components/cms/header-special-section-cms/template/HeaderSpecialSectionCMS";

//Brand
import AddBrand from "../components/brand-details-page/customBrand/template/AddBrand";
import UpdateBrand from "../components/brand-details-page/customBrand/template/UpdateBrand";
import Brand from "../components/brand-details-page/customBrand/template/Brand";

// Product
import ProductBrand from "../components/brand-details-page/customProduct/template/Brand";
import CustomProductList from "../components/brand-details-page/customProduct/template/Product";
import AddCustomProduct from "../components/brand-details-page/customProduct/template/AddProduct";
import UpadateCustomProduct from "../components/brand-details-page/customProduct/template/UpdateProduct";

//Retailer Meta Data
import AddRetailerMetaData from "../components/retailer-meta-data/template/AddRetailerMetaData";
import UpdateRetailerMetaData from "../components/retailer-meta-data/template/UpdateRetailerMetaData";
import RetailerMetaData from "../components/retailer-meta-data/template/RetailerMetaData";

// Product Category Meta Data
import AddProductCategoryMetaData from "../components/product-category-meta-data/template/AddProductCategoryMetaData";
import UpdateProductCategoryMetaData from "../components/product-category-meta-data/template/UpdateProductCategoryMetaData";
import ProductCategoryMetaData from "../components/product-category-meta-data/template/ProductCategoryMetaData";

// Advertisement Banner
import AddAdvertisementBanner from "../components/advertisement-banner/template/AddAdvertisementBanner";
import AdvertisementBanner from "../components/advertisement-banner/template/AdvertisementBanner";
import EditAdvertisementBanner from "../components/advertisement-banner/template/EditAdvertisementBanner";

// New SEO
import ProductMetaMapping from "../components/cms/product-meta-mapping/template/ProductMetaMapping";
import PageSchema from "../components/cms/page-schema/template/PageSchema";
import SeoContentTextCMS from "../components/cms/seo-content-text-cms/template/SeoContentTextCMS";

const routes = [
	/* Auth Routes */
	{ path: "/admin-login", name: "Login", component: Login },
	{ path: "/", redirect: { name: 'Home' } },
	{ path: "/home", name: "Home", component: Dashboard, beforeEnter: guard },
	{ path: "/change-password", name: "ChangePassword", component: ChangePassword, beforeEnter: guard },

	/* Error and Unauthorized routes */
	{ path: "/404-not-found", name: "404 Not Found", component: Error404, beforeEnter: guard },
	{ path: "/unauthorized", name: "Unauthorized", component: Unauthorized, beforeEnter: guard },
	{ path: '/:pathMatch(.*)*', component: Error404, beforeEnter: guard },

	/* CMS Routes */
	{ path: "/landing-page-cms", name: "LandingPageCms", component: LandingPageCms, beforeEnter: adminGuard },
	{ path: "/signin-page-cms", name: "SigninPageCms", component: SigninPageCms, beforeEnter: adminGuard },
	{ path: "/signup-page-cms", name: "SignupPageCms", component: SignupPageCms, beforeEnter: adminGuard },
	{ path: "/choose-place-page-cms", name: "ChoosePlace", component: ChoosePlace, beforeEnter: adminGuard },
	{ path: "/retailer-page-cms", name: "RetailerPageCms", component: RetailerPageCms, beforeEnter: adminGuard },
	{ path: "/location-page-cms", name: "LocationPageCms", component: LocationPageCms, beforeEnter: adminGuard },
	{ path: "/career-list-page-cms", name: "CareerListPageCms", component: CareerListPageCms, beforeEnter: guard },
	{ path: "/career-details-page-cms", name: "CareerDetailsPageCms", component: CareerDetailsPageCms, beforeEnter: guard },
	{ path: "/career-apply-modal-page-cms", name: "CareerApplyModalPageCms", component: CareerApplyModalPageCms, beforeEnter: guard },
	{ path: "/vendor-banner-cms", name: "ScheduleVendorDayBannerCms", component: ScheduleVendorDayBannerCms, beforeEnter: adminGuard },
	{ path: "/contact-us-page-cms", name: "ContactUsPageCms", component: ContactUsPageCms, beforeEnter: adminGuard },

	{ path: "/contact-us-section-one", name: "ContactUsSectionOne", component: ContactUsSectionOne, beforeEnter: adminGuard },
	{ path: "/section-content-list", name: "SectionContentList", component: SectionContentList, beforeEnter: adminGuard },
	{ path: "/section-content-list-default", name: "SectionContentListDefault", component: SectionContentListDefault, beforeEnter: adminGuard },

	{ path: "/add-contact-us-section-one", name: "AddContactUsSectionOne", component: AddContactUsSectionOne, beforeEnter: adminGuard },
	{ path: "/edit-contact-us-section-one/:id", name: "EditContactUsSectionOne", component: EditContactUsSectionOne, beforeEnter: adminGuard },
	{ path: "/newsletter-banner-cms", name: "NewsletterBannerCms", component: NewsletterBannerCms, beforeEnter: adminGuard },
	{ path: "/privacy-policy", name: "PrivacyPolicyCms", component: PrivacyPolicyCms, beforeEnter: adminGuard },
	{ path: "/terms-and-condition", name: "TermsAndConditionCms", component: TermsAndConditionCms, beforeEnter: adminGuard },
	{ path: "/common-cms", name: "CommonCms", component: CommonCms, beforeEnter: adminGuard },
	{ path: "/about-us-page-cms", name: "AboutUsPageCms", component: AboutUsPageCms, beforeEnter: adminGuard },
	{ path: "/about-us-section-one", name: "AboutUsSectionOneCms", component: AboutUsSectionOneCms, beforeEnter: adminGuard },
	{ path: "/about-us-section-two", name: "AboutUsSectionTwoCms", component: AboutUsSectionTwoCms, beforeEnter: adminGuard },
	{ path: "/about-us-dispo-difference", name: "AboutUsDispoDifferenceCms", component: AboutUsDispoDifferenceCms, beforeEnter: adminGuard },

	{ path: "/home-hero-section-left-cms-list", name: "HomeHeroSectionLeftCMSList", component: HomeHeroSectionLeftCMSList, beforeEnter: adminGuard },
	{ path: "/home-hero-section-left-cms", name: "HomeHeroSectionLeftCms", component: HomeHeroSectionLeftCms, beforeEnter: adminGuard },
	{ path: "/home-hero-section-left-cms-default", name: "HomeHeroSectionLeftCMSDefault", component: HomeHeroSectionLeftCMSDefault, beforeEnter: adminGuard },

	{ path: "/header-banner", name: "HeaderBanner", component: HeaderBanner, beforeEnter: adminGuard },
	{ path: "/header-banner/:retailerId/:retailerName", name: "HeaderBannerList", component: HeaderBannerList, beforeEnter: adminGuard },

	{ path: "/hero-section-slider", name: "HeroSectionSlider", component: HeroSectionSlider, beforeEnter: adminGuard },
	{ path: "/hero-section-slider-list/:retailerId/:retailerName", name: "HeroSectionSliderList", component: HeroSectionSliderList, beforeEnter: adminGuard },
	{ path: "/add-hero-section-slider/:retailerId/:retailerName", name: "AddHeroSectionSlider", component: AddHeroSectionSlider, beforeEnter: adminGuard },
	{ path: "/edit-hero-section-slider/:id/:retailerId/:retailerName", name: "EditHeroSectionSlider", component: EditHeroSectionSlider, beforeEnter: adminGuard },
	{ path: "/home-page-section-one-cms", name: "HomeV2BannerCMS", component: HomeV2BannerCMS, beforeEnter: adminGuard },
	{ path: "/home-page-section-two-cms", name: "HomeV2SectionTwoCMS", component: HomeV2SectionTwoCMS, beforeEnter: adminGuard },
	{ path: "/home-page-special-section-cms", name: "HomeSpecialSlider", component: HomeSpecialSlider, beforeEnter: adminGuard },
	{ path: "/home-page-effect-cms", name: "HomeV2Effect", component: HomeV2Effect, beforeEnter: adminGuard },
	{ path: "/home-page-about-dispensary-cms", name: "HomeV2AboutDispensaryCMS", component: HomeV2AboutDispensaryCMS, beforeEnter: adminGuard },
	{ path: "/home-page-why-choose-our-dispensary-cms", name: "HomeV2WhyChooseOurDispensary", component: HomeV2WhyChooseOurDispensary, beforeEnter: adminGuard },
	{ path: "/home-page-looking-for-dispensary-cms", name: "HomeV2LookingForDispensary", component: HomeV2LookingForDispensary, beforeEnter: adminGuard },
	{ path: "/home-page-best-dispensary-cms", name: "HomeV2BestDispensary", component: HomeV2BestDispensary, beforeEnter: adminGuard },
	{ path: "/home-page-blog-section-cms", name: "HomeV2BlogSectionCMS", component: HomeV2BlogSectionCMS, beforeEnter: adminGuard },
	{ path: "/why-choose-our-dispensary-cms", name: "HomeV2WhyChooseDespensaryCMSFaq", component: HomeV2WhyChooseDespensaryCMSFaq, beforeEnter: adminGuard },
	{ path: "/level-up-program-cms", name: "HomeV2LevelProgramCMS", component: HomeV2LevelProgramCMS, beforeEnter: adminGuard },
	{ path: "/customer-feedback-section-cms", name: "HomeV2CustomerFeedbackCMS", component: HomeV2CustomerFeedbackCMS, beforeEnter: adminGuard },
	{ path: "/customer-feedbacks", name: "CustomerFeedbackCRUD", component: CustomerFeedbackCRUD, beforeEnter: adminGuard },
	{ path: "/home-page-cms", name: "HomePageCms", component: HomePageCms, beforeEnter: adminGuard },
	{ path: "/header-cms", name: "HeaderCms", component: HeaderCms, beforeEnter: adminGuard },
	{ path: "/footer-cms", name: "FooterCms", component: FooterCms, beforeEnter: adminGuard },
	{ path: "/social-links", name: "SocialLinks", component: SocialLinks, beforeEnter: adminGuard },
	{ path: "/header-banner-cms", name: "HeaderBannerCMS", component: HeaderBannerCMS, beforeEnter: adminGuard },
	{ path: "/site-page-title-cms", name: "SitePageTitleCMS", component: SitePageTitleCMS, beforeEnter: adminGuard },
	{ path: "/site-page-meta-cms", name: "SitePageMetaCMS", component: SitePageMetaCMS, beforeEnter: adminGuard },
	{ path: "/canonical-tags", name: "CanonicalTags", component: CanonicalTags, beforeEnter: adminGuard },
	{ path: "/footer-images", name: "FooterImages", component: FooterImages, beforeEnter: adminGuard },
	{ path: "/add-footer-images", name: "AddFooterImages", component: AddFooterImages, beforeEnter: adminGuard },
	{ path: "/edit-footer-images/:id", name: "EditFooterImages", component: EditFooterImages, beforeEnter: adminGuard },

	{ path: "/newsletter-pop-up-cms", name: "NewsletterPopUpCms", component: NewsletterPopUpCms, beforeEnter: adminGuard },
	{ path: "/offer-pop-up-cms", name: "OfferPopUpCms", component: OfferPopUpCms, beforeEnter: adminGuard },

	// brands
	{ path: "/brands", name: "BrandStore", component: BrandStore, beforeEnter: adminGuard },
	{ path: "/brands-list", name: "BrandList", component: BrandList, beforeEnter: adminGuard },


	/* FAQ Routes*/
	{ path: "/faq", name: "Faq", component: Faq, beforeEnter: adminGuard },
	{ path: "/faq-page-cms", name: "FaqPageCMS", component: FaqPageCMS, beforeEnter: adminGuard },
	{ path: "/faq-page-section-two", name: "FaqPageSectionTwo", component: FaqPageSectionTwo, beforeEnter: adminGuard },

	/* Store Routes */
	{ path: "/store", name: "Store", component: Store, beforeEnter: adminGuard },
	{ path: "/store-details-cms", name: "StoreDetailsCMS", component: StoreDetailsCMS, beforeEnter: adminGuard },

	/* Blog Routes */
	{ path: "/blog-category", name: "BlogCategory", component: BlogCategory, beforeEnter: adminGuard },
	{ path: "/blog-tag", name: "BlogTag", component: BlogTag, beforeEnter: adminGuard },
	{ path: "/blog-post", name: "BlogPost", component: BlogPost, beforeEnter: adminGuard },
	{ path: "/add-blog-post", name: "AddBlogPost", component: AddBlogPost, beforeEnter: adminGuard },
	{ path: "/edit-blog-post/:id", name: "EditBlogPost", component: EditBlogPost, beforeEnter: adminGuard },

	/* Career Routes */
	{ path: "/career-applicant", name: "CareerApplicant", component: CareerApplicant, beforeEnter: guard },
	{ path: "/career-department", name: "CareerDepartment", component: CareerDepartment, beforeEnter: guard },
	{ path: "/add-career-department", name: "AddCareerDepartment", component: AddCareerDepartment, beforeEnter: guard },
	{ path: "/edit-career-department/:id", name: "EditCareerDepartment", component: EditCareerDepartment, beforeEnter: guard },
	{ path: "/career", name: "Career", component: Career, beforeEnter: guard },
	{ path: "/add-career", name: "AddCareer", component: AddCareer, beforeEnter: guard },
	{ path: "/edit-career/:id", name: "EditCareer", component: EditCareer, beforeEnter: guard },
	{ path: "/why-join-us", name: "WhyJoinUs", component: WhyJoinUs, beforeEnter: guard },
	{ path: "/add-why-join-us", name: "AddWhyJoinUs", component: AddWhyJoinUs, beforeEnter: guard },
	{ path: "/edit-why-join-us/:id", name: "EditWhyJoinUs", component: EditWhyJoinUs, beforeEnter: guard },

	/* Category Routes */
	{ path: '/category', name: "Category", component: Category, beforeEnter: adminGuard },

	/* Effect Routes */
	{ path: '/effects', name: "Effect", component: Effect, beforeEnter: adminGuard },

	/* Retailer Routes */
	{ path: '/retailer', name: "Retailer", component: Retailer, beforeEnter: adminGuard },
	{ path: '/edit-retailer/:id', name: "EditRetailer", component: EditRetailer, beforeEnter: adminGuard },
	{ path: '/default-retailer', name: "DefaultRetailer", component: DefaultRetailer, beforeEnter: adminGuard },

	/* Retailer AIQ Service Routes */
	{ path: '/retailer-aiq', name: "AiqService", component: AiqService, beforeEnter: adminGuard },

	/* Contact Routes */
	{ path: '/contact', name: "Contact", component: Contact, beforeEnter: adminGuard },

	/* Newsletter Routes */
	{ path: '/newsletter', name: "Newsletter", component: Newsletter, beforeEnter: adminGuard },
	{ path: '/newsletter-subscriber', name: "NewsletterSubscriber", component: NewsletterSubscriber, beforeEnter: adminGuard },

	/* Product Review Routes */
	{ path: '/product-review', name: "ProductReview", component: ProductReview, beforeEnter: adminGuard },

	/* Reefer A Friend Routes */
	{ path: '/reefer-friend-cms', name: "ReeferFriendCms", component: ReeferFriendCms, beforeEnter: adminGuard },
	{ path: '/reefer-friend', name: "ReeferFriend", component: ReeferFriend, beforeEnter: adminGuard },

	/* User Routes */
	{ path: '/admin', name: "Admin", component: Admin, beforeEnter: adminGuard },
	{ path: '/customer', name: "Customer", component: Customer, beforeEnter: adminGuard },
	{ path: '/hr', name: "HR", component: HR, beforeEnter: adminGuard },

	/* Synchronize Routes */
	{ path: "/synchronize", name: "Synchronize", component: Synchronize, beforeEnter: adminGuard },

	/* AIQ Personas Routes */
	{ path: "/aiq-personas/:id", name: "AIQPersonas", component: AIQPersonas, beforeEnter: adminGuard },

	/* Component UI Routes */
	{ path: "/home-page-component-ui", name: "HomeComponentUI", component: HomeComponentUI, beforeEnter: adminGuard },
	{ path: "/shop-page-component-ui", name: "ShopComponentUI", component: ShopComponentUI, beforeEnter: adminGuard },
	{ path: "/about-page-component-ui", name: "AboutComponentUI", component: AboutComponentUI, beforeEnter: adminGuard },
	{ path: "/categories-page-component-ui", name: "CategoriesComponentUI", component: CategoriesComponentUI, beforeEnter: adminGuard },
	{ path: "/brand-page-component-ui", name: "BrandComponentUI", component: BrandComponentUI, beforeEnter: adminGuard },
	{ path: "/special-page-component-ui", name: "SpecialComponentUI", component: SpecialComponentUI, beforeEnter: adminGuard },
	{ path: "/location-page-component-ui", name: "LocationComponentUI", component: LocationComponentUI, beforeEnter: adminGuard },
	{ path: "/contact-page-component-ui", name: "ContactComponentUI", component: ContactComponentUI, beforeEnter: adminGuard },
	{ path: "/faq-page-component-ui", name: "FAQComponentUI", component: FAQComponentUI, beforeEnter: adminGuard },
	{ path: "/career-page-component-ui", name: "CareerComponentUI", component: CareerComponentUI, beforeEnter: adminGuard },
	{ path: "/career-details-page-component-ui", name: "CareerDetailsComponentUI", component: CareerDetailsComponentUI, beforeEnter: adminGuard },
	{ path: "/career-department-page-component-ui", name: "CareerDepartmentComponentUI", component: CareerDepartmentComponentUI, beforeEnter: adminGuard },
	{ path: "/blog-page-component-ui", name: "BlogComponentUI", component: BlogComponentUI, beforeEnter: adminGuard },
	{ path: "/blog-details-page-component-ui", name: "BlogDetailsComponentUI", component: BlogDetailsComponentUI, beforeEnter: adminGuard },
	{ path: "/privacy-page-component-ui", name: "PrivacyComponentUI", component: PrivacyComponentUI, beforeEnter: adminGuard },
	{ path: "/terms-page-component-ui", name: "TermsComponentUI", component: TermsComponentUI, beforeEnter: adminGuard },
	{ path: "/newsletter-component-ui", name: "NewsletterComponentUI", component: NewsletterComponentUI, beforeEnter: adminGuard },
	{ path: "/top-ticker-component-ui", name: "TopTickerComponentUI", component: TopTickerComponentUI, beforeEnter: adminGuard },
	{ path: "/landing-component-ui", name: "LandingComponentUI", component: LandingComponentUI, beforeEnter: adminGuard },
	{ path: "/retailer-component-ui", name: "RetailerComponentUI", component: RetailerComponentUI, beforeEnter: adminGuard },
	{ path: "/choose-place-component-ui", name: "ChoosePlaceComponentUI", component: ChoosePlaceComponentUI, beforeEnter: adminGuard },
	{ path: "/store-details-component-ui", name: "StoreDetailsComponentUi", component: StoreDetailsComponentUi, beforeEnter: adminGuard },
	{ path: "/header-component-ui", name: "HeaderComponentUI", component: HeaderComponentUI, beforeEnter: adminGuard },
	{ path: "/common-component-ui", name: "CommonComponentUI", component: CommonComponentUI, beforeEnter: adminGuard },


	/* Exclusive Brand Page */
	{ path: "/exclusive-brand-section-one-cms", name: "ExclusiveBrandSectionOneCMS", component: ExclusiveBrandSectionOneCMS, beforeEnter: adminGuard },
	{ path: "/exclusive-brand-section-two-cms", name: "ExclusiveBrandSectionTwoCMS", component: ExclusiveBrandSectionTwoCMS, beforeEnter: adminGuard },
	{ path: "/exclusive-brand-section-three-cms", name: "ExclusiveBrandSectionThreeCMS", component: ExclusiveBrandSectionThreeCMS, beforeEnter: adminGuard },
	{ path: "/exclusive-brand-page-cms", name: "ExclusiveBrandPageCMS", component: ExclusiveBrandPageCMS, beforeEnter: adminGuard },

	/* Offer Pop Up */
	{ path: "/offer-pop-up", name: "OfferPopup", component: OfferPopup, beforeEnter: adminGuard },
	{ path: "/offer-pop-up-details/:retailerId/:retailerName", name: "OfferPopupDetails", component: OfferPopupDetails, beforeEnter: adminGuard },

	/* Newsletter Pop Up */
	{ path: "/newsletter-pop-up", name: "NewsletterPopup", component: NewsletterPopup, beforeEnter: adminGuard },
	{ path: "/newsletter-pop-up-details/:retailerId/:retailerName", name: "NewsletterPopupDetails", component: NewsletterPopupDetails, beforeEnter: adminGuard },

	/* SEO */
	{ path: '/site-map', name: 'SiteMapCMS', component: SiteMapCMS, beforeEnter: adminGuard },
	{ path: "/robot-txt-cms", name: "RobotTxt", component: RobotTxt, beforeEnter: adminGuard },

	// Mobile Banner

	{ path: "/mobile-banner-cms", name: "MobileBannerCms", component: MobileBannerCms, beforeEnter: adminGuard },

	// Deals Special

	{ path: "/deals-special-cms", name: "DealsSpecialCms", component: DealsSpecialCms, beforeEnter: adminGuard },

	// Header Special Section

	{ path: "/header-special-section-cms", name: "HeaderSpecialSectionCms", component: HeaderSpecialSectionCms, beforeEnter: adminGuard },

	// Brand
	{ path: "/brand-details-page/brand/add-brand", name: "AddBrand", component: AddBrand, beforeEnter: adminGuard },
	{ path: "/brand-details-page/brand/:id", name: "UpdateBrand", component: UpdateBrand, beforeEnter: guard },
	{ path: "/brand-details-page/brand", name: "Brand", component: Brand, beforeEnter: adminGuard },

	// Product
	{ path: "/brand-details-page/product/brand", name: "ProductBrand", component: ProductBrand, beforeEnter: adminGuard },
	{ path: "/brand-details-page/product/brand/:customBrandId", name: "CustomProductList", component: CustomProductList, beforeEnter: adminGuard },
	{ path: "/brand-details-page/product/brand/:customBrandId/product/add-product", name: "AddCustomProduct", component: AddCustomProduct, beforeEnter: adminGuard },
	{ path: "/brand-details-page/product/brand/:customBrandId/product/update-product/:customProductId", name: "UpadateCustomProduct", component: UpadateCustomProduct, beforeEnter: adminGuard },

	// Retailer Meta
	{ path: "/retailer-meta-data/add", name: "AddRetailerMetaData", component: AddRetailerMetaData, beforeEnter: adminGuard },
	{ path: "/retailer-meta-data/:id", name: "UpdateRetailerMetaData", component: UpdateRetailerMetaData, beforeEnter: guard },
	{ path: "/retailer-meta-data", name: "RetailerMetaData", component: RetailerMetaData, beforeEnter: adminGuard },

	// Product Category Meta
	{ path: "/product-category-meta-data/add", name: "AddProductCategoryMetaData", component: AddProductCategoryMetaData, beforeEnter: adminGuard },
	{ path: "/product-category-meta-data/:id", name: "UpdateProductCategoryMetaData", component: UpdateProductCategoryMetaData, beforeEnter: guard },
	{ path: "/product-category-meta-data", name: "ProductCategoryMetaData", component: ProductCategoryMetaData, beforeEnter: adminGuard },

	// Advertisement Banner
	{ path: "/advertisement-banner/add", name: "AddAdvertisementBanner", component: AddAdvertisementBanner, beforeEnter: adminGuard },
	{ path: "/advertisement-banner/:id", name: "EditAdvertisementBanner", component: EditAdvertisementBanner, beforeEnter: guard },
	{ path: "/advertisement-banner", name: "AdvertisementBanner", component: AdvertisementBanner, beforeEnter: adminGuard },

	// New SEO
	{ path: "/product-meta-mapping", name: "ProductMetaMapping", component: ProductMetaMapping, beforeEnter: guard },
	{ path: "/page-schema", name: "PageSchema", component: PageSchema, beforeEnter: guard },
	{ path: "/seo-content-text-cms", name: "SeoContentTextCMS", component: SeoContentTextCMS, beforeEnter: guard },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
