<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">About Us Page CMS</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">About Us Page CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">About Us Page CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-lg-8 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="col-12 mb-3">
												<label class="form-label">Page Title:</label>
												<input type="text" v-model="cmsData.pageTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter page title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Section Two Title:</label>
												<input type="text" v-model="cmsData.sectionTwoTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter section two title"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Section Two Subtitle:</label>
												<textarea type="text" v-model="cmsData.sectionTwoSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter section two subtitle" rows="3"></textarea>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Location Section Title:</label>
												<input type="text" v-model="cmsData.locationSectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter location section title"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Location Section Subtitle:</label>
												<textarea type="text" v-model="cmsData.locationSectionSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter location section subtitle" rows="3"></textarea>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Dispo Difference Section Title:</label>
												<input type="text" v-model="cmsData.dispoDifferenceSectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter dispo difference section title"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Dispo Difference Section Subtitle:</label>
												<textarea type="text" v-model="cmsData.dispoDifferenceSectionSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter dispo difference section subtitle" rows="3"></textarea>
											</div>
										</div>
									</div>
								</div>
								<div class="d-grid col-12 col-lg-8">
									<button type="submit" class="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/about-us-page-cms.js"></script>