<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Contact Us Section One</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Contact Us Section One</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Edit Section</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Icon: [Preferred Icon Size: 71X71, Max Upload Limit: 1MB]</label>
                                        <input class="form-control" @change="onIconChange" type="file" ref="icon">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-12">
                                            <img v-if="previewIcon" class="img-thumbnail" :src="previewIcon">
                                            <img v-else-if="icon" class="img-thumbnail" :src="icon" >
                                            <img v-else class="img-thumbnail" src="../../../../assets/images/default.png" height="100">
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Title:</label>
                                        <input type="text" v-model="title" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Subtitle:</label>
                                        <textarea type="text" v-model="subTitle" class="form-control" rows="5"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Button Text:</label>
                                        <input type="text" v-model="buttonText" class="form-control" />
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Button Link:</label>
                                        <input type="text" v-model="buttonLink" class="form-control" />
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update" type="button" class="btn btn-primary">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/edit-contact-us-section-one.js"></script>