<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Category</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Effects</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group"></div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Effects List</h6>
      <hr />
      <div class="card">
        <div class="p-2 mt-2" style="margin-right:5px">
          <div style="float:right">
            <input
              type="text"
              class="form-control"
              placeholder="Search Effects"
              v-model="searchKey"
            />
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table id="myTable" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Icon</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
                <template  v-for="(row, index) in tempDataList" :key="index">
                  <tr :class="searchKey ? 'disable-row' : ''">
                    <td>{{ row.effects }}</td>
                    <td>
                      <img :src="row.icon" height="50" />
                    </td>
                    <td>{{ $filters.formatDate(row.createdAt) }}</td>
                    <td>
                      <div class="d-flex order-actions">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          v-on:click="editData(row)"
                          class="edit-button"
                        >
                          <i class="bx bxs-edit"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </template>
              <tfoot>
                <tr>
                  <th>Name</th>
                  <th>Icon</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
          <rawDisplayer class="col-3" :value="dataList" title="List" />
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Effect</h5>
            <button
              type="button"
              class="btn-close"
              id="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <!-- <label for="exampleFormControlInput1" class="form-label">Category Name:</label>
              <input type="text" disabled v-model="singleData.categoryName" class="form-control" /> -->
              <div :style="{
                  border: '1px solid #ccc',
                  padding: '.375rem .75rem',
                  borderRadius: '0.25rem',
                  backgroundColor: '#e9ecef',
                  display: 'inline-block',
                  width: '100%',
                  marginTop: '5px',
                  fontSize: '1rem',
                  fontWeight: 400,
                  color: '#212529'
                }">
                <p class="mb-0"
                >{{ singleData.effects }}
                </p>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Icon: [Preferred Icon Size: 25X25, Max Upload Limit: 1MB]</label>
              <input class="form-control" @change="onIconChange" type="file" ref="icon" />
            </div>
            <div class="mb-3">
              <div class="col-md-12">
                <img v-if="previewIcon" class="img-thumbnail" :src="previewIcon" />
                <img v-else-if="singleData.icon" class="img-thumbnail" :src="singleData.icon" />
                <img v-else class="img-thumbnail" src="../../../assets/images/default.png" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="update">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}

.disable-row {
  pointer-events: none;
}
</style>

<script src="../js/effects.js"></script>