import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            id: this.$route.params.id,
            setURL: this.$serverURL + this.$api.brandDetailsPage.addCustomBrand,
            copyURL: this.$serverURL + this.$api.brandDetailsPage.copyCustomBrand,
            logo: "",
            previewLogo: "",

            banner: "",
            previewBanner: "",

            brand: [],
            brandList: [],
        }

    },
    async created() {
        document.title = "Dispo - Update Custom Brand";
        await this.getBrandList();
        await this.getCustomBrandDetails();
    },
    methods: {
        getCustomBrandDetails: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.setURL + '/' + this.id,
                    headers: {
                        ...authHeader()
                    }
                };
                this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data?.data?.length) {
                            this.singleBrand = response.data.data[0];
                            this.brand = {
                                value: {}
                            };
                            if (this.singleBrand.brandId) {
                                this.brand.value.brandId = this.singleBrand.brandId;
                            }
                            if (this.singleBrand.brandName) {
                                this.brand.value.brandName = this.singleBrand.brandName;
                            }
                            if (this.singleBrand.retailerId) {
                                this.brand.value.retailerId = this.singleBrand.retailerId;
                            }
                            if (this.singleBrand.retailerName) {
                                this.brand.value.retailerName = this.singleBrand.retailerName;
                            }
                            this.brand.label = `${this.singleBrand.brandName} (${this.singleBrand.retailerName})`;
                            if (this.singleBrand.banner) {
                                this.banner = this.singleBrand.banner;
                                this.previewBanner = this.singleBrand.banner;
                            }
                            if (this.singleBrand.logo) {
                                this.logo = this.singleBrand.logo;
                                this.previewLogo = this.singleBrand.logo;
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error.response.data.message);
                });
            } catch (error) {
                console.log(error);
            }
        },
        uploadBannerImage: function (event) {
            this.banner = event.target.files[0];
            let input = this.$refs.bannerInput;
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewBanner = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadLogoImage: function (event) {
            this.logo = event.target.files[0];
            let input = this.$refs.logoInput;
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewLogo = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        getBrandList: async function () {
            const url = this.$serverURL + this.$api.brandDetailsPage.getAllBrands;
            const config = {
                method: "GET",
                url: url,
                headers: {
                    ...authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.brandList = response.data.data.map((el) => {
                        return { value: el, label: `${el.brandName} (${el.retailerName})` };
                    });
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        checkValidation: function () {
            if (!this.brand || !(Object.keys(this.brand).length)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select brand!"
                });
                return false;
            }
            return true;
        },
        updateCustomBrand: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append("brandId", this.brand.value.brandId);
                    formData.append("brandName", this.brand.value.brandName);
                    formData.append("retailerId", this.brand.value.retailerId);
                    formData.append("retailerName", this.brand.value.retailerName);
                    formData.append("banner", this.banner);
                    formData.append("logo", this.logo);

                    let config = {
                        method: 'PATCH',
                        url: this.setURL + '/' + this.id,
                        data: formData,
                        headers: {
                            ...authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.$axios(config).then((response) => {
                        if (response.status == 200) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }
                        this.$router.push("/brand-details-page/brand");
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        },
        copyBrandForOtherStores: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    const data = {
                        brandId: this.brand.value.brandId,
                        brandName: this.brand.value.brandName,
                        retailerId: this.brand.value.retailerId,
                        retailerName: this.brand.value.retailerName,
                        banner: this.banner,
                        logo: this.logo,
                    }

                    let config = {
                        method: 'POST',
                        url: this.copyURL,
                        data,
                        headers: {
                            ...authHeader(),
                        }
                    };
                    await this.$axios(config).then((response) => {
                        if (response.status == 201) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }
                        this.$router.push("/brand-details-page/brand");
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        }
    }
}