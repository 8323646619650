<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Home</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Blog Details Page</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Blog Details Component UI CMS</h5>
                    <hr />
                    <div class="form-body mt-4" v-if="dataLoaded">
                        <div class="row mb-3">
                            <div class="col-md-3 mb-3">
                                <div class="input-group">
                                    <LvColorpicker label="Page Background Color:" v-model="cmsData.backgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <div class="input-group">
                                    <LvColorpicker label="Page Title Font Color:" v-model="cmsData.pageTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="d-grid">
                                <div class="d-grid gap-2 d-md-flex justify-content-md-start"> 
                                    <button class="btn btn-primary" @click="update" type="button">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/blog-details-component-ui.js"></script>