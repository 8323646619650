import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { authHeader } from "../../../auth";
import Editor from '@tinymce/tinymce-vue';
import Multiselect from '@vueform/multiselect';

export default {
    name: "EditRetailer",
    components: {
        Editor,
        Multiselect
    },
    data() {
        return {
            retailerId: this.$route.params.id,
            singleData: {},
            moreSections: [],
            editor: ClassicEditor,
            previewImage: "",
            updateImage: "",
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            storeImageList: [],
            deletedImage: [],
            previewStoreImages: [],
            disableButton: false,
        }
    },
    async created() {
        document.title = "Dispo - Edit Retailer Service";
        this.blogId = this.$route.params.id;
        await this.getRetailerData();
    },

    methods: {
        getRetailerData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.retailer.requestUrlNew + '/' + this.retailerId,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.singleData = response.data.data;
                    if (this.singleData.retailerMobileNumber == "null") {
                        this.singleData.retailerMobileNumber = "";
                    }
                    if (this.singleData.email == "null") {
                        this.singleData.email = "";
                    }
                    if (this.singleData.retailerImage.length > 0) {
                        this.storeImageList = this.singleData.retailerImage.map(el => {
                            return el.image;
                        })
                        this.previewStoreImages = this.singleData.retailerImage.map(el => {
                            return el.image;
                        })
                    }
                    if (this.storeImageList.length > 2) {
                        this.disableButton = true;
                    }

                    if (!this.singleData?.description) {
                        this.singleData.description = "";
                    }
                    if (!this.singleData?.moreSections) {
                        this.singleData.moreSections = '[{ "title": "", "description": "" }]';
                    }

                    this.moreSections = JSON.parse(this.singleData.moreSections);
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onImageChange(event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function () {
            const formData = new FormData();
            formData.append("featuredImage", this.updateImage ? this.updateImage : this.singleData.featuredImage);
            formData.append("retailerMobileNumber", this.singleData.retailerMobileNumber);
            formData.append("email", this.singleData.email);
            formData.append("description", this.singleData.description);
            formData.append("accessible", this.singleData.accessible ? true : false);
            formData.append("minimumAge", this.singleData.minimumAge ? true : false);
            formData.append("atm", this.singleData.atm ? true : false);
            formData.append("brandVerified", this.singleData.brandVerified ? true : false);
            this.storeImageList.map((el) => {
                if (typeof el !== "string") {
                    formData.append("retailerImage", el);
                }
            });
            this.deletedImage.map((el) => {
                formData.append("deletedImage", el);
            });

            const temp = this.moreSections;
            if (temp?.[temp.length - 1].title != "" && temp?.[temp.length - 1].description != "") {
                formData.append("moreSections", JSON.stringify(this.moreSections));
            } else if (temp.length > 1) {
                this.moreSections.pop();
                formData.append("moreSections", JSON.stringify(this.moreSections));
            }

            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.retailer.requestURL + '/' + this.singleData.id,
                data: formData,
                headers: authHeader()
            };
            this.$swal.showLoading();
            await this.$axios(config).then(async (response) => {
                this.$swal.close();
                if (response.data.statusCode == 200) {
                    this.singleData = {};
                    this.$refs.image.value = "";
                    this.updateImage = "";
                    this.previewImage = "";
                    setTimeout(async () => {
                        await this.getRetailerData();
                    }, 2000)
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteSpecificationRow(pos) {
            this.moreSections?.splice(pos, 1);
        },
        addSpecification() {
            const temp = this.moreSections;
            if (temp?.[temp.length - 1].title != "" && temp?.[temp.length - 1].description != "") {
                this.moreSections?.push({ title: "", description: "" });
            }
        },
        uploadStoreImages() {
            this.storeImageList.push(this.$refs.storeImages.files[0]);
            if (this.storeImageList.length > 2) {
                this.disableButton = true;
            }
            let input = this.$refs.storeImages
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewStoreImages.push(e.target.result)
                }
                reader.readAsDataURL(file[0])
            }
            this.$refs.storeImages.value = null;
        },
        removeUploadedStoreImage(index) {
            this.previewStoreImages.splice(index, 1);
            if (typeof this.storeImageList[index] === "string") {
                if (this.storeImageList[index].startsWith("https")) {
                    this.deletedImage.push(this.storeImageList[index]);
                }
            }
            this.storeImageList.splice(index, 1);
            if (this.storeImageList.length > 2) {
                this.disableButton = true;
            } else {
                this.disableButton = false;
            }
        },
    }
}