export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.pageMetaCMS,
            cmsData: {},
        }
    },
    async created() {
        document.title = "Dispo - Site Page Meta CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async updateCms() {
            try {
                let data = {
                    'signinPageMetaKeyword': this.cmsData.signinPageMetaKeyword,
                    'signinPageMetaDescription': this.cmsData.signinPageMetaDescription,
                    'signupPageMetaKeyword': this.cmsData.signupPageMetaKeyword,
                    'signupPageMetaDescription': this.cmsData.signupPageMetaDescription,
                    'homePageMetaKeyword': this.cmsData.homePageMetaKeyword,
                    'homePageMetaDescription': this.cmsData.homePageMetaDescription,
                    'shopPageMetaKeyword': this.cmsData.shopPageMetaKeyword,
                    'shopPageMetaDescription': this.cmsData.shopPageMetaDescription,
                    'productDetailsPageMetaKeyword': this.cmsData.productDetailsPageMetaKeyword,
                    'productDetailsPageMetaDescription': this.cmsData.productDetailsPageMetaDescription,
                    'wishlistPageMetaKeyword': this.cmsData.wishlistPageMetaKeyword,
                    'wishlistPageMetaDescription': this.cmsData.wishlistPageMetaDescription,
                    'cartPageMetaKeyword': this.cmsData.cartPageMetaKeyword,
                    'cartPageMetaDescription': this.cmsData.cartPageMetaDescription,
                    'aboutUsPageMetaKeyword': this.cmsData.aboutUsPageMetaKeyword,
                    'aboutUsPageMetaDescription': this.cmsData.aboutUsPageMetaDescription,
                    'categoriesPageMetaKeyword': this.cmsData.categoriesPageMetaKeyword,
                    'categoriesPageMetaDescription': this.cmsData.categoriesPageMetaDescription,
                    'brandPageMetaKeyword': this.cmsData.brandPageMetaKeyword,
                    'brandPageMetaDescription': this.cmsData.brandPageMetaDescription,
                    'specialsPageMetaKeyword': this.cmsData.specialsPageMetaKeyword,
                    'specialsPageMetaDescription': this.cmsData.specialsPageMetaDescription,
                    'locationPageMetaKeyword': this.cmsData.locationPageMetaKeyword,
                    'locationPageMetaDescription': this.cmsData.locationPageMetaDescription,
                    'contactUsPageMetaKeyword': this.cmsData.contactUsPageMetaKeyword,
                    'contactUsPageMetaDescription': this.cmsData.contactUsPageMetaDescription,
                    'faqPageMetaKeyword': this.cmsData.faqPageMetaKeyword,
                    'faqPageMetaDescription': this.cmsData.faqPageMetaDescription,
                    'careerPageMetaKeyword': this.cmsData.careerPageMetaKeyword,
                    'careerPageMetaDescription': this.cmsData.careerPageMetaDescription,
                    'careerDepartmentPageMetaKeyword': this.cmsData.careerDepartmentPageMetaKeyword,
                    'careerDepartmentPageMetaDescription': this.cmsData.careerDepartmentPageMetaDescription,
                    'careerDetailsPageMetaKeyword': this.cmsData.careerDetailsPageMetaKeyword,
                    'careerDetailsPageMetaDescription': this.cmsData.careerDetailsPageMetaDescription,
                    'blogPageMetaKeyword': this.cmsData.blogPageMetaKeyword,
                    'blogPageMetaDescription': this.cmsData.blogPageMetaDescription,
                    'blogDetailsPageMetaKeyword': this.cmsData.blogDetailsPageMetaKeyword,
                    'blogDetailsPageMetaDescription': this.cmsData.blogDetailsPageMetaDescription,
                    'privacyPageMetaKeyword': this.cmsData.privacyPageMetaKeyword,
                    'privacyPageMetaDescription': this.cmsData.privacyPageMetaDescription,
                    'termsPageMetaKeyword': this.cmsData.termsPageMetaKeyword,
                    'termsPageMetaDescription': this.cmsData.termsPageMetaDescription,
                    'profilePageMetaKeyword': this.cmsData.profilePageMetaKeyword,
                    'profilePageMetaDescription': this.cmsData.profilePageMetaDescription,
                    'successPageMetaKeyword': this.cmsData.successPageMetaKeyword,
                    'successPageMetaDescription': this.cmsData.successPageMetaDescription,
                    'retailerPageMetaKeyword': this.cmsData.retailerPageMetaKeyword,
                    'retailerPageMetaDescription': this.cmsData.retailerPageMetaDescription,
                    'forgotPasswordPageMetaKeyword': this.cmsData.forgotPasswordPageMetaKeyword,
                    'forgotPasswordPageMetaDescription': this.cmsData.forgotPasswordPageMetaDescription,
                    'choosePlacePageMetaKeyword': this.cmsData.choosePlacePageMetaKeyword,
                    'choosePlacePageMetaDescription': this.cmsData.choosePlacePageMetaDescription,
                    'resetPasswordPageMetaKeyword': this.cmsData.resetPasswordPageMetaKeyword,
                    'resetPasswordPageMetaDescription': this.cmsData.resetPasswordPageMetaDescription,
                    'invoicePageMetaKeyword': this.cmsData.invoicePageMetaKeyword,
                    'invoicePageMetaDescription': this.cmsData.invoicePageMetaDescription,
                    'shopBrandPageMetaKeyword': this.cmsData.shopBrandPageMetaKeyword,
                    'shopBrandPageMetaDescription': this.cmsData.shopBrandPageMetaDescription ? this.cmsData.shopBrandPageMetaDescription : 'Dispo - your go-to Michigan cannabis store for convenient weed delivery. Explore a wide selection of products at our dispensary. Order now!',
                    'shopCategoryPageMetaKeyword': this.cmsData.shopCategoryPageMetaKeyword,
                    'shopCategoryPageMetaDescription': this.cmsData.shopCategoryPageMetaDescription ? this.cmsData.shopCategoryPageMetaDescription : 'Dispo - your go-to Michigan cannabis store for convenient weed delivery. Explore a wide selection of products at our dispensary. Order now!',
                    exclusiveBrandPageMetaKeyword: this.cmsData.exclusiveBrandPageMetaKeyword,
                    exclusiveBrandPageMetaDescription: this.cmsData.exclusiveBrandPageMetaDescription ? this.cmsData.exclusiveBrandPageMetaDescription : 'Dispo - Explore the exclusive cannabis brands of Michigan. Order now!',
                    featuredBrandPageMetaKeyword: this.cmsData.featuredBrandPageMetaKeyword,
                    featuredBrandPageMetaDescription: this.cmsData.featuredBrandPageMetaDescription ? this.cmsData.featuredBrandPageMetaDescription : 'Dispo - Explore the featured cannabis brands of Michigan. Order now!',
                    featuredBrandDetailsPageMetaKeyword: this.cmsData.featuredBrandDetailsPageMetaKeyword,
                    featuredBrandDetailsPageMetaDescription: this.cmsData.featuredBrandDetailsPageMetaDescription ? this.cmsData.featuredBrandDetailsPageMetaDescription : 'Dispo - Featured cannabis brand of Michigan.',
                    sitemapPageMetaKeyword: this.cmsData.sitemapPageMetaKeyword,
                    sitemapPageMetaDescription: this.cmsData.sitemapPageMetaDescription,
                    storePageMetaKeyword: this.cmsData.storePageMetaKeyword,
                    storePageMetaDescription: this.cmsData.storePageMetaDescription ? this.cmsData.storePageMetaDescription : 'Dispo - Explore our cannabis stores!',
                    dealsSpecialPageMetaKeyword: this.cmsData.dealsSpecialPageMetaKeyword,
                    dealsSpecialPageMetaDescription: this.cmsData.dealsSpecialPageMetaDescription ? this.cmsData.dealsSpecialPageMetaDescription: 'Dispo - Explore special deals on different stores!',
                    orderPageMetaKeyword: this.cmsData.orderPageMetaKeyword,
                    orderPageMetaDescription: this.cmsData.orderPageMetaDescription,
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}