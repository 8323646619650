import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            setURL: this.$serverURL + this.$api.brandDetailsPage.addCustomProduct,
            customBrandId: this.$route.params.customBrandId,
            image: "",
            previewImage: "",
            name: "",
            description: "",
            sellingPoint: "",
            link: "",
            category: [],
            categoryList: [],
        }

    },
    async created() {
        document.title = "Dispo - Add Custom Product";
        await this.getCategoryList();
    },
    methods: {
        uploadImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.imageInput;
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0]);
            }
        },
        checkValidation: function () {
            // if (!this.name) {
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Please enter product name!"
            //     });
            //     return false;
            // }
            // if (!this.description) {
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Please enter product description!"
            //     });
            //     return false;
            // }
            // if (!this.sellingPoint) {
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Please enter product selling point!"
            //     });
            //     return false;
            // }
            if (!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product image!"
                });
                return false;
            }
            if (!this.link) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product link!"
                });
                return false;
            }
            if (!(Object.keys(this.category).length)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select category!"
                });
                return false;
            }
            return true;
        },
        addProduct: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append("name", this.name);
                    formData.append("description", this.description);
                    formData.append("sellingPoint", this.sellingPoint);
                    formData.append("image", this.image);
                    formData.append("link", this.link);
                    formData.append("customBrandId", this.customBrandId);
                    formData.append("categoryId", this.category.value.id);
                    formData.append("categoryName", this.category.value.categoryName);

                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            ...authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.$axios(config).then((response) => {
                        if (response.status == 201) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }

                        this.image = "";
                        this.previewImage = "";
                        this.name = "";
                        this.description = "";
                        this.sellingPoint = "";
                        this.link = "";
                        this.category = [];
                        this.$refs.imageInput = null;
                        this.$router.push("/brand-details-page/product/brand/" + this.customBrandId);
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        },
        toTitleCase: function (str) {
            if (str) {
                return str.replace(/\w\S*/g, function (txt) {
                    let text = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    if (text.includes("_")) {
                        let str = text.split("_");
                        let final_str = "";
                        for (let i = 0; i < str.length; i++) {
                            let new_str = str[i].charAt(0).toUpperCase() + str[i].substr(1).toLowerCase();
                            final_str += new_str + ' ';
                        }
                        return final_str.trim();
                    }
                    return text;
                });
            } else {
                return;
            }
        },
        getCategoryList: async function () {
            const url = this.$serverURL + this.$api.brandDetailsPage.getCategories;
            const config = {
                method: "GET",
                url: url,
                headers: {
                    ...authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.categoryList = response.data.data.map((el) => {
                        return { value: el, label: this.toTitleCase(el.categoryName) };
                    });
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        }
    },
}