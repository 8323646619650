<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Retailer</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Retailer Service</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button type="button" class="btn btn-primary" @click="$router.push('/retailer')">
              <i class="bx bx-coin-stack"></i> Retailer List
            </button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-12 mx-auto">
              <h6 class="mt-2 mb-2 text-uppercase">Edit Retailer Information</h6>
              <hr />
              <div class="form-body mt-4">
                <div class="row mb-3">
                  <div class="col-lg-8">
                    <div class="border border-3 p-1 rounded">
                      <div class="card-body p-5">
                        <div class="mb-3">
                          <label for="exampleFormControlInput1" class="form-label">Retailer Name:</label>
                          <input
                            type="text"
                            disabled
                            v-model="singleData.retailerName"
                            class="form-control"
                          />
                        </div>
                        <div class="mb-3">
                          <label for="exampleFormControlInput1" class="form-label">Contact Number:</label>
                          <input
                            type="text"
                            v-model="singleData.retailerMobileNumber"
                            class="form-control"
                          />
                        </div>
                        <div class="mb-3">
                          <label for="exampleFormControlInput1" class="form-label">Email:</label>
                          <input type="email" v-model="singleData.email" class="form-control" />
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Description:</label>
                          <ckeditor
                            :editor="editor"
                            v-model="singleData.description"
                            :config="editorConfig"
                          ></ckeditor>
                        </div>
                        <div class="mb-3">
                          <label
                            class="form-label"
                          >Image: [Preferred Image Size: 510X500, Max Upload Limit: 1MB]</label>
                          <input
                            class="form-control"
                            @change="onImageChange"
                            type="file"
                            ref="image"
                          />
                        </div>
                        <div class="mb-3">
                          <div class="col-md-12">
                            <img
                              v-if="previewImage"
                              class="img-thumbnail"
                              :src="previewImage"
                              style="height: 150px;"
                            />
                            <img
                              v-else-if="singleData.featuredImage"
                              class="img-thumbnail"
                              :src="singleData.featuredImage"
                              style="height: 150px;"
                            />
                          </div>
                        </div>

                        <div class="border border-3 p-4 rounded mb-4">
                          <label for="exampleInputEmail1" class="mb-3">
                            Store Images (Max Limit 3):
                            <strong>
                              [Preferred Image Size: 900X550, Max Upload Limit:
                              1MB]
                            </strong>
                          </label>
                          <input
                            type="file"
                            style="display: none"
                            @change="uploadStoreImages"
                            ref="storeImages"
                          />

                          <div class="card text-center">
                            <div
                              @click="$refs.storeImages.click()"
                              class="card-body btn btn-outline-primary"
                              :style="[
                          disableButton == true
                            ? { 'pointer-events': 'none' }
                            : '',
                        ]"
                            >
                              <h5>Select Image</h5>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="col-sm-3 mb-2"
                              v-for="(data, index) in previewStoreImages"
                              :key="index"
                            >
                              <i
                                class="fa-solid fa-circle-xmark close-button"
                                @click="removeUploadedStoreImage(index)"
                              ></i>
                              <img :src="data" height="100" />
                            </div>
                          </div>
                        </div>

                        <div class="border border-3 p-4 rounded mb-3">
                          <h6>Amenities</h6>
                          <hr />
                          <div class="mb-3 d-flex">
                            <label class="form-label">Accessible:</label>
                            <div class="form-check form-switch ms-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                v-model="singleData.accessible"
                              />
                            </div>
                          </div>
                          <div class="mb-3 d-flex">
                            <label class="form-label">Minimum Age:</label>
                            <div class="form-check form-switch ms-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                v-model="singleData.minimumAge"
                              />
                            </div>
                          </div>
                          <div class="mb-3 d-flex">
                            <label class="form-label">ATM:</label>
                            <div class="form-check form-switch ms-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                v-model="singleData.atm"
                              />
                            </div>
                          </div>
                          <div class="d-flex">
                            <label class="form-label">Brand Verified:</label>
                            <div class="form-check form-switch ms-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                v-model="singleData.brandVerified"
                              />
                            </div>
                          </div>
                        </div>

                        <h6 class="mb-2 mt-4">More Information</h6>
                        <div
                          class="border border-3 p-4 rounded mb-3"
                          v-for="(item, pos) in moreSections"
                          :key="pos"
                        >
                          <div class="d-flex justify-content-end">
                            <button
                              v-if="pos > 0"
                              @click="deleteSpecificationRow(pos)"
                              type="button"
                              class="btn-close btn btn-danger"
                            ></button>
                          </div>
                          <div class="row mb-3">
                            <div class="mb-3">
                              <label class="form-label">Title:</label>
                              <input type="test" v-model="item.title" class="form-control" />
                            </div>
                            <div>
                              <label class="form-label">Description:</label>
                              <ckeditor
                                :editor="editor"
                                v-model="item.description"
                                :config="editorConfig"
                              ></ckeditor>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <button
                            type="button"
                            @click="addSpecification"
                            class="btn btn-primary"
                          >Add More</button>
                        </div>

                        <div class="d-grid mt-5">
                          <button @click="update" type="button" class="btn btn-primary">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/edit-retailer.js"></script>

<style scoped>
@import "../../../../public/core/assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css";
@import "../../../../public/core/assets/plugins/metismenu/css/metisMenu.min.css";
@import "../../../../public/core/assets/plugins/smart-wizard/css/smart_wizard_all.min.css";

.margin-auto {
  margin-left: 480px;
}
</style>