// import { authHeader } from '../../../../../auth';

export default {
    name: "AddWhyJoinUs",
    data() {
        return {
            retailerName: this.$route.query.name,
            retailerId: this.$route.query.id,
            title: '',
            subTitle: '',
            buttonText: '',
            buttonLink: '',
            icon: '',
            previewIcon: null,
            typeList: ['contact', 'email', 'chat'],
        }
    },
    async created() {
        document.title = "Dispo - Add Career Department";
    },
    methods: {
        onIconChange: function (event) {
            this.icon = event.target.files[0];
            let input = this.$refs.icon;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewIcon = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if (!this.subTitle) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter subtitle!"
                });
                return false;
            }
            if (!this.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter buttonText!"
                });
                return false;
            }
            if (!this.buttonLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter buttonLink!"
                });
                return false;
            }
            if (!this.icon) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload icon!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("icon", this.icon);
                formData.append("retailerId", this.retailerId);
                formData.append("retailerName", this.retailerName);
                formData.append("title", this.title);
                formData.append("subTitle", this.subTitle);
                formData.append("buttonText", this.buttonText);
                formData.append("buttonLink", this.buttonLink);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.contactUsSectionOneURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.title = '';
                    this.subTitle = '';
                    this.buttonText = '';
                    this.buttonLink = '';
                    this.$refs.icon.value = null;
                    this.$$router.push({ path: '/section-content-list', query: { id: this.retailerId, name: this.retailerName } });
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}