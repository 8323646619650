import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            id: this.$route.params.id,
            setURL: this.$serverURL + this.$api.retailer.meta,

            retailer: [],
            retailerList: [],

            contactPageTitle: "",
            contactMetaDescription: "",
            contactMetaKeyword: "",
            contactCanonicalTag: "",
            storePageTitle: "",
            storeMetaDescription: "",
            storeMetaKeyword: "",
            storeCanonicalTag: "",

            singleRetailer: {},
        }

    },
    async created() {
        document.title = "Dispo - Update Retailer Meta";
        await this.getRetailerList();
        await this.getRetailerMetaDetails();
    },
    methods: {
        getRetailerMetaDetails: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.setURL + '/' + this.id,
                    headers: {
                        ...authHeader()
                    }
                };
                this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data?.data?.length) {
                            this.singleRetailer = response.data.data[0];
                            this.retailer = {
                                value: {},
                                label: ''
                            };
                            const foundRetailer = this.retailerList?.filter((retailer) => retailer.value.id === this.singleRetailer.retailerId);
                            this.retailer = foundRetailer?.[0];

                            this.retailer.value.id = this.singleRetailer.retailerId;
                            this.retailer.value.name = this.singleRetailer.retailerName;
                            this.contactPageTitle = this.singleRetailer.contactPageTitle;
                            this.contactMetaDescription = this.singleRetailer.contactMetaDescription;
                            this.contactMetaKeyword = this.singleRetailer.contactMetaKeyword;
                            this.contactCanonicalTag = this.singleRetailer.contactCanonicalTag;
                            this.storePageTitle = this.singleRetailer.storePageTitle;
                            this.storeMetaDescription = this.singleRetailer.storeMetaDescription;
                            this.storeMetaKeyword = this.singleRetailer.storeMetaKeyword;
                            this.storeCanonicalTag = this.singleRetailer.storeCanonicalTag;
                        }
                    }
                }).catch(error => {
                    console.log(error.response.data.message);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getRetailerList: async function () {
            const url = this.$serverURL + this.$api.store.requestURL;
            const config = {
                method: "POST",
                url: url,
                headers: {
                    ...authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.retailerList = response.data.map((el) => {
                        return { value: el, label: `${el.name}` };
                    });
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        checkValidation: function () {
            if (!(Object.keys(this.retailer).length)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select retailer!"
                });
                return false;
            }
            return true;
        },
        updateRetailerMeta: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    const payload = {
                        retailerId: this.retailer.value.id,
                        retailerName: this.retailer.value.name,
                        contactPageTitle: this.contactPageTitle,
                        contactMetaDescription: this.contactMetaDescription,
                        contactMetaKeyword: this.contactMetaKeyword,
                        contactCanonicalTag: this.contactCanonicalTag,
                        storePageTitle: this.storePageTitle,
                        storeMetaDescription: this.storeMetaDescription,
                        storeMetaKeyword: this.storeMetaKeyword,
                        storeCanonicalTag: this.storeCanonicalTag,
                    }

                    const config = {
                        method: 'PATCH',
                        url: this.setURL + '/' + this.id,
                        data: payload,
                        headers: {
                            ...authHeader(),
                        }
                    };
                    await this.$axios(config).then((response) => {
                        if (response.status == 200) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }
                        this.$router.push("/retailer-meta-data");
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        },
    }
}