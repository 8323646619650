<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Hero Section Slider</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Hero Section Slider</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              @click="$router.push(`/add-hero-section-slider/${retailerId}/${retailerName}`)"
              type="button"
              class="btn btn-primary"
            >
              <i class="bx bxs-plus-square"></i>Add New Slider
            </button>
          </div>
        </div>
      </div>
      <div style="display:flex; justify-content:space-between">
        <h6 class="mb-0 text-uppercase">Hero Section Slider Table</h6>
        <div class="d-flex order-actions" v-if="retailerName == 'default'">
          <div
            style="display: flex; justify-content: center; align-items: center;cursor:pointer;"
            @click="$router.push({ path: '/hero-section-slider'})"
          >
            <span style="margin: 0 auto;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px; margin: 0 auto;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight:600">Go Back</h6>
          </div>
        </div>
      </div>
      <div v-if="retailerName != 'default'">
        <hr />
        <div class="card">
          <div class="card-body" style="display: flex; align-items: center;">
            <h5 style="margin: 0;">{{retailerName}}</h5>
            <div
              style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
              @click="$router.push({ path: '/hero-section-slider'})"
            >
              <span style="margin-right: 5px;">
                <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
              </span>
              <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div
        class="page-breadcrumb d-none d-sm-flex align-items-center mb-3"
        v-if="tempDataList.length>0"
      >
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              @click="handleSetDefault"
              :disabled="isDefault"
            >
              <i class="bx bxs-plus-square"></i>Set Default
            </button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id class="table table-striped table-bordered">
              <thead>
                <tr>
                  <!-- <th>Sl</th> -->
                  <th>Sl</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Link</th>
                  <th>Action</th>
                </tr>
              </thead>
              <draggable
                v-model="tempDataList"
                tag="tbody"
                item-key="data.id"
                @update="onDragUpdate"
              >
                <template #item="{ element: data }">
                  <tr>
                    <td>{{ data.position }}</td>
                    <td>
                      <img :src="data.image" height="70" />
                    </td>
                    <td>{{ data.title }}</td>
                    <td>
                      <span
                        class="metaModal"
                        data-bs-toggle="modal"
                        data-bs-target="#showModal"
                        @click="updateModalValue(data.description)"
                        v-html="data.description ? data.description.length > 50 ? data.description.slice(0, 30) + ' ...' : data.description : 'No description found.'"
                      ></span>
                    </td>
                    <td>{{ data.link.length > 50 ? data.link.slice(0, 30) + ' ...' : data.link }}</td>
                    <td>
                      <div class="d-flex order-actions">
                        <a
                          href="javascript:void(0);"
                          @click="$router.push(`/edit-hero-section-slider/${data.id}/${retailerId}/${retailerName}`)"
                          class="edit-button"
                        >
                          <i class="bx bxs-edit"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          v-on:click="deleteContent(data['id'])"
                          class="ms-3 delete-button"
                        >
                          <i class="bx bxs-trash"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </template>
              </draggable>
              <tfoot>
                <tr>
                  <!-- <th>Sl</th> -->
                  <th>Sl</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Link</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Subtitle Modal -->
    <div
      class="modal fade"
      id="showModal"
      tabindex="-1"
      aria-labelledby="showModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="showModalLabel">Description</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p class="form-control mb-1" type="text" v-html="description"></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script src="../js/hero-section-slider-list.js"></script>

<style scoped>
@import "../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.metaModal:hover {
  cursor: pointer;
  color: #09f;
}
</style>