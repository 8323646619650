<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Deals Special CMS</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">Deals Special CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Deals Special CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-lg-12 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="col-6 mb-3">
												<label class="form-label">Banner One Link:</label>
												<input type="text" v-model="cmsData.bannerOneLink" class="form-control"
													id="inputPageHeaderTitle" placeholder="Enter banner one link" />
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Banner Two Link:</label>
												<input type="text" v-model="cmsData.bannerTwoLink" class="form-control"
													id="inputPageHeaderPageName" placeholder="Enter banner two link" />
											</div>
											<div class="col-6 mb-3">
												<div class="row">
													<div class="col-12">
														<label for="inputProductTitle" class="form-label">
															Banner One Image:
															<strong>
																[Preferred Image Size: 722X301, Max Upload Limit:
																1MB]
															</strong>
														</label>
													</div>
													<div class="col-12">
														<input class="form-control mt-3" type="file" ref="ref1"
															accept="image/*"
															@change="handleUploadImage('bannerOneImage', 'imageOne', 'ref1', $event)" />
													</div>
													<div class="mt-3">
														<img style="position: relative; left: 11px" v-if="preview.imageOne"
															:src="preview.imageOne" height="100" />
														<img style="position: relative; left: 11px"
															v-else-if="cmsData.bannerOneImage" :src="cmsData.bannerOneImage"
															height="100" />
													</div>
												</div>
											</div>
											<div class="col-6 mb-3">
												<div class="row">
													<div class="col-12">
														<label for="inputProductTitle" class="form-label">
															Banner Two Image:
															<strong>
																[Preferred Image Size: 231X301, Max Upload Limit:
																1MB]
															</strong>
														</label>
													</div>
													<div class="col-12">
														<input class="form-control mt-3" type="file" ref="ref2"
															accept="image/*"
															@change="handleUploadImage('bannerTwoImage', 'imageTwo', 'ref2', $event)" />
													</div>
													<div class="mt-3">
														<img style="position: relative; left: 11px" v-if="preview.imageTwo"
															:src="preview.imageTwo" height="100" />
														<img style="position: relative; left: 11px"
															v-else-if="cmsData.bannerTwoImage" :src="cmsData.bannerTwoImage"
															height="100" />
													</div>
												</div>

											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Banner Three Link:</label>
												<input type="text" v-model="cmsData.bannerThreeLink" class="form-control"
													id="inputPageHeaderTitle" placeholder="Enter banner three link" />
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Banner Three Mobile Link:</label>
												<input type="text" v-model="cmsData.bannerThreeMobileLink"
													class="form-control" id="inputPageHeaderTitle"
													placeholder="Enter banner three mobile link" />
											</div>
											<div class="col-6 mb-3">
												<div class="row">
													<div class="col-12">
														<label for="inputProductTitle" class="form-label">
															Banner Three Image:
															<strong>
																[Preferred Image Size: 231X323, Max Upload Limit:
																1MB]
															</strong>
														</label>
													</div>
													<div class="col-12">
														<input class="form-control mt-3" type="file" ref="ref3"
															accept="image/*"
															@change="handleUploadImage('bannerThreeImage', 'imageThree', 'ref3', $event)" />
													</div>
													<div class="mt-3">
														<img style="position: relative; left: 11px"
															v-if="preview.imageThree" :src="preview.imageThree"
															height="100" />
														<img style="position: relative; left: 11px"
															v-else-if="cmsData.bannerThreeImage"
															:src="cmsData.bannerThreeImage" height="100" />
													</div>
												</div>
											</div>
											<div class="col-6 mb-3">
												<div class="row">
													<div class="col-12">
														<label for="inputProductTitle" class="form-label">
															Banner Three Mobile Image:
															<strong>
																[Preferred Image Size: 1420X602, Max Upload Limit:
																1MB]
															</strong>
														</label>
													</div>
													<div class="col-12">
														<input class="form-control mt-3" type="file" ref="ref4"
															accept="image/*"
															@change="handleUploadImage('bannerThreeMobileImage', 'imageFour', 'ref4', $event)" />
													</div>
													<div class="mt-3">
														<img style="position: relative; left: 11px" v-if="preview.imageFour"
															:src="preview.imageFour" height="100" />
														<img style="position: relative; left: 11px"
															v-else-if="cmsData.bannerThreeMobileImage"
															:src="cmsData.bannerThreeMobileImage" height="100" />
													</div>
												</div>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Product Slider One title:</label>
												<input type="text" v-model="cmsData.productSliderOneTitle"
													class="form-control" id="inputPageHeaderTitle"
													placeholder="Enter product slider one title" />
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Product Slider Two title:</label>
												<input type="text" v-model="cmsData.productSliderTwoTitle"
													class="form-control" id="inputPageHeaderTitle"
													placeholder="Enter product slider two title" />
											</div>
										</div>
									</div>
								</div>
								<div class="d-grid">
									<button type="submit" class="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/deals-special-cms.js"></script>