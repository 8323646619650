<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add Brand
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Add New Brand</h5>
            <hr />
            <div class="form-body mt-4">

              <div class="row">
                <div class="col-lg-8">

                  <div class="col-md-12 mb-3">
                    <label class="form-label">Select a Brand:</label>
                    <v-select placeholder="Select a Brand" v-model="brand" :options="brandList" :close-on-select="true" :searchable="true" />
                  </div>

                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label for="exampleInputEmail1">Banner:
                        <strong>[Preferred Image Size: 1567X486, Max Upload Limit:
                          1MB]</strong></label>
                      <input class="form-control mb-2" type="file" name="banner" id="banner" ref="bannerInput"
                        aria-describedby="imageHelp" accept="image/*" @change="uploadBannerImage" />
                      <img v-if="previewBanner" :src="previewBanner" height="100" />
                      <img v-else src="/core/assets/images/default.png" height="100" />
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label for="exampleInputEmail1">Logo:
                        <strong>[Preferred Image Size: 67X67, Max Upload Limit:
                          1MB]</strong></label>
                      <input class="form-control mb-2" type="file" name="logo" id="logo" ref="logoInput"
                        aria-describedby="imageHelp" accept="image/*" @change="uploadLogoImage" />
                      <img v-if="previewLogo" :src="previewLogo" height="100" />
                      <img v-else src="/core/assets/images/default.png" height="100" />
                    </div>
                  </div>

                  <div class="col-12 mt-3">
                    <div class="d-grid">
                      <button type="button" @click="addBrand" class="btn btn-primary">
                        Save Brand
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/add-brand.js'></script>