<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                <div class="col" v-if="isAdmin">
                    <div class="card radius-10 cp" @click="$router.push('/retailer')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Retailers</p>
                                    <h4 class="my-1">{{ totalRetailer }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-primary text-primary ms-auto">
                                    <i class="fa-solid fa-store"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col" v-if="isAdmin">
                    <div class="card radius-10 cp" @click="$router.push('/career')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Career</p>
                                    <h4 class="my-1">{{ totalCareer }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                                    <i class="fa-solid fa-briefcase"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10 cp" @click="$router.push('/career-applicant')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Career Applicant</p>
                                    <h4 class="my-1">{{ totalCareerApplicant }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                                    <i class="fa-solid fa-user-tie"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <!-- <div class="card radius-10 cp" @click="$router.push('/newsletter')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Subscriber</p>
                                    <h4 class="my-1">{{ totalSubscriber }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-info text-info ms-auto">
                                    <i class="fa-solid fa-newspaper"></i>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="card radius-10 cp" @click="$router.push('/career-applicant')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Today Total Career Application</p>
                                    <h4 class="my-1">{{ todayTotalCareerApplicant }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-info text-info ms-auto">
                                    <i class="fa-solid fa-user-tie"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card radius-10 cp" @click="$router.push('/blog-post')" v-if="isAdmin">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Article</p>
                                    <h4 class="my-1">{{ totalArticle }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-info text-info ms-auto">
                                    <i class="fa-solid fa-pen-nib"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card radius-10 cp" @click="$router.push('/contact')" v-if="isAdmin">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Contacts</p>
                                    <h4 class="my-1">{{ totalContact }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-info text-info ms-auto">
                                    <i class="fa-solid fa-address-book"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card radius-10 cp" @click="$router.push('/contact')" v-if="isAdmin">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Today Total Contacts</p>
                                    <h4 class="my-1">{{ todayTotalContact }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-info text-info ms-auto">
                                    <i class="fa-solid fa-address-book"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4" v-if="isAdmin">
                    <div class="card h-100">
                        <div class="card-body">
                            <div class="chart-container1">
                                <canvas id="retailerChart" style="position: relative; height:100vh; width:80vw;"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4" v-if="isAdmin">
                    <div class="card h-100">
                        <div class="card-body">
                            <LinkShortcut/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="isAdmin">
                <div class="col-xl-12 d-flex">
                    <div class="card radius-10 w-100 mt-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-around align-items-center">
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/hero-section-slider')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        Home Page Slider
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/header-cms')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        Header CMS
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/footer-cms')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        Footer CMS
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/faq')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;" >
                                        FAQ
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/reefer-friend')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        Reefer A Friend
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/home-page-cms')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;" >
                                        Home Page CMS
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/dashboard.js"></script>

<style scoped>
.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
.cp{
    cursor: pointer;
}
</style>