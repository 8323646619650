export default {
    data() {
        return {
            dataList: [],
            cmsData: {},
            setURL: this.$serverURL + this.$api.store.requestURL,
            cmsURL: this.$serverURL + this.$api.cms.storeCmsURL
        }
    },
    async created() {
        document.title = "Dispo - Home Hero Section Left";
        await this.getStoreDataList(this.setURL);
        await this.getData(this.cmsURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getData: async function (url) {
            let response = await this.getCmsData(url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        getStoreDataList: async function (url) {
            try {
                let config = {
                    method: "POST",
                    url: url
                }
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.dataList = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
    }
}