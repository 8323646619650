<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Reefer A Friend</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Reefer A Friend</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" :disabled="contentList.length>2" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add New Content
                        </button>
                    </div>
                    <!-- Add Category Modal -->
                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add New Content</h5>
                                        <button type="button" id="closeButton" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Title:</label>
                                        <input class="form-control mb-3" type="text" v-model="title" placeholder="Title" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Description:</label>
                                        <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" v-on:click="addContent" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Add Category Modal -->
                    <!-- Edit Category Modal -->
                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                        <form>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="editModalLabel">Edit Content</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">title:</label>
                                        <input class="form-control mb-3" type="text" v-model="singleContent.title" aria-label="default input example">
                                    </div>
									<div class="modal-body">
                                        <label class="form-label">Description:</label>
                                        <ckeditor :editor="editor" v-model="singleContent.description" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" v-on:click="updateContent(singleContent.id)" class="btn btn-primary" data-bs-dismiss="modal">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Edit Category Modal -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Reefer A Friend Content List</h6><hr/>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in contentList" :key="index">
                                    <td> {{ index + 1 }} </td>
                                    <td> {{ row['title'] }} </td>
                                    <td v-html="row['description']"></td>
                                    <td> {{ $filters.formatDate(row['createdAt']) }} </td>
                                    <td> 
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="setId(index)" data-bs-toggle="modal" data-bs-target="#editModal" class="edit-button"><i class='bx bxs-edit'></i></a>
                                            <a href="javascript:void(0);" v-on:click="deleteContent(row['id'])" class="ms-3 delete-button"><i class='bx bxs-trash'></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import "../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
    background-color: #17a00e;
}
.delete-button {
    background-color: #f41127;
}
</style>

<script src="../js/reefer-friend.js"></script>