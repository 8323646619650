<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">Career</li>
                    </ol>
                </nav>
            </div>
            <h6 class="mb-0 text-uppercase">Career Applicant List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Job Title</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Cover Letter</th>
                                    <th>Resume</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.jobTitle }}</td>
                                    <td>{{ data.fullName }}</td>
                                    <td>{{ data.email }}</td>
                                    <td> {{ data.contactNumber }} </td>
                                    <td>
                                        <span class="metaModal" data-bs-toggle="modal" data-bs-target="#showModal" @click="updateModalValue(data.coverLetter)">
                                            {{ data.coverLetter ? data.coverLetter.length > 50 ? data.coverLetter.slice(0, 20) + ' ...' : data.coverLetter : 'No cover letter found.' }}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a :href="data.resume" class="edit-button btn-sm ms-3">
                                                <i class="bx bx-cloud-download font-22 me-0"></i>
                                            </a>
                                        </div>
                                    </td>
                                    <td> {{ this.$filters.formatDate(data.createdAt) }} </td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0)" @click="deleteContent(data.id)" class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Job Title</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Cover Letter</th>
                                    <th>Resume</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="showModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="showModalLabel">Cover Letter</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="form-control mb-1" type="text" v-html="coverLetter"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/career-applicant.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: block;
}
.metaModal:hover {
    cursor: pointer;
    color: #09f;
}
</style>
