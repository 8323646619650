import { authHeader } from '../../../auth';

export default {
    name: "Contact",
    // data() {
    //     return {
    //         dataList: [],
    //         setURL: this.$serverURL + this.$api.newsletter.requestURL,
    //         filter: {
    //             startDate: "",
    //             endDate: ""
    //         }
    //     }
    // },
    // async created() {
    //     document.title = "Dispo - Contact";
    //     this.dataList = await this.getDataList(this.setURL);
    //     var scripts = [
    //         "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
    //         "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
    //         "/core/assets/js/table-datatable.js"
    //     ];
    //     scripts.forEach(script => {
    //         let tag = document.createElement("script");
    //         tag.setAttribute("src", script);
    //         document.head.appendChild(tag);
    //     });
    //     let style = document.createElement('link');
    //     style.type = "text/css";
    //     style.rel = "stylesheet";
    //     style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
    //     document.head.appendChild(style);
    // },
    // methods: {
    //     deleteContent: function (id) {
    //         this.$swal.fire({
    //             title: 'Are you sure?',
    //             text: "You won't be able to revert this!",
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonColor: '#3085d6',
    //             cancelButtonColor: '#d33',
    //             confirmButtonText: 'Delete'
    //             }).then(async(result) => {
    //                 if (result.isConfirmed) {
    //                     try {
    //                         let config = {
    //                             method: 'DELETE',
    //                             url: this.setURL + '/' + id,
    //                             headers: authHeader()
    //                         }
    //                         await this.deleteData(config);
    //                         this.dataList = await this.getDataList(this.setURL);
    //                     } catch (error) {
    //                         this.$swal.fire({
    //                             icon: "error",
    //                             text: error.response.data.message
    //                         });
    //                     }
    //                 }
    //         })

    //     }
    // }
    data() {
        return {
            dataList: [],
            cmsData: {},
            setURL: this.$serverURL + this.$api.store.requestURL,
            link: "",
            storeDetails: {
                id: "",
                name: "",
            },
        }
    },
    async created() {
        document.title = "Dispo - Newsletter";
        await this.getStoreDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getStoreDataList: async function (url) {
            try {
                let config = {
                    method: "POST",
                    url: url
                }
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.dataList = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        handleEditBtnClick: async function (data) {
            this.storeDetails = {
                id: data.id,
                name: data.name,
            };
            await this.getDataList(this.$serverURL + this.$api.newsletter.requestURL + '?retailerId=' + this.storeDetails.id).then((response) => {
                this.link = (response.length > 0) ? response[0].link : "";
            }).catch(error => {
                console.log(error);
            });
        },

        async saveLink() {
            const payLoad = {
                retailerId: this.storeDetails.id,
                retailerName: this.storeDetails.name,
                link: this.link
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.newsletter.requestURL,
                data: payLoad,
                headers: authHeader()
            };
            this.$swal.showLoading();
            await this.$axios(config).then(async (response) => {
                this.$swal.close();
                if (response.data.statusCode == 200 || response.data.statusCode == 201) {
                    this.storeDetails = {
                        id: "",
                        name: "",
                    }
                    this.link = "";
                    document.getElementById("close-btn").click();
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}