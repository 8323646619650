export default {
    data() {
        return {
            siteUrl: this.$serverURL + this.$api.cms.pageSchema,
            homePage: "",
            shopPage: "",
            categoryPage: "",
        }
    },
    async mounted() {
        document.title = "Dispo - Page Schema";
    },
    async created() {
        const schemaData = await this.getCmsData(this.siteUrl);
        if(schemaData?.data?.length) {
            this.homePage = schemaData?.data?.[0]?.homePage;
            this.shopPage = schemaData?.data?.[0]?.shopPage;
            this.categoryPage = schemaData?.data?.[0]?.categoryPage;
        }
    },
    methods: {
        async updatePageSchema() {
            try {
                if(!this.homePage) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter home page schema!"
                    });
                } else if(!this.shopPage) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter shop page schema!"
                    });
                } else if(!this.categoryPage) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter category page schema!"
                    });
                } else {
                    const data = {
                        homePage: this.homePage,
                        shopPage: this.shopPage,
                        categoryPage: this.categoryPage
                    };
                    const res = await this.createUpdateCMS(this.siteUrl, data);
                    if (res.statusCode == 200 || res.statusCode == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: res.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }

                    const schemaData = await this.getCmsData(this.siteUrl);
                    if(schemaData?.data?.length) {
                        this.homePage = schemaData?.data?.[0]?.homePage;
                        this.shopPage = schemaData?.data?.[0]?.shopPage;
                        this.categoryPage = schemaData?.data?.[0]?.categoryPage;
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        },
    }
}