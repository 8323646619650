<template>
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header d-flex justify-content-center">
      <div>
        <img src="/core/assets/images/logo.png" class="logo-icon" alt="logo icon" />
      </div>
      <!-- <div>
                <h4 class="logo-text">Dispo</h4>
      </div>-->
      <!-- <div class="toggle-icon ms-auto">
                <i class="bx bx-arrow-to-left"></i>
      </div>-->
    </div>
    <ul class="metismenu" id="menu">
      <li>
        <a href="javascript:void(0)" @click="$router.push('/home')">
          <div class="parent-icon">
            <i class="bx bxs-dashboard"></i>
          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      <li v-if="isAdmin">
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-user-rectangle"></i>
          </div>
          <div class="menu-title">Users</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/admin')">
              <i class="bx bx-right-arrow-alt"></i>Admins
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/hr')">
              <i class="bx bx-right-arrow-alt"></i>HR
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/customer')">
              <i class="bx bx-right-arrow-alt"></i>Customers
            </a>
          </li>
        </ul>
      </li>
      <li v-if="isAdmin">
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-up-arrow"></i>
          </div>
          <div class="menu-title">Data</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/retailer-aiq')">
              <i class="bx bx-right-arrow-alt"></i>Retailer AIQ Service
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/contact')">
              <i class="bx bx-right-arrow-alt"></i>Contact
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/newsletter')">
              <i class="bx bx-right-arrow-alt"></i>Newsletter
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-review')">
              <i class="bx bx-right-arrow-alt"></i>Product Review
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fadeIn animated bx bxs-briefcase-alt-2"></i>
          </div>
          <div class="menu-title">HR</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career')">
              <i class="bx bx-right-arrow-alt"></i>Career List
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career-applicant')">
              <i class="bx bx-right-arrow-alt"></i>Career Applicant
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career-department')">
              <i class="bx bx-right-arrow-alt"></i>Career Department
            </a>
          </li>
        </ul>
      </li>
      <li v-if="isAdmin">
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fa-solid fa-list-check"></i>
          </div>
          <div class="menu-title">SEO Settings</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-page-title-cms')">
              <i class="bx bx-right-arrow-alt"></i>Site Page Title
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-page-meta-cms')">
              <i class="bx bx-right-arrow-alt"></i>Site Page Meta
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/canonical-tags')">
              <i class="bx bx-right-arrow-alt"></i>Canonical Tags
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-category-meta-data')">
              <i class="bx bx-right-arrow-alt"></i>Product Category Meta
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/retailer-meta-data')">
              <i class="bx bx-right-arrow-alt"></i>Retailer Meta Data
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-map')">
              <i class="bx bx-right-arrow-alt"></i>Site Map
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-meta-mapping')">
              <i class="bx bx-right-arrow-alt"></i>Product Details Page Meta
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/robot-txt-cms')">
              <i class="bx bx-right-arrow-alt"></i>Robot Text
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/page-schema')">
              <i class="bx bx-right-arrow-alt"></i>Page Schema
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/seo-content-text-cms')">
              <i class="bx bx-right-arrow-alt"></i>SEO Content Text CMS
            </a>
          </li>
        </ul>
      </li>
      <!-- <li v-if="isAdmin">
        <a href="javascript:void(0)" @click="$router.push('/newsletter-subscriber')">
          <div class="parent-icon">
            <i class="bx bxs-news"></i>
          </div>
          <div class="menu-title">Newsletter Subscriber</div>
        </a>
      </li> -->
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-cog"></i>
          </div>
          <div class="menu-title">CMS Setting</div>
        </a>
        <ul>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-log-in-circle"></i>
              </div>
              <div class="menu-title">Login/Signup</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/signin-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Sign In Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/signup-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Sign Up Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-home-alt"></i>
              </div>
              <div class="menu-title">Home</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Home Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-section-one-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Home Section One CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-section-two-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Home Section Two CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-special-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Home Special Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-best-dispensary-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Best Dispensary CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-effect-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Home Effect Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-about-dispensary-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Home About Dispensary CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-why-choose-our-dispensary-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Home Why Choose Our Dispensary CMS
                </a>
              </li>

              <li>
                <a href="javascript:void(0)" @click="$router.push('/why-choose-our-dispensary-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Why Choose Our Dispensary FAQ
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-looking-for-dispensary-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Looking For Dispensary CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/level-up-program-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Level Up Program Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/customer-feedback-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Customer Feedback CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/customer-feedbacks')">
                  <i class="bx bx-right-arrow-alt"></i>Customer Feedback List
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-blog-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Blog Section CMS
                </a>
              </li>


              <li>
                <a href="javascript:void(0)" @click="$router.push('/hero-section-slider')">
                  <i class="bx bx-right-arrow-alt"></i>Hero Section Slider
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-hero-section-left-cms-list')">
                  <i class="bx bx-right-arrow-alt"></i>Hero Section Left CMS List
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-us-dispo-difference')">
                  <i class="bx bx-right-arrow-alt"></i>About Us Dispo Difference CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/newsletter-banner-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Newsletter Banner CMS
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-copy-alt"></i>
              </div>
              <div class="menu-title">Header Footer</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/header-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/header-banner-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Default Header Banner CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/header-banner')">
                  <i class="bx bx-right-arrow-alt"></i>Header Banner
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/header-special-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header Special Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/footer-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Footer CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/footer-images')">
                  <i class="bx bx-right-arrow-alt"></i>Footer Images
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/social-links')">
                  <i class="bx bx-right-arrow-alt"></i>Social Links
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-info-circle"></i>
              </div>
              <div class="menu-title">About Us</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-us-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-us-section-one')">
                  <i class="bx bx-right-arrow-alt"></i>Section One CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-us-section-two')">
                  <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-briefcase-alt-2"></i>
              </div>
              <div class="menu-title">Career</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-list-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Career List Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-details-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Career Details Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-apply-modal-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Apply Career Modal CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/why-join-us')">
                  <i class="bx bx-right-arrow-alt"></i>Why Join Us
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-message-alt-dots"></i>
              </div>
              <div class="menu-title">Contact Us</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/contact-us-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/contact-us-section-one')">
                  <i class="bx bx-right-arrow-alt"></i>Section One
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-question-mark"></i>
              </div>
              <div class="menu-title">FAQ</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/faq')">
                  <i class="bx bx-right-arrow-alt"></i>FAQ
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/faq-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/faq-page-section-two')">
                  <i class="bx bx-right-arrow-alt"></i>Page Section Two
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-book-content"></i>
              </div>
              <div class="menu-title">Policy</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/privacy-policy')">
                  <i class="bx bx-right-arrow-alt"></i> Privacy Policy
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/terms-and-condition')">
                  <i class="bx bx-right-arrow-alt"></i> Terms and Condition
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-user-check"></i>
              </div>
              <div class="menu-title">Reefer A Friend</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/reefer-friend-cms')">
                  <i class="bx bx-right-arrow-alt"></i> Reefer a Friend CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/reefer-friend')">
                  <i class="bx bx-right-arrow-alt"></i> Reefer a Friend
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-store"></i>
              </div>
              <div class="menu-title">Store</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/retailer')">
                  <i class="bx bx-right-arrow-alt"></i>Retailers
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/default-retailer')">
                  <i class="bx bx-right-arrow-alt"></i>Default Retailer CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/store-details-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Store Details CMS
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-gift"></i>
              </div>
              <div class="menu-title">Exclusive Brand</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/exclusive-brand-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/exclusive-brand-section-one-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section One CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/exclusive-brand-section-two-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/exclusive-brand-section-three-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Three CMS
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-gift"></i>
              </div>
              <div class="menu-title">Featured Brand</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/brand-details-page/brand')">
                  <i class="bx bx-right-arrow-alt"></i>Brand
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/brand-details-page/product/brand')">
                  <i class="bx bx-right-arrow-alt"></i>Product
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="fa-solid fa-list-check"></i>
              </div>
              <div class="menu-title">Popup Modules</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/newsletter-pop-up')">
                  <i class="bx bx-right-arrow-alt"></i>Newsletter Popup Module
                </a>
              </li>
              <li v-if="isAdmin">
                <a href="javascript:void(0)" @click="$router.push('/offer-pop-up')">
                  <i class="bx bx-right-arrow-alt"></i>Offer Popup Module
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bxs-pen"></i>
              </div>
              <div class="menu-title">Blog</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/blog-category')">
                  <i class="bx bx-right-arrow-alt"></i>Blog Category
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/blog-tag')">
                  <i class="bx bx-right-arrow-alt"></i>Blog Tag
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/blog-post')">
                  <i class="bx bx-right-arrow-alt"></i>Blog Post
                </a>
              </li>
            </ul>
          </li>
          <li v-if="isAdmin">
            <a href="javascript:void(0)" @click="$router.push('/landing-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Landing Page CMS
            </a>
          </li>
          <li v-if="isAdmin">
            <a href="javascript:void(0)" @click="$router.push('/deals-special-cms')">
              <i class="bx bx-right-arrow-alt"></i>Deals Special CMS
            </a>
          </li>
          <li v-if="isAdmin">
            <a href="javascript:void(0)" @click="$router.push('/retailer-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Retailer Page CMS
            </a>
          </li>
          <li v-if="isAdmin">
            <a href="javascript:void(0)" @click="$router.push('/vendor-banner-cms')">
              <i class="bx bx-right-arrow-alt"></i>Schedule Vendor Day Banner CMS
            </a>
          </li>
          <li v-if="isAdmin">
            <a href="javascript:void(0)" @click="$router.push('/location-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Location Page CMS
            </a>
          </li>
          <li v-if="isAdmin">
            <a href="javascript:void(0)" @click="$router.push('/common-cms')">
              <i class="bx bx-right-arrow-alt"></i>Common CMS
            </a>
          </li>
          <li v-if="isAdmin">
            <a href="javascript:void(0)" @click="$router.push('/mobile-banner-cms')">
              <i class="bx bx-right-arrow-alt"></i>Mobile Banner CMS
            </a>
          </li>
          <li v-if="isAdmin">
            <a href="javascript:void(0)" @click="$router.push('/advertisement-banner')">
              <i class="bx bx-right-arrow-alt"></i>Advertisement Banner CMS
            </a>
          </li>
          <li v-if="isAdmin">
            <a href="javascript:void(0)" @click="$router.push('/brands')">
              <i class="bx bx-right-arrow-alt"></i>Brands
            </a>
          </li>
          <li v-if="isAdmin">
            <a href="javascript:void(0)" @click="$router.push('/category')">
              <i class="bx bx-right-arrow-alt"></i>Category Icons
            </a>
          </li>
          <li v-if="isAdmin">
            <a href="javascript:void(0)" @click="$router.push('/effects')">
              <i class="bx bx-right-arrow-alt"></i>Effect Icons
            </a>
          </li>
        </ul>
      </li>
      <li v-if="isAdmin">
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-analyse"></i>
          </div>
          <div class="menu-title">System Settings</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/synchronize')">
              <i class="bx bx-right-arrow-alt"></i>Synchronize
            </a>
          </li>
        </ul>
      </li>
      <li v-if="isAdmin">
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-component"></i>
          </div>
          <div class="menu-title">Component UI</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/about-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>About Us Page
            </a>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-book-content"></i>
              </div>
              <div class="menu-title">Blog</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/blog-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Blog Page
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/blog-details-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Blog Details Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/brand-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Brand Page
            </a>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-briefcase-alt-2"></i>
              </div>
              <div class="menu-title">Career</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Career Page
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-department-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Career Department Page
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-details-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Career Details Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/categories-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Categories Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/choose-place-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Choose Place Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/contact-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Contact Us Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/common-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Common Component UI
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/faq-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>FAQ Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/header-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Header
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/home-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Home Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/landing-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Landing Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/location-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Location Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/newsletter-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Newsletter Section
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/privacy-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Privacy Policy Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/retailer-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Retailer Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/shop-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Shop Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/special-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Specials Page
            </a>
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/store-details-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Store Details
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/terms-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Terms Condition Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/top-ticker-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Top Ticker
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
export default {
  name: "Header",
  data() {
    return {
      role: 0,
      isAdmin: false
    };
  },
  created() {
    const token = localStorage?.getItem("token");
    const decoded = jwt_decode(token);
    this.role = decoded?.role;
    this.isAdmin = this.role == 2;
    this.isHR = this.role == 4;
  }
};
</script>