<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Home Page CMS</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">Home Page CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Home Page CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-lg-8 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="col-12 mb-3">
												<label class="form-label">Category Section Title:</label>
												<input type="text" v-model="cmsData.categorySectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter category section title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Category Section Subtitle:</label>
												<textarea type="text" v-model="cmsData.categorySectionSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter category section subtitle" rows="3"></textarea>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Popular Brand Section Title:</label>
												<input type="text" v-model="cmsData.popularBrandSectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter popular brand section title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Popular Brand Section Subtitle:</label>
												<textarea type="text" v-model="cmsData.popularBrandSectionSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter popular brand section subtitle" rows="3"></textarea>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Dispo Difference Section Title:</label>
												<input type="text" v-model="cmsData.dispoDifferenceSectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter dispo difference section title"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Dispo Difference Section Subtitle:</label>
												<textarea type="text" v-model="cmsData.dispoDifferenceSectionSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter dispo difference section subtitle" rows="3"></textarea>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Instagram Section Title:</label>
												<input type="text" v-model="cmsData.instagramSectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter instagram section title"/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Instagram Section Subtitle:</label>
												<textarea type="text" v-model="cmsData.instagramSectionSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter instagram section subtitle" rows="3"></textarea>
											</div>
										</div>
									</div>
								</div>
								<div class="d-grid col-lg-8">
									<button type="submit" class="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/home-page-cms.js"></script>