<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Header Banner
            </li>
          </ol>
        </nav>

        <div class="ms-auto">
          <!-- <div class="btn-group">
            <button type="button" class="btn btn-primary btn-sm m-1">
              <i class="bx bxs-plus-square"></i>Add New Banner
            </button>
          </div> -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Header Banner List</h6>
      <hr />
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
          <i class="bx bxs-plus-square"></i>Add
        </button>
      </div>
      <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
        <form @submit.prevent="addBanner">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="row m-1">
                <div class="modal-body col-md-9">
                  <label class="form-label">Description:<span style="color: red; font-size: 18px">*</span></label>
                  <input class="form-control mb-1" type="text" v-model="banner" />

                  <label class="form-label">Link:<span style="color: red; font-size: 18px">*</span></label>
                  <input class="form-control mb-1" type="text" v-model="link" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" id="add-btn-close" data-bs-dismiss="modal">
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Add
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
        <form @submit.prevent="editBanner">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="row m-1">
                <div class="modal-body col-md-9">
                  <label class="form-label">Description:<span style="color: red; font-size: 18px">*</span></label>
                  <input class="form-control mb-1" type="text" v-model="singleData.banner" />

                  <label class="form-label">Link:<span style="color: red; font-size: 18px">*</span></label>
                  <input class="form-control mb-1" type="text" v-model="singleData.link" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" id="edit-btn-close" data-bs-dismiss="modal">
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Banner Description</th>
                  <th>Banner Link</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.banner }}</td>
                  <td>{{ data.link }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a href="javascript:void(0);" @click="updateModalValue(data)" class="edit-button"
                        data-bs-toggle="modal" data-bs-target="#editModal"><i class="bx bxs-edit text-white"></i></a>
                      <a href="javascript:void(0);" @click="deleteContent(data.id)"
                        class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Banner Description</th>
                  <th>Banner Link</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/header-banner-list.js'></script>


<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}

.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}

.metaModal:hover {
  cursor: pointer;
  color: #09f;
}
</style>