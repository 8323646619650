<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Update Retailer Meta
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title"> Update Retailer Meta</h5>
            <hr />
            <div class="form-body mt-4">

              <div class="row">
                <div class="col-lg-8">

                  <div class="col-md-12 mb-3">
                    <label class="form-label">Select a Retailer:</label>
                    <v-select placeholder="Select a Retailer" v-model="retailer" :options="retailerList"
                      :close-on-select="true" :searchable="true" />
                  </div>

                  <hr>

                  <div class="col-md-12 mb-3">
                    <label for="contactPageTitle" class="form-label">Contact Page Title:</label>
                    <input type="text" v-model="contactPageTitle" class="form-control">
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="contactMetaDescription" class="form-label">Contact Meta Description:</label>
                    <input type="text" v-model="contactMetaDescription" class="form-control">
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="contactMetaKeyword" class="form-label">Contact Meta Keyword:</label>
                    <input type="text" v-model="contactMetaKeyword" class="form-control">
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="contactCanonicalTag" class="form-label">Contact Canonical Tag:</label>
                    <input type="text" v-model="contactCanonicalTag" class="form-control">
                  </div>

                  <hr>

                  <div class="col-md-12 mb-3">
                    <label for="storePageTitle" class="form-label">Store Page Title:</label>
                    <input type="text" v-model="storePageTitle" class="form-control">
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="storeMetaDescription" class="form-label">Store Meta Description:</label>
                    <input type="text" v-model="storeMetaDescription" class="form-control">
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="storeMetaKeyword" class="form-label">Store Meta Keyword:</label>
                    <input type="text" v-model="storeMetaKeyword" class="form-control">
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="storeCanonicalTag" class="form-label">Store Canonical Tag:</label>
                    <input type="text" v-model="storeCanonicalTag" class="form-control">
                  </div>


                  <div class="col-12 mt-3">
                    <div class="d-grid">
                      <button type="button" @click="updateRetailerMeta" class="btn btn-primary">
                        Save Retailer Meta
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/update-retailer-meta-data.js'></script>