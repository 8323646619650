export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.pageTitleCMS,
            cmsData: {},
        }
    },
    async created() {
        document.title = "Dispo - Site Page Title CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async updateCms() {
            try {
                let data = {
                    'signinPageTitle': this.cmsData.signinPageTitle,
                    'signupPageTitle': this.cmsData.signupPageTitle,
                    'homePageTitle': this.cmsData.homePageTitle,
                    'shopPagetitle': this.cmsData.shopPagetitle,
                    'productDetailsPageTitle': this.cmsData.productDetailsPageTitle,
                    'wishlistPageTitle': this.cmsData.wishlistPageTitle,
                    'cartPageTitle': this.cmsData.cartPageTitle,
                    'aboutUsPageTitle': this.cmsData.aboutUsPageTitle,
                    'categoriesPageTitle': this.cmsData.categoriesPageTitle,
                    'brandPageTitle': this.cmsData.brandPageTitle,
                    'specialsPageTitle': this.cmsData.specialsPageTitle,
                    'locationPageTitle': this.cmsData.locationPageTitle,
                    'contactUsPageTitle': this.cmsData.contactUsPageTitle,
                    'faqPageTitle': this.cmsData.faqPageTitle,
                    'careerPageTitle': this.cmsData.careerPageTitle,
                    'careerDepartmentPageTitle': this.cmsData.careerDepartmentPageTitle,
                    'careerDetailsPageTitle': this.cmsData.careerDetailsPageTitle,
                    'blogPageTitle': this.cmsData.blogPageTitle,
                    'blogDetailsPageTitle': this.cmsData.blogDetailsPageTitle,
                    'privacyPageTitle': this.cmsData.privacyPageTitle,
                    'termsPageTitle': this.cmsData.termsPageTitle,
                    'profilePageTitle': this.cmsData.profilePageTitle,
                    'successPageTitle': this.cmsData.successPageTitle,
                    'forgotPasswordPageTitle': this.cmsData.forgotPasswordPageTitle,
                    'retailerPageTitle': this.cmsData.retailerPageTitle,
                    'choosePlacePageTitle': this.cmsData.choosePlacePageTitle,
                    'resetPasswordPageTitle': this.cmsData.resetPasswordPageTitle,
                    'invoicePageTitle': this.cmsData.invoicePageTitle,
                    dealsSpecialPageTitle: this.cmsData.dealsSpecialPageTitle ? this.cmsData.dealsSpecialPageTitle : 'Dispo | Deals Special',
                    shopBrandPageTitle: this.cmsData.shopBrandPageTitle ? this.cmsData.shopBrandPageTitle : 'Dispo | Brands',
                    shopCategoryPageTitle: this.cmsData.shopCategoryPageTitle ? this.cmsData.shopCategoryPageTitle : 'Dispo | Category',
                    exclusiveBrandPageTitle: this.cmsData.exclusiveBrandPageTitle ? this.cmsData.exclusiveBrandPageTitle : 'Dispo | Exclusive Brands',
                    featuredBrandPageTitle: this.cmsData.featuredBrandPageTitle ? this.cmsData.featuredBrandPageTitle : 'Dispo | Featured Brands',
                    featuredBrandDetailsPageTitle: this.cmsData.featuredBrandDetailsPageTitle ? this.cmsData.featuredBrandDetailsPageTitle : 'Dispo | Featured Brand Details',
                    sitemapPageTitle: this.cmsData.sitemapPageTitle ? this.cmsData.sitemapPageTitle : 'Dispo | Sitemap',
                    storePageTitle: this.cmsData.storePageTitle ? this.cmsData.storePageTitle : 'Dispo | Store Details',
                    orderPageTitle: this.cmsData.orderPageTitle ? this.cmsData.orderPageTitle : 'Dispo | User Orders',
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}