<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Brand List</li>
          </ol>
        </nav>
      </div>
      <h6 class="mb-2 text-uppercase">Brand List</h6>
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{retailerName}}</h5>
          <div
            style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/brands'})"
          >
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>
      <hr />

      <div class="card">
        <div class="p-2 mt-2" style="margin-right:5px">
          <div style="float:right">
            <input type="text" class="form-control" placeholder="Search brands" v-model="searchKey" />
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table id class="table table-striped table-bordered">
              <thead>
                <tr>
                  <!-- <th>Sl</th> -->
                  <th>Position</th>
                  <th>Brand Name</th>
                  <th>Dutchie Image</th>
                  <th>Custom Image</th>
                  <th>Show Image</th>
                  <th>Home Page</th>
                  <th>Brand Page</th>
                  <th>Category Page</th>
                  <th>Action</th>
                </tr>
              </thead>
              <draggable
                v-model="tempDataList"
                tag="tbody"
                item-key="data.id"
                @update="onDragUpdate"
              >
                <template #item="{ element: data }">
                  <tr :class="searchKey ? 'disable-row' : ''">
                    <!-- <td>{{ data.serial }}</td> -->
                    <td>{{ data.position }}</td>
                    <td>{{ data.brandName }}</td>
                    <td>
                      <img v-if="data.brandImage" :src="data.brandImage" height="100" width="120" />
                      <img v-else src="../../../assets/images/default.png" height="100" width="120" />
                    </td>
                    <td>
                      <img
                        v-if="data.customBrandImage"
                        :src="data.customBrandImage"
                        height="100"
                        width="120"
                      />
                      <img v-else src="../../../assets/images/default.png" height="100" width="120" />
                    </td>
                    <td>
                      <div class="form-check form-switch">
                        <label for>{{data.isShowCustomBrandImage ? 'Custom' : 'Dutchie'}}</label>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          v-model="data.isShowCustomBrandImage"
                          @click="handleImageStatus(data, $event)"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          v-model="data.isShowBrandInHome"
                          @click="handleStatusChangeHomePage(data, $event)"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          v-model="data.isShowBrand"
                          @click="handleStatusChangeBrandPage(data, $event)"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          v-model="data.isShowBrandInCategory"
                          @click="handleStatusChangeCategoryPage(data, $event)"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex order-actions">
                        <a
                          href="javascript:void(0)"
                          class="edit-button"
                          data-bs-toggle="modal"
                          data-bs-target="#updateModal"
                          @click="editBrandData(data)"
                        >
                          <i class="bx bxs-edit text-white"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </template>
              </draggable>
              <tfoot>
                <tr>
                  <!-- <th>Sl</th> -->
                  <th>Position</th>
                  <th>Brand Name</th>
                  <th>Dutchie Image</th>
                  <th>Custom Image</th>
                  <th>Show Image</th>
                  <th>Home Page</th>
                  <th>Brand Page</th>
                  <th>Category Page</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="updateModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Upload Custom Brand Image</h5>
              <button
                type="button"
                class="btn-close"
                id="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <!-- <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Category Name:</label>
                <input type="text" disabled v-model="singleData.categoryName" class="form-control" />
              </div>-->
              <div class="mb-3">
                <label
                  class="form-label"
                >Image: [Preferred Image Size: 125X125, Max Upload Limit: 1MB]</label>
                <input class="form-control" @change="onImageChange" type="file" ref="editImage" />
              </div>
              <div class="mb-3">
                <div class="col-md-12">
                  <img
                    v-if="previewImage"
                    class="img-thumbnail"
                    :src="previewImage"
                    style="height:125px; width:125px"
                  />
                  <img
                    v-else-if="singleBrand.customBrandImage"
                    class="img-thumbnail"
                    :src="singleBrand.customBrandImage"
                    style="height:125px; width:125px"
                  />
                  <img
                    v-else
                    class="img-thumbnail"
                    src="../../../assets/images/default.png"
                    style="height:125px; width:125px"
                  />
                </div>
              </div>
              <!-- <div class="mb-3">
                <label
                  class="form-label"
                >Image: [Preferred Image Size: 510X500, Max Upload Limit: 1MB]</label>
                <input class="form-control" @change="onImageChange" type="file" ref="image" />
              </div>
              <div class="mb-3">
                <div class="col-md-12">
                  <img
                    v-if="previewImage"
                    class="img-thumbnail"
                    :src="previewImage"
                    style="height: 150px;"
                  />
                  <img
                    v-else-if="singleData.featuredImage"
                    class="img-thumbnail"
                    :src="singleData.featuredImage"
                    style="height: 150px;"
                  />
                  <img v-else class="img-thumbnail" src="../../../assets/images/default.png" />
                </div>
              </div>-->
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="updateBrandImage">Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/brand-list.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.primary-button {
  background-color: #1765db;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.metaModal:hover {
  cursor: pointer;
  color: #09f;
}

.disable-row {
  pointer-events: none;
}
</style>
