<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Contact Us Section One Content</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Contact Us Section One Content</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              type="button"
              class="btn btn-primary"
            >
              <i class="bx bxs-plus-square"></i>Add New Section
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Contact Us Section One Content Table</h6>
      <hr />
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{retailerName}}</h5>
          <div
            style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/contact-us-section-one'})"
          >
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Icon</th>
                  <th>Title</th>
                  <th>Subtitle</th>
                  <th>Type</th>
                  <th>Button Text</th>
                  <th>Button Link</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img :src="row.icon" style="height:100px;" />
                  </td>
                  <td>{{ row.title }}</td>
                  <td>
                    <span
                      class="metaModal"
                    >{{ row.subTitle ? row.subTitle.length > 50 ? row.subTitle.slice(0, 30) + ' ...' : row.subTitle : 'No subtitle found.' }}</span>
                  </td>
                  <td>{{ row.type }}</td>
                  <td>{{ row.buttonText }}</td>
                  <td>{{ row.buttonLink }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#updateModal"
                        class="edit-button"
                        @click="handleEdit(row)"
                      >
                        <i class="bx bxs-edit"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteContent(row['id'])"
                        class="ms-3 delete-button"
                      >
                        <i class="bx bxs-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Icon</th>
                  <th>Title</th>
                  <th>Subtitle</th>
                  <th>Type</th>
                  <th>Button Text</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- add modal -->

    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="addModalLabel"
      aria-hidden="true"
    >
      <form>
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addModalLabel">
                <i class="bx bxs-plus-square"></i> Add New Content
              </h5>
              <button
                type="button"
                id="addModalCloseButton"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="border border-3 p-4 rounded">
              <div class="row mb-3">
                <div class="col-md-6">
                  <label class="form-label">Retailer Name:</label>
                  <input type="text" v-model="retailerName" class="form-control" disabled />
                </div>
                <div class="col-md-6">
                  <label class="form-label">Title:</label>
                  <input type="text" v-model="title" class="form-control" />
                </div>
              </div>

              <div class="mb-3">
                <label class="form-label">Type:</label>
                <select v-model="type" class="form-select form-control mb-3">
                  <option class="p-1" style="display:none" value="" selected>Select Type</option>
                  <option
                    class="p-1"
                    v-for="(el, index) in typeList"
                    :key="index"
                    :value="el"
                  >{{ el }}</option>
                </select>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <label class="form-label">Button Text:</label>
                  <input type="text" v-model="buttonText" class="form-control" rows="5" />
                </div>
                <div class="col-md-6">
                  <label class="form-label">Button Link:</label>
                  <input type="text" v-model="buttonLink" class="form-control" rows="5" />
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Subtitle:</label>
                <textarea type="text" v-model="subTitle" class="form-control" rows="5"></textarea>
              </div>
              <div class="mb-3">
                <label class="form-label">Icon: [Preferred Iocn Size: 71X71, Max Upload Limit: 1MB]</label>
                <input class="form-control" @change="onIconChange" type="file" ref="icon" />
              </div>
              <div class="mb-3">
                <div class="col-md-3">
                  <img v-if="previewIcon" :src="previewIcon" style="height:100px;" />
                  <img v-else src="../../../../assets/images/default.png" height="100" />
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                <i class="fadeIn animated bx bx-x"></i>Close
              </button>
              <button type="button" @click="save" class="btn btn-primary">
                <i class="bx bxs-plus-square"></i>Add
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- update modal -->
    <div
      class="modal fade"
      id="updateModal"
      tabindex="-1"
      aria-labelledby="updateModalLabel"
      aria-hidden="true"
    >
      <form>
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="updateModalLabel">
                <i class="bx bxs-plus-square"></i> Update Content
              </h5>
              <button
                type="button"
                id="updateModalCloseButton"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="border border-3 p-4 rounded">
              <div class="row mb-3">
                <div class="col-md-6">
                  <label class="form-label">Retailer Name:</label>
                  <input type="text" v-model="retailerName" class="form-control" disabled />
                </div>
                <div class="col-md-6">
                  <label class="form-label">Title:</label>
                  <input type="text" v-model="update.title" class="form-control" />
                </div>
              </div>

              <div class="mb-3">
                <label class="form-label">Type:</label>
                <select v-model="update.type" class="form-select form-control mb-3">
                  <option
                    class="p-1"
                    v-for="(el, index) in typeList"
                    :key="index"
                    :value="el"
                  >{{ el }}</option>
                </select>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <label class="form-label">Button Text:</label>
                  <input type="text" v-model="update.buttonText" class="form-control" rows="5" />
                </div>
                <div class="col-md-6">
                  <label class="form-label">Button Link:</label>
                  <input type="text" v-model="update.buttonLink" class="form-control" rows="5" />
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Subtitle:</label>
                <textarea type="text" v-model="update.subTitle" class="form-control" rows="5"></textarea>
              </div>
              <div class="mb-3">
                <label class="form-label">Icon: [Preferred Icon Size: 71X71, Max Upload Limit: 1MB]</label>
                <input
                  class="form-control"
                  @change="onUpdateIconChange"
                  type="file"
                  ref="updateIcon"
                />
              </div>
              <div class="mb-3">
                <div class="col-md-12">
                  <img
                    v-if="update.previewIcon"
                    class="img-thumbnail"
                    :src="update.previewIcon"
                    style="height:100px;"
                  />
                  <img
                    v-else-if="update.icon"
                    class="img-thumbnail"
                    :src="update.icon"
                    style="height:100px;"
                  />
                  <img
                    v-else
                    class="img-thumbnail"
                    src="../../../../assets/images/default.png"
                    style="height:100px;"
                  />
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                <i class="fadeIn animated bx bx-x"></i>Close
              </button>
              <button type="button" @click="updateContent" class="btn btn-primary">
                <i class="bx bxs-plus-square"></i>Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
  

<script src="../js/section-content-list.js"></script>

<style scoped>
@import "../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.metaModal:hover {
  cursor: pointer;
  color: #09f;
}
</style>