<template>
  <div class="page-wrapper">
      <div class="page-content">
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0 p-0">
                      <li class="breadcrumb-item">
                          <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                          FAQ List
                      </li>
                  </ol>
              </nav>
              <div class="ms-auto">
                  <div class="btn-group">
                      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                          <i class="bx bxs-plus-square"></i>Add New FAQ
                      </button>
                  </div>
                  <!-- Add Modal -->
                  <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                      <form @submit.prevent="addFaq">
                          <div class="modal-dialog modal-lg">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title" id="addModalLabel">Add FAQ</h5>
                                      <button type="button" id="closeButton" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body">
                                      <label class="form-label">Question:</label>
                                      <ckeditor :editor="editor" v-model="question" :config="editorConfig"></ckeditor>
                                  </div>
                                  <div class="modal-body">
                                      <label class="form-label">Answer:</label>
                                      <ckeditor :editor="editor" v-model="answer" :config="editorConfig"></ckeditor>
                                  </div>
                                  <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                          Close
                                      </button>
                                      <button type="submit" class="btn btn-primary">
                                          Add
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
                  <!-- Add Modal -->
                  <!-- Edit Modal -->
                  <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                      <form @submit.prevent="updateFaq">
                          <div class="modal-dialog modal-lg">
                              <div class="modal-content">
                                  <div class="modal-header">
                                      <h5 class="modal-title" id="addModalLabel">Update FAQ</h5>
                                      <button type="button" id="buttonClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body">
                                      <label class="form-label">Question:</label>
                                      <ckeditor :editor="editor" v-model="single.question" :config="editorConfig"></ckeditor>
                                  </div>
                                  <div class="modal-body">
                                      <label class="form-label">Answer:</label>
                                      <ckeditor :editor="editor" v-model="single.answer" :config="editorConfig"></ckeditor>
                                  </div>
                                  <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                          Close
                                      </button>
                                      <button type="submit" class="btn btn-primary">
                                          Update
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
                  <!-- Edit Modal -->
              </div>
          </div>
          <h6 class="mb-0 text-uppercase">FAQ List</h6>
          <hr />
          <div class="card">
              <div class="card-body">
                  <div class="table-responsive">
                      <table id="example" class="table table-striped table-bordered">
                          <thead>
                              <tr>
                                  <th>SL</th>
                                  <th>Question</th>
                                  <th>Answer</th>
                                  <th>Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(data, index) in dataList" :key="index">
                                  <td>{{ index + 1 }}</td>
                                  <td>
                                      <span class="metaModal" data-bs-toggle="modal" data-bs-target="#showModal" @click="updateQuestionModalValue(data.question)" v-html="data.question ? data.question.length > 50 ? data.question.slice(0, 30) + ' ...' : data.question : 'No question found.'">
                                          
                                      </span>
                                  </td>
                                  <td>
                                      <span class="metaModal" data-bs-toggle="modal" data-bs-target="#showModal2" @click="updateAnswerModalValue(data.answer)" v-html="data.answer ? data.answer.length > 50 ? data.answer.slice(0, 30) + ' ...' : data.answer : 'No answer found.'">
                                      </span>
                                  </td>
                                  <td>
                                      <div class="d-flex order-actions">
                                          <a href="javascript:void(0);" @click="editFaq(data)" data-bs-toggle="modal" data-bs-target="#editModal" class="edit-button">
                                              <i class="bx bxs-edit text-white"></i>
                                          </a>
                                          <a href="javascript:void(0);" @click="deleteFaq(data.id)" class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                          <tfoot>
                              <tr>
                                  <th>SL</th>
                                  <th>Question</th>
                                  <th>Answer</th>
                                  <th>Action</th>
                              </tr>
                          </tfoot>
                      </table>
                  </div>
              </div>
          </div>
      </div>
      <!-- Question Modal -->
      <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="showModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="showModalLabel">Question</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                      <p class="form-control mb-1" type="text" v-html="question"></p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                          Close
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <!-- Answer Modal -->
      <div class="modal fade" id="showModal2" tabindex="-1" aria-labelledby="showModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="showModalLabel">Answer</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                      <p class="form-control mb-1" type="text" v-html="answer"></p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                          Close
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script src="../js/why-choose-despensary.js"></script>

<style scoped>
@import "../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
background-color: #17a00e;
}
.delete-button {
background-color: #f41127;
}
.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.metaModal:hover {
  cursor: pointer;
  color: #09f;
}
</style>


