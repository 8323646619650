import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      url: this.$serverURL + this.$api.cms.homev2.bannerSlider,
      cmsData: {},
      preview: {
        cardOneImagePrev: "",
        cardTwoImagePrev: "",
        cardThreeImagePrev: "",
      },
      editor: ClassicEditor,
      editorConfig: {
        ckfinder: {
          uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
        },
      },
    };
  },
  async created() {
    document.title = "Dispo - New Home Slider";
    this.getData();
  },
  methods: {
    async getData() {
      let response = await this.getCmsData(this.url);
      if (response.statusCode == 200) {
        this.cmsData = response.data.length > 0 ? response.data[0] : {};
      } else {
        this.cmsData = {};
      }
    },
    handleUploadImage(imageKey, previewImageKey, ref, event) {
      this.cmsData[imageKey] = event.target.files[0];
      let input = this.$refs[ref];
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.preview[previewImageKey] = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    async updateCms() {
      try {
        let formData = new FormData();

        // Card One
        formData.append("cardOneTitle", this.cmsData.cardOneTitle);
        formData.append("cardOneSubtitle", this.cmsData.cardOneSubtitle);
        formData.append("cardOneImage", this.cmsData.cardOneImage);
        formData.append("cardOneButtonText", this.cmsData.cardOneButtonText);
        formData.append("cardOneButtonLink", this.cmsData.cardOneButtonLink);

        // Card Two
        formData.append("cardTwoTitle", this.cmsData.cardTwoTitle);
        formData.append("cardTwoSubtitle", this.cmsData.cardTwoSubtitle);
        formData.append("cardTwoImage", this.cmsData.cardTwoImage);
        formData.append("cardTwoButtonText", this.cmsData.cardTwoButtonText);
        formData.append("cardTwoButtonLink", this.cmsData.cardTwoButtonLink);

        // Card Three
        formData.append("cardThreeTitle", this.cmsData.cardThreeTitle);
        formData.append("cardThreeSubtitle", this.cmsData.cardThreeSubtitle);
        formData.append("cardThreeImage", this.cmsData.cardThreeImage);
        formData.append(
          "cardThreeButtonText",
          this.cmsData.cardThreeButtonText
        );
        formData.append(
          "cardThreeButtonLink",
          this.cmsData.cardThreeButtonLink
        );

        // General
        formData.append("title", this.cmsData.title);
        formData.append("description", this.cmsData.description);
        this.$swal.showLoading();
        let response = await this.createUpdateCMS(this.url, formData);
        if (response.statusCode == 200 || response.statusCode == 201) {
          this.$refs.cardOneImage.value = null;
          this.$refs.cardTwoImage.value = null;
          this.$refs.cardThreeImage.value = null;
          this.preview = {
            cardOneImagePrev: "",
            cardTwoImagePrev: "",
            cardThreeImagePrev: "",
          };
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        await this.getData();
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong. Please try again!",
        });
      } finally {
        this.$swal.hideLoading();
      }
    },
  },
};
