<template>
    <div class="page-wrapper">
      <div class="page-content">
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div class="breadcrumb-title pe-3">Footer CMS</div>
          <div class="ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item">
                  <router-link to="/home">
                    <i class="bx bx-home-alt"></i>
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Footer CMS</li>
              </ol>
            </nav>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Footer CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-8">
                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label class="form-label">Description:</label>
                      <textarea class="form-control" v-model="cmsData.description" aria-label="default input example"></textarea>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Copyright Text:</label>
                      <input type="text" v-model="cmsData.copyrightText" class="form-control" />
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-6 mb-3">
                        <label class="form-label">Contact Icon: <strong style="font-size: 12px;">[ Icon can only be changed from <a href="https://icons.getbootstrap.com/">Bootstrap Icon</a> ]</strong></label>
                        <input type="text" v-model="cmsData.contactIcon" class="form-control" />
                      </div>
                      <div class="col-12 col-md-6 mb-3">
                        <label class="form-label">Contact No:</label>
                        <input type="text" v-model="cmsData.contactNo" class="form-control" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-6 mb-3">
                        <label class="form-label">Email Icon: <strong style="font-size: 12px;">[ Icon can only be changed from <a href="https://icons.getbootstrap.com/">Bootstrap Icon</a> ]</strong></label>
                        <input type="text" v-model="cmsData.emailIcon" class="form-control" />
                      </div>
                      <div class="col-12 col-md-6 mb-3">
                        <label class="form-label">Contact Email:</label>
                        <input type="text" v-model="cmsData.contactEmail" class="form-control" />
                      </div>
                    </div>
                    <!-- <div class="mb-3">
                      <label class="form-label">Button Text:</label>
                      <input type="text" v-model="cmsData.buttonText" class="form-control" />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Button Link:</label>
                      <input type="text" v-model="cmsData.buttonLink" class="form-control" />
                    </div> -->
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">
                        Logo:
                        <strong>
                          [Preferred Image Size: 1799X768, Max Upload Limit:
                          1MB]
                        </strong>
                      </label><br/>
                      <img
                        style="position: relative; left: 11px"
                        v-if="previewLogo"
                        :src="previewLogo"
                        height="70"
                      />
                      <img
                        style="position: relative; left: 11px"
                        v-else-if="cmsData.logo"
                        :src="cmsData.logo"
                        height="70"
                      />
                      <img
                        style="position: relative; left: 11px"
                        v-else
                        src="../../../../assets/images/default.png"
                        height="70"
                      />
                      <input
                        class="form-control mt-3"
                        type="file"
                        ref="logo"
                        accept="image/*"
                        @change="uploadLogo"
                      />
                    </div>
                    <div class="d-grid">
                      <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script src="../js/footer-cms.js"></script>