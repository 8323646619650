<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Custom Products
            </li>
          </ol>
        </nav>

        <div class="ms-auto">
          <div class="btn-group">
            <button type="button" class="btn btn-primary btn-sm m-1"
              @click="$router.push('/brand-details-page/product/brand/' + customBrandId + '/product/add-product')">
              <i class="bx bxs-plus-square"></i>Add New Custom Product
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Custom Product List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <!-- <th>Name</th> -->
                  <th>Category Name</th>
                  <!-- <th>Description</th> -->
                  <!-- <th>Selling Point</th> -->
                  <th>Image</th>
                  <th>Link</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <!-- <td>{{ data.name }}</td> -->
                  <td>{{ toTitleCase(data.categoryName) }}</td>
                  <!-- <td>{{ data.description.length > 50 ? data.description.slice(0, 50) + '...' : data.description }}</td> -->
                  <!-- <td>{{ data.sellingPoint }}</td> -->
                  <td>
                    <img v-if="data.image" :src="data.image" alt="" width="90" />
                    <img v-else src="/core/assets/images/400.png" alt="" width="90" />
                  </td>
                  <td>{{ data.link }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a href="javascript:void(0);"
                        @click="$router.push('/brand-details-page/product/brand/' + customBrandId + '/product/update-product/' + data.id)"
                        class="edit-button"><i class="bx bxs-edit text-white"></i></a>
                      <a href="javascript:void(0);" @click="deleteProduct(data.id)" class="ms-3 delete-button"><i
                          class="bx bxs-trash text-white"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <!-- <th>Name</th> -->
                  <th>Category Name</th>
                  <!-- <th>Description</th> -->
                  <!-- <th>Selling Point</th> -->
                  <th>Image</th>
                  <th>Link</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/product.js'></script>


<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}

.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}

.metaModal:hover {
  cursor: pointer;
  color: #09f;
}
</style>