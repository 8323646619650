<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Choose Place Page CMS</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page"> Choose Place Page CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Choose Place Page CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-lg-12 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="col-12 mb-3">
												<label class="form-label">Title:</label>
												<input type="text" v-model="cmsData.title" class="form-control" id="inputPageHeaderTitle" placeholder="Enter title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Description:</label>
												<textarea type="text" v-model="cmsData.description" class="form-control" id="inputPageHeaderTitle" placeholder="Enter description" rows="5"></textarea>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Question Title:</label>
												<input type="text" v-model="cmsData.questionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter question title"/>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Button One Text:</label>
												<input type="text" v-model="cmsData.buttonOneText" class="form-control" id="inputPageHeaderTitle" placeholder="Enter button one text"/>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Button Two Text:</label>
												<input type="text" v-model="cmsData.buttonTwoText" class="form-control" id="inputPageHeaderTitle" placeholder="Enter button two text"/>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Button Three Text:</label>
												<input type="text" v-model="cmsData.buttonThreeText" class="form-control" id="inputPageHeaderTitle" placeholder="Enter button three text"/>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Pickup Page Title:</label>
												<input type="text" v-model="cmsData.pickUpPageTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter pickup page title"/>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Delivery Page Title:</label>
												<input type="text" v-model="cmsData.deliveryPageTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter delivery page title"/>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Search Box Text:</label>
												<input type="text" v-model="cmsData.searchBoxText" class="form-control" id="inputPageHeaderTitle" placeholder="Enter search box text"/>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Choose Store Button Text:</label>
												<input type="text" v-model="cmsData.chooseStoreButtonText" class="form-control" id="inputPageHeaderPageName" placeholder="Enter store button text"/>
											</div>
											<div class="col-6 mb-3">
												<div class="row">
													<div class="col-12">
														<label for="inputProductTitle" class="form-label">
															Image: <strong>[Preferred Image Size: 706X706, Max Upload Limit: 1MB]</strong>
														</label>
													</div>
													<div class="col-12">
														<input class="form-control mt-3" type="file" ref="backgroundImage2" accept="image/*" @change="handleUploadImage('image','imageTwo','backgroundImage2', $event)"/>
													</div>
												</div>
												<div class="mt-3">
													<img style="position: relative; left: 11px" v-if="preview.imageTwo" :src="preview.imageTwo" height="100"/>
													<img style="position: relative; left: 11px" v-else-if="cmsData.image" :src="cmsData.image" height="100"/>
												</div>
												
											</div>
											<div class="col-6 mb-3">
												<div class="row">
													<div class="col-12">
														<label for="inputProductTitle" class="form-label">
															Logo: <strong>[Preferred Image Size: 140X50, Max Upload Limit: 1MB]</strong>
														</label>
													</div>
													<div class="col-12">
														<input class="form-control mt-3" type="file" ref="backgroundImage1" accept="image/*" @change="handleUploadImage('logo','imageOne','backgroundImage1', $event)"/>
													</div>
												</div>
												<div class="mt-3">
													<img style="position: relative; left: 11px" v-if="preview.imageOne" :src="preview.imageOne" />
													<img style="position: relative; left: 11px" v-else-if="cmsData.logo" :src="cmsData.logo" />
												</div>
												
											</div>
										</div>
									</div>
								</div>
								<div class="d-grid">
									<button type="submit" class="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/choose-place-page-cms.js"></script>