<template>
    <div class="border border-3 p-4 rounded">                 
        <div class="mb-3">
            <div class="input-group">
                <label class="form-label  col-md-12">Shop Page Link:</label>
                <input type="text" disabled value="/shop" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" style="cursor: pointer;" @click="copyLink('/shop')">Copy Link</span>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="input-group">
                <label class="form-label  col-md-12">Category Link:</label>
                <input type="text" disabled value="/categories" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" style="cursor: pointer;" @click="copyLink('/categories')">Copy Link</span>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="input-group">
                <label class="form-label  col-md-12">Specials Page Link:</label>
                <input type="text" disabled value="/specials" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" style="cursor: pointer;" @click="copyLink('/specials')">Copy Link</span>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="input-group">
                <label class="form-label  col-md-12">Career Page Link:</label>
                <input type="text" disabled value="/career" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" style="cursor: pointer;" @click="copyLink('/career')">Copy Link</span>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="input-group">
                <label class="form-label  col-md-12">Brand Page Link:</label>
                <input type="text" disabled value="/brand" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" style="cursor: pointer;" @click="copyLink('/brand')">Copy Link</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        copyLink: function(data) {
            var text = data;
            navigator.clipboard.writeText(text).then(function() {
                alert("Link copied...");
            }, function(error) {
                console.log(error);
            });
        }
    }
}
</script>