<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Exclusive Brand</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">Section Three CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Section Three CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-lg-12 mb-3">
									<div class=" p-4">
										<div class="row">
											<div class="col-6 mb-3">
												<label class="form-label">Title:</label>
												<input
													type="text"
													v-model="cmsData.title"
													class="form-control"
													id="inputPageHeaderTitle"
													placeholder="Enter title"
												/>
											</div>
										</div>

										<hr class="my-5"/>

										<div class="row border border-3 rounded p-4 mb-3">
											<div class="col-6 mb-3">
												<div class="row">
													<div class="col-12">
														<label for="inputProductTitle" class="form-label">
															Brand Image:
															<strong>
																[Preferred Image Size: 223X107, Max Upload Limit:
																1MB]
															</strong>
														</label>
													</div>
													<div class="col-12">
														<input
															class="form-control mt-3"
															type="file"
															ref="brandImage"
															accept="image/*"
															@change="handleUploadImage('brandImage','brandImage','brandImage', $event)"
														/>
													</div>
													<div class="mt-3">
														<img
															style="position: relative; left: 11px"
															v-if="preview.brandImage"
															:src="preview.brandImage"
															
															height="100"
														/>
														<img
															style="position: relative; left: 11px"
															v-else-if="cmsData.brandImage"
															:src="cmsData.brandImage"
															
															height="100"
														/>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-6 mb-3">
													<label class="form-label">Brand Image Link:</label>
													<input
														type="text"
														v-model="cmsData.brandImageLink"
														class="form-control"
														id="inputPageHeaderTitle"
														placeholder="Enter brand image Link"
													/>
												</div>
											</div>
										</div>
										<div class="row border border-3 rounded p-4 mb-3">
											<div class="col-6 mb-3">
												<div class="row">
													<div class="col-12">
														<label for="inputProductTitle" class="form-label">
															Product Image One:
															<strong>
																[Preferred Image Size: 203X203, Max Upload Limit:
																1MB]
															</strong>
														</label>
													</div>
													<div class="col-12">
														<input
															class="form-control mt-3"
															type="file"
															ref="productImageOne"
															accept="image/*"
															@change="handleUploadImage('productImageOne','productImageOne','productImageOne', $event)"
														/>
													</div>
													<div class="mt-3">
														<img
															style="position: relative; left: 11px"
															v-if="preview.productImageOne"
															:src="preview.productImageOne"
															
															height="100"
														/>
														<img
															style="position: relative; left: 11px"
															v-else-if="cmsData.productImageOne"
															:src="cmsData.productImageOne"
															height="100"
														/>
													</div>
												</div>
											</div>
											<div class="col-6 mb-3">
												<div class="row">
													<div class="col-12">
														<label for="inputProductTitle" class="form-label">
															Product Image Two:
															<strong>
																[Preferred Image Size: 203X203, Max Upload Limit:
																1MB]
															</strong>
														</label>
													</div>
													<div class="col-12">
														<input
															class="form-control mt-3"
															type="file"
															ref="productImageTwo"
															accept="image/*"
															@change="handleUploadImage('productImageTwo','productImageTwo','productImageTwo', $event)"
														/>
													</div>
												</div>
												<div class="mt-3">
													<img
														style="position: relative; left: 11px"
														v-if="preview.productImageTwo"
														:src="preview.productImageTwo"
														height="100"
													/>
													<img
														style="position: relative; left: 11px"
														v-else-if="cmsData.productImageTwo"
														:src="cmsData.productImageTwo"
														height="100"
													/>
												</div>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Product Image One Link:</label>
												<input
													type="text"
													v-model="cmsData.productImageOneLink"
													class="form-control"
													id="inputPageHeaderTitle"
													placeholder="Enter product image one link"
												/>
											</div>
											<div class="col-6 mb-3">
												<label class="form-label">Product Image Two Link:</label>
												<input
													type="text"
													v-model="cmsData.productImageTwoLink"
													class="form-control"
													id="inputPageHeaderPageName"
													placeholder="Enter product image two link"
												/>
											</div>

											<hr class="my-4"/>

											<div class="col-6 mb-3">
												<div class="row">
													<div class="col-12">
														<label for="inputProductTitle" class="form-label">
															Product Image Three:
															<strong>
																[Preferred Image Size: 203X203, Max Upload Limit:
																1MB]
															</strong>
														</label>
													</div>
													<div class="col-12">
														<input
															class="form-control mt-3"
															type="file"
															ref="productImageThree"
															accept="image/*"
															@change="handleUploadImage('productImageThree','productImageThree','productImageThree', $event)"
														/>
													</div>
													<div class="mt-3">
														<img
															style="position: relative; left: 11px"
															v-if="preview.productImageThree"
															:src="preview.productImageThree"
															
															height="100"
														/>
														<img
															style="position: relative; left: 11px"
															v-else-if="cmsData.productImageThree"
															:src="cmsData.productImageThree"
															height="100"
														/>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-6 mb-3">
													<label class="form-label">Product Image Three Link:</label>
													<input
														type="text"
														v-model="cmsData.productImageThreeLink"
														class="form-control"
														id="inputPageHeaderTitle"
														placeholder="Enter product image three link"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="d-grid">
									<button type="submit" class="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/exclusive-brand-section-three-cms.js"></script>