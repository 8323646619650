<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Home Page</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Home Component UI CMS</h5>
          <hr />
          <div class="form-body mt-4" v-if="dataLoaded">
            <div class="row mb-3">
              <div class="col-md-3 mb-3">
                <div class="input-group">
                  <LvColorpicker
                    label="Page Background Color:"
                    v-model="cmsData.backgroundColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Hero Section Button Background Color:"
                      v-model="cmsData.heroSectionShopNowButtonBackgroundColor "
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Hero Section Button Font Color:"
                      v-model="cmsData.heroSectionShopNowButtonFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Hero Section Button Hover Background Color:"
                      v-model="cmsData.heroSectionShopNowButtonHoverBackgroundColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Hero Section Button Hover Font Color:"
                      v-model="cmsData.heroSectionShopNowButtonHoverFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Category Section Title Font Color:"
                      v-model="cmsData.categorySectionTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Category Section Subtitle Font Color:"
                      v-model="cmsData.categorySectionSubtitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Today's Special Section Title Font Color:"
                      v-model="cmsData.todaysSpecialSectionTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Today's Special Section Sub Title Font Color:"
                      v-model="cmsData.todaysSpecialSectionSubTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Today's Special Section Button Background Color:"
                      v-model="cmsData.todaysSpecialSectionButtonBackgroundColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Today's Special Section Button Font Color:"
                      v-model="cmsData.todaysSpecialSectionButtonFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Today's Special Section Shop All Background Color:"
                      v-model="cmsData.todaysSpecialSectionShopAllButtonBackgroundColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Today's Special Section Shop All Button Font Color:"
                      v-model="cmsData.todaysSpecialSectionShopAllButtonFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Popular Brand Section Title Font Color:"
                      v-model="cmsData.popularBrandSectionTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Popular Brand Section Subtitle Font Color:"
                      v-model="cmsData.popularBrandSectionSubtitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Flower Section Title Font Color:"
                      v-model="cmsData.flowerSectionTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Flower Section Subtitle Font Color:"
                      v-model="cmsData.flowerSectionSubtitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Flower Section Button Background Color:"
                      v-model="cmsData.flowerSectionButtonBackgroundColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Flower Section Button Font Color:"
                      v-model="cmsData.flowerSectionButtonFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Vaporizers Section Title Font Color:"
                      v-model="cmsData.vaporizersSectionTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Vaporizers Section Sub Title Font Color:"
                      v-model="cmsData.vaporizersSectionSubTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Vaporizers Section Button Background Color:"
                      v-model="cmsData.vaporizersSectionButtonBackgroundColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Vaporizers Section Button Background Color:"
                      v-model="cmsData.vaporizersSectionButtonFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Brand Section Title Font Color:"
                      v-model="cmsData.brandSectionTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Brand Section Subtitle Font Color:"
                      v-model="cmsData.brandSectionSubtitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Dispo Difference Section Title Font Color:"
                      v-model="cmsData.dispoDifferenceSectionTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Dispo Difference Section Subtitle Font Color:"
                      v-model="cmsData.dispoDifferenceSectionSubtitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Dispo Difference Sub Section One Title Font Color:"
                      v-model="cmsData.dispoDifferenceSubSectionOneTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Dispo Difference Sub Section One Subtitle Font Color:"
                      v-model="cmsData.dispoDifferenceSubSectionOneSubtitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Dispo Difference Sub Section Two Title Font Color:"
                      v-model="cmsData.dispoDifferenceSubSectionTwoTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Dispo Difference Sub Section Two Subtitle Font Color:"
                      v-model="cmsData.dispoDifferenceSubSectionTwoSubtitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Dispo Difference Slider Arrow Color:"
                      v-model="cmsData.dispoDifferenceSliderArrowColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Dispo Difference Slider Arrow Background Color:"
                      v-model="cmsData.dispoDifferenceSliderArrowBackgroundColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Reefer A Friend Section Title Font Color:"
                      v-model="cmsData.reeferAFriendSectionTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Reefer A Friend Section Subtitle Font Color:"
                      v-model="cmsData.reeferAFriendSectionSubtitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Instagram Section Title Font Color:"
                      v-model="cmsData.instagramSectionTitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <div class="input-group">
                    <LvColorpicker
                      label="Instagram Section Subtitle Font Color:"
                      v-model="cmsData.instagramSectionSubtitleFontColor"
                      :clearable="true"
                      :bottomBar="true"
                      :colors="colors"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <div class="input-group">
                  <LvColorpicker
                    label="Footer Section Background Color:"
                    v-model="cmsData.footerSectionBackgroundColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="d-grid">
                <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                  <button class="btn btn-primary" @click="update" type="button">Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/home-component-ui.js"></script>