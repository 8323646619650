import { authHeader } from '../../../../auth';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "EditHeroSectionSlider",
    data() {
        return {
            editData: [],
            title: '',
            link: '',
            retailerId: this.$route.params.retailerId,
            retailerName: this.$route.params.retailerName,
            description: '',
            imageFile: '',
            mobileImageFile: '',
            previewImage: '',
            previewMobileImage: '',
            image: '',
            mobileImage: '',
            id: '',
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            startDate: new Date(),
            endDate: new Date(),
            isActive: false,
        }
    },
    async created() {
        document.title = "Dispo - Edit Hero Section Slider";
    },
    mounted() {
        if (this.$route.params.id != undefined) {
            this.id = this.$route.params.id;
        }
        this.getData(this.id);
    },
    methods: {
        getData: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.home.heroSectionSlider + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.editData = response.data.data[0];
                    this.image = this.editData.image;
                    this.mobileImage = this.editData.mobileImage;
                    this.title = this.editData.title;
                    this.link = this.editData.link;
                    this.retailerId = this.editData.retailerId ?? this.retailerId;
                    this.retailerName = this.editData.retailerName ?? this.retailerName;
                    this.description = this.editData.description;

                    const originalDateString = this.editData.startDate;
                    const originalDate = new Date(originalDateString);
                    const year = originalDate.getFullYear();
                    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
                    const day = String(originalDate.getDate()).padStart(2, '0');
                    this.startDate = `${year}-${month}-${day}`;

                    const originalDateString2 = this.editData.endDate;
                    const originalDate2 = new Date(originalDateString2);
                    const year2 = originalDate2.getFullYear();
                    const month2 = String(originalDate2.getMonth() + 1).padStart(2, '0');
                    const day2 = String(originalDate2.getDate()).padStart(2, '0');
                    this.endDate = `${year2}-${month2}-${day2}`;

                    this.isActive = this.editData.isActive === 0 ? false : true;
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange(event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onMobileImageChange: function (event) {
            this.mobileImageFile = event.target.files[0];
            let input = this.$refs.mobileImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewMobileImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function () {
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("image", this.imageFile ? this.imageFile : this.image);
            formData.append("mobileImage", this.mobileImageFile ? this.mobileImageFile : this.mobileImage);
            formData.append("title", this.title);
            formData.append("link", this.link);
            formData.append("description", this.description);
            formData.append("retailerId", this.retailerId);
            formData.append("retailerName", this.retailerName);
            formData.append("startDate", new Date(this.startDate));
            formData.append("endDate", new Date(this.endDate));
            formData.append("isActive", this.isActive ? 1 : 0);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.cms.home.heroSectionSlider + '/' + this.id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.previewImage = '';
                this.imageFile = '';
                this.$refs.image.value = null;
                this.previewMobileImage = '';
                this.mobileImageFile = '';
                this.$refs.mobileImage.value = null;
                this.$router.push(`/hero-section-slider-list/${this.retailerId}/${this.retailerName}`);
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
    }
}