<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Update Product Category Meta
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title"> Update Product Category Meta</h5>
            <hr />
            <div class="form-body mt-4">

              <div class="row">
                <div class="col-lg-8">

                  <div class="col-md-12 mb-3">
                    <label class="form-label">Select a Product Category:</label>
                    <v-select placeholder="Select a Product Category" v-model="category" :options="categoryList"
                      :close-on-select="true" :searchable="true" />
                  </div>

                  <hr>

                  <div class="col-md-12 mb-3">
                    <label for="pageTitle" class="form-label">Page Title:</label>
                    <input type="text" v-model="pageTitle" class="form-control">
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="metaDescription" class="form-label">Meta Description:</label>
                    <input type="text" v-model="metaDescription" class="form-control">
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="metaKeyword" class="form-label">Meta Keyword:</label>
                    <input type="text" v-model="metaKeyword" class="form-control">
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="canonicalTag" class="form-label">Canonical Tag:</label>
                    <input type="text" v-model="canonicalTag" class="form-control">
                  </div>

                  <div class="col-12 mt-3">
                    <div class="d-grid">
                      <button type="button" @click="updateProductCategoryMeta" class="btn btn-primary">
                        Save Product Category Meta
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/update-product-category-meta-data.js'></script>