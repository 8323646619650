import { authHeader } from '../../../auth';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "EditAdvertisementBanner",
    data() {
        return {
            link: '',
            image: '',
            previewImage: null,
            delay: 5,
            priority: 1,
            active: false,
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
        }
    },
    async created() {
        document.title = "Dispo - Edit Advertisement Banner";
    },
    mounted() {
        if (this.$route.params.id != undefined) {
            this.id = this.$route.params.id;
        }
        this.getData(this.id);
    },
    methods: {
        getData: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.advertisementBanner + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.editData = response.data.data[0];

                    this.image = this.editData.image;
                    this.previewImage = this.editData.image;
                    this.link = this.editData.link;
                    this.delay = this.editData.delay;
                    this.priority = this.editData.priority;

                    this.active = this.editData.active === 0 ? false : true;
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onImageChange(event) {
            this.image = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate: function () {
            if (!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter image!"
                });
                return false;
            }
            if(!this.link) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter link!"
                });
                return false;
            }
            if (!this.priority) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload positive priority!"
                });
                return false;
            }
            if (!this.delay) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter positive timer!"
                });
                return false;
            }
            return true;
        },
        update: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("image", this.image);
                formData.append("link", this.link);
                formData.append("delay", this.delay);
                formData.append("priority", this.priority);
                formData.append("active", this.active ? 1 : 0);
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.cms.advertisementBanner + '/' + this.id,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.previewImage = '';
                    this.imageFile = '';
                    this.$refs.image.value = null;
                    this.$router.push(`/advertisement-banner`);
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
    }
}