import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { authHeader } from "../../../auth";

export default {
    name: "FAQ",
    data() {
        return {
            single: {
                id: "",
                title: "",
                description: "",
                image: ""
            },
            preview: {
                image: "",
            },
            title: "",
            description: "",
            image: "",
            setURL: this.$serverURL + this.$api.cms.faq.faqPageSectionTwoURL,
            dataList: [],
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            }
        }
    },
    async created() {
        document.title = "Dispo - FAQ Page Section Two";
        this.dataList = await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        async updateModalValue(description) {
            this.description = description;
        },
        addFaqSectionTwo: async function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
            } else if(!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
            } else if(!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add image!"
                });
            }
            else {
                const fromData = new FormData();
                fromData.append("title", this.title);
                fromData.append("description", this.description);
                fromData.append("image", this.image);
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: fromData,
                    headers: authHeader()
                };
                let response = await this.postData(config);
                if (response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.title = '';
                this.description = '';
                this.image = null;
                this.preview = {};
                this.$refs.imageAdd = null;
                this.dataList = await this.getDataList(this.setURL);
                document.getElementById("closeButtonAdd").click();
            }
        },
        editFaqSectionTwo: async function (data) {
            this.single = {...data};
        },
        updateFaqSectionTwo: async function () {
            if (!this.single.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
            } else if(!this.single.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
            } else if(!this.single.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add image!"
                });
            } else {
                const fromData = new FormData();
                fromData.append("title", this.single.title);
                fromData.append("description", this.single.description);
                fromData.append("image", this.single.image);
                let config = {
                    method: "PATCH",
                    url: this.setURL + '/' + this.single.id,
                    data: fromData,
                    headers: authHeader()
                };
                let response = await this.updateData(config);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.single = {};
                this.$refs.imageEdit = null;
                this.preview = {};
                this.dataList = await this.getDataList(this.setURL);
                document.getElementById("closeButtonEdit").click();
            }
        },
        deleteFaqSectionTwo: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: this.setURL + '/' + id,
                                headers: authHeader()
                            }
                            await this.deleteData(config);
                            this.dataList = await this.getDataList(this.setURL);
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        }
                    }
            })
            
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            if(ref === "imageEdit"){
                this.single[imageKey] = event.target.files[0];
            } else {
                this.image = event.target.files[0];
            }
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
    }
}