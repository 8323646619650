<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">All Page Title CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All Page Title CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Page Title CMS</h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Signin Page Title:</label>
                                                <input type="text" v-model="cmsData.signinPageTitle" class="form-control"
                                                    placeholder="Signin page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Signin Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.signinPageMetaKeyword" class="form-control" placeholder="Signin page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Signin Page Meta Description:</label>
                                                <textarea v-model="cmsData.signinPageMetaDescription" class="form-control" placeholder="Signin page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Signup Page Title:</label>
                                                <input type="text" v-model="cmsData.signupPageTitle" class="form-control"
                                                    placeholder="Signup page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Signup Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.signupPageMetaKeyword" class="form-control" placeholder="Signup page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Signup Page Meta Description:</label>
                                                <textarea v-model="cmsData.signupPageMetaDescription" class="form-control" placeholder="Signup page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Title:</label>
                                                <input type="text" v-model="cmsData.homePageTitle" class="form-control"
                                                    placeholder="Home page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.homePageMetaKeyword" class="form-control" placeholder="Home page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Description:</label>
                                                <textarea v-model="cmsData.homePageMetaDescription" class="form-control" placeholder="Home page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Page Title:</label>
                                                <input type="text" v-model="cmsData.shopPagetitle" class="form-control"
                                                    placeholder="Shop page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.shopPageMetaKeyword" class="form-control" placeholder="Shop page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Page Meta Description:</label>
                                                <textarea v-model="cmsData.shopPageMetaDescription" class="form-control" placeholder="Shop page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Product Details Page Title:</label>
                                                <input type="text" v-model="cmsData.productDetailsPageTitle"
                                                    class="form-control" placeholder="Product details page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Product Details Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.productDetailsPageMetaKeyword" class="form-control" placeholder="Product details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Product Details Page Meta Description:</label>
                                                <textarea v-model="cmsData.productDetailsPageMetaDescription" class="form-control" placeholder="Product details page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Wishlist Page Title:</label>
                                                <input type="text" v-model="cmsData.wishlistPageTitle" class="form-control"
                                                    placeholder="Wishlist page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Wishlist Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.wishlistPageMetaKeyword" class="form-control" placeholder="Wishlist page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Wishlist Page Meta Description:</label>
                                                <textarea v-model="cmsData.wishlistPageMetaDescription" class="form-control" placeholder="Wishlist page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Title:</label>
                                                <input type="text" v-model="cmsData.cartPageTitle" class="form-control"
                                                    placeholder="Cart page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.cartPageMetaKeyword" class="form-control" placeholder="Cart page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Meta Description:</label>
                                                <textarea v-model="cmsData.cartPageMetaDescription" class="form-control" placeholder="Cart page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">About Us Page Title:</label>
                                                <input type="text" v-model="cmsData.aboutUsPageTitle" class="form-control"
                                                    placeholder="About Us page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Us Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.aboutUsPageMetaKeyword" class="form-control" placeholder="About Us page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Us Page Meta Description:</label>
                                                <textarea v-model="cmsData.aboutUsPageMetaDescription" class="form-control" placeholder="About Us page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Categories Page Title:</label>
                                                <input type="text" v-model="cmsData.categoriesPageTitle"
                                                    class="form-control" placeholder="Categories page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Categories Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.categoriesPageMetaKeyword" class="form-control" placeholder="Categories page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Categories Page Meta Description:</label>
                                                <textarea v-model="cmsData.categoriesPageMetaDescription" class="form-control" placeholder="Categories page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Brand Page Title:</label>
                                                <input type="text" v-model="cmsData.brandPageTitle" class="form-control"
                                                    placeholder="Brand page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Brand Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.brandPageMetaKeyword" class="form-control" placeholder="Brand page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Brand Page Meta Description:</label>
                                                <textarea v-model="cmsData.brandPageMetaDescription" class="form-control" placeholder="Brand page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Brand Page Title:</label>
                                                <input type="text" v-model="cmsData.shopBrandPageTitle" class="form-control"
                                                    placeholder="Shop Brand page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Exclusive Brand Page Title:</label>
                                                <input type="text" v-model="cmsData.exclusiveBrandPageTitle"
                                                    class="form-control" placeholder="Exclusive Brand page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Featured Brand Page Title:</label>
                                                <input type="text" v-model="cmsData.featuredBrandPageTitle"
                                                    class="form-control" placeholder="Featured Brand page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Featured Brand Details Page Title:</label>
                                                <input type="text" v-model="cmsData.featuredBrandDetailsPageTitle"
                                                    class="form-control" placeholder="Featured Brand Details page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Category Page Title:</label>
                                                <input type="text" v-model="cmsData.shopCategoryPageTitle"
                                                    class="form-control" placeholder="Shop Category page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Specials Page Title:</label>
                                                <input type="text" v-model="cmsData.specialsPageTitle" class="form-control"
                                                    placeholder="Specials page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Specials Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.specialsPageMetaKeyword" class="form-control" placeholder="Specials page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Specials Page Meta Description:</label>
                                                <textarea v-model="cmsData.specialsPageMetaDescription" class="form-control" placeholder="Specials page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Deals Special Page Title:</label>
                                                <input type="text" v-model="cmsData.dealsSpecialPageTitle"
                                                    class="form-control" placeholder="Deals special page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Location Page Title:</label>
                                                <input type="text" v-model="cmsData.locationPageTitle" class="form-control"
                                                    placeholder="Location page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Location Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.locationPageMetaKeyword" class="form-control" placeholder="Location page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Location Page Meta Description:</label>
                                                <textarea v-model="cmsData.locationPageMetaDescription" class="form-control" placeholder="Location page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Us Page Title:</label>
                                                <input type="text" v-model="cmsData.contactUsPageTitle" class="form-control"
                                                    placeholder="Contact Us page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Us Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.contactUsPageMetaKeyword" class="form-control" placeholder="Contact Us page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Us Page Meta Description:</label>
                                                <textarea v-model="cmsData.contactUsPageMetaDescription" class="form-control" placeholder="Contact Us page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Page Title:</label>
                                                <input type="text" v-model="cmsData.faqPageTitle" class="form-control"
                                                    placeholder="FAQ page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.faqPageMetaKeyword" class="form-control" placeholder="FAQ page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Page Meta Description:</label>
                                                <textarea v-model="cmsData.faqPageMetaDescription" class="form-control" placeholder="FAQ page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Title:</label>
                                                <input type="text" v-model="cmsData.careerPageTitle" class="form-control"
                                                    placeholder="Career page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.careerPageMetaKeyword" class="form-control" placeholder="Career page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Meta Description:</label>
                                                <textarea v-model="cmsData.careerPageMetaDescription" class="form-control" placeholder="Career page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Department Page Title:</label>
                                                <input type="text" v-model="cmsData.careerDepartmentPageTitle"
                                                    class="form-control" placeholder="Career Department page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Department Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.careerDepartmentPageMetaKeyword" class="form-control" placeholder="Career Department page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Department Page Meta Description:</label>
                                                <textarea v-model="cmsData.careerDepartmentPageMetaDescription" class="form-control" placeholder="Career Department page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Details Page Title:</label>
                                                <input type="text" v-model="cmsData.careerDetailsPageTitle"
                                                    class="form-control" placeholder="Career Details page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Details Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.careerDetailsPageMetaKeyword" class="form-control" placeholder="Career Details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Details Page Meta Description:</label>
                                                <textarea v-model="cmsData.careerDetailsPageMetaDescription" class="form-control" placeholder="Career Details page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page Title:</label>
                                                <input type="text" v-model="cmsData.blogPageTitle" class="form-control"
                                                    placeholder="Blog page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.blogPageMetaKeyword" class="form-control" placeholder="Blog page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page Meta Description:</label>
                                                <textarea v-model="cmsData.blogPageMetaDescription" class="form-control" placeholder="Blog page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Details Page Title:</label>
                                                <input type="text" v-model="cmsData.blogDetailsPageTitle"
                                                    class="form-control" placeholder="Blog Details page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Details Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.blogDetailsPageMetaKeyword" class="form-control" placeholder="Blog Details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Details Page Meta Description:</label>
                                                <textarea v-model="cmsData.blogDetailsPageMetaDescription" class="form-control" placeholder="Blog Details page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Title:</label>
                                                <input type="text" v-model="cmsData.privacyPageTitle" class="form-control"
                                                    placeholder="Privacy page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.privacyPageMetaKeyword" class="form-control" placeholder="Privacy page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Page Meta Description:</label>
                                                <textarea v-model="cmsData.privacyPageMetaDescription" class="form-control" placeholder="Privacy page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Terms & Conditions Page Title:</label>
                                                <input type="text" v-model="cmsData.termsPageTitle" class="form-control"
                                                    placeholder="Terms & Conditions page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms & Conditions Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.termsPageMetaKeyword" class="form-control" placeholder="Terms & Conditions page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms & Conditions Page Meta Description:</label>
                                                <textarea v-model="cmsData.termsPageMetaDescription" class="form-control" placeholder="Terms & Conditions page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Profile Page Title:</label>
                                                <input type="text" v-model="cmsData.profilePageTitle" class="form-control"
                                                    placeholder="Profile page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Profile Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.profilePageMetaKeyword" class="form-control" placeholder="Profile page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Profile Page Meta Description:</label>
                                                <textarea v-model="cmsData.profilePageMetaDescription" class="form-control" placeholder="Profile page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Success Page Title:</label>
                                                <input type="text" v-model="cmsData.successPageTitle" class="form-control"
                                                    placeholder="Success page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Success Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.successPageMetaKeyword" class="form-control" placeholder="Success page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Success Page Meta Description:</label>
                                                <textarea v-model="cmsData.successPageMetaDescription" class="form-control" placeholder="Success page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Retailer Page Title:</label>
                                                <input type="text" v-model="cmsData.retailerPageTitle" class="form-control"
                                                    placeholder="Retailer page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Retailer Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.retailerPageMetaKeyword" class="form-control" placeholder="Retailer page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Retailer Page Meta Description:</label>
                                                <textarea v-model="cmsData.retailerPageMetaDescription" class="form-control" placeholder="Retailer page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Forgot Password Page Title:</label>
                                                <input type="text" v-model="cmsData.forgotPasswordPageTitle"
                                                    class="form-control" placeholder="Forgot Password page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Forgot Password Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.forgotPasswordPageMetaKeyword" class="form-control" placeholder="Forgot Password page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Forgot Password Page Meta Description:</label>
                                                <textarea v-model="cmsData.forgotPasswordPageMetaDescription" class="form-control" placeholder="Forgot Password page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Choose Place Page Title:</label>
                                                <input type="text" v-model="cmsData.choosePlacePageTitle"
                                                    class="form-control" placeholder="Choose Place page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Choose Place Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.choosePlacePageMetaKeyword" class="form-control" placeholder="Choose Place page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Choose Place Page Meta Description:</label>
                                                <textarea v-model="cmsData.choosePlacePageMetaDescription" class="form-control" placeholder="Choose Place page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Reset Password Page Title:</label>
                                                <input type="text" v-model="cmsData.resetPasswordPageTitle"
                                                    class="form-control" placeholder="Reset Password page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Switch Place Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.resetPasswordPageMetaKeyword" class="form-control" placeholder="Switch Place page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Switch Place Page Meta Description:</label>
                                                <textarea v-model="cmsData.resetPasswordPageMetaDescription" class="form-control" placeholder="Switch Place page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr />
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Invoice Page Title:</label>
                                                <input type="text" v-model="cmsData.invoicePageTitle" class="form-control"
                                                    placeholder="Invoice page title">
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Invoice Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.invoicePageMetaKeyword" class="form-control" placeholder="Invoice page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Invoice Page Meta Description:</label>
                                                <textarea v-model="cmsData.invoicePageMetaDescription" class="form-control" placeholder="Invoice page meta description"></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <hr>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Store Page Title:</label>
                                                <input type="text" v-model="cmsData.storePageTitle" class="form-control"
                                                    placeholder="Store page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Order Page Title:</label>
                                                <input type="text" v-model="cmsData.orderPageTitle" class="form-control"
                                                    placeholder="Order page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Sitemap Page Title:</label>
                                                <input type="text" v-model="cmsData.sitemapPageTitle" class="form-control"
                                                    placeholder="Sitemap page title">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="updateCms()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/site-page-title-cms.js"></script>