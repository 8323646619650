<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add Product
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Add New Product</h5>
            <hr />
            <div class="form-body mt-4">

              <div class="row">
                <div class="col-lg-8">

                  <!-- <div class="mb-3">
                    <label class="form-label">Name:</label>
                    <input type="text" v-model="name" class="form-control">
                  </div> -->

                  <!-- <div class="mb-3">
                    <label class="form-label">Description:</label>
                    <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                  </div> -->


                  <div class="mb-3 text-center">
                    <a href="https://dispodutchie.nyc3.cdn.digitaloceanspaces.com/default/instructions-for-featured-brand-products-v2.pdf"
                      target="_blank">
                      Instructions for Image Upload PDF
                    </a>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label class="form-label">Select a Category:</label>
                    <v-select placeholder="Select a Category" v-model="category" :options="categoryList"
                      :close-on-select="true" :searchable="true" />
                  </div>

                  <!-- <div class="mb-3">
                    <label class="form-label">Selling Point:</label>
                    <input type="text" v-model="sellingPoint" class="form-control">
                  </div> -->

                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label for="exampleInputEmail1">Image:
                        <strong>[Max Upload Limit:
                          1MB]</strong></label>
                      <input class="form-control mb-2" type="file" name="image" id="image" ref="imageInput"
                        aria-describedby="imageHelp" accept="image/*" @change="uploadImage" />
                      <img v-if="previewImage" :src="previewImage" height="100" />
                      <img v-else src="/core/assets/images/default.png" height="100" />
                    </div>
                  </div>

                  <div class="mb-3 mt-3">
                    <label class="form-label">Link:</label>
                    <input type="text" v-model="link" class="form-control">
                  </div>

                  <div class="col-12 mt-3">
                    <div class="d-grid">
                      <button type="button" @click="addProduct" class="btn btn-primary">
                        Save Product
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/add-product.js'></script>