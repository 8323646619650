export default {
    name: "HeaderBannerCMS",
    data() {
        return {
            specificationKey: [],
            specificationValue: [],
            specificationArray: [],
            counter: 1,
            cmsData: {}
        }
    },
    async created() {
        document.title = "Dispo - Header Banner CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let url = this.$serverURL + this.$api.cms.headerBannerCmsURL;
            let response = await this.getCmsData(url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
                if (Object.keys(this.cmsData).length > 0) {
                    this.counter = 0;
                    let bannerTextData = this.cmsData.bannerText;
                    if (bannerTextData != null) {
                        let dataArray = JSON.parse(bannerTextData);
                        let index = 0;
                        for (const banner of dataArray) {
                            this.specificationKey[index] = banner?.title;
                            this.specificationValue[index] = banner?.link;
                            this.specificationArray.push({ title: this.specificationKey[index], link: this.specificationValue[index] })
                            index++;
                            this.counter = this.counter + 1;
                        }
                    }
                }
                else {
                    this.counter = 1;
                }
            }
            else {
                this.cmsData = {};
            }
        },
        addSpecification() {
            // let index = this.counter - 1;
            // if (!this.specificationKey[index]) {
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Please enter banner text!"
            //     });
            //     return;
            // }
            // if (!this.specificationValue[index]) {
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Please enter link!"
            //     });
            //     return;
            // }
            this.counter = this.counter + 1;
        },
        deleteSpecificationRow(index) {
            delete this.specificationArray[this.specificationKey[index]];
            this.specificationKey.splice(index, 1);
            this.specificationValue.splice(index, 1);
            this.counter = this.counter - 1;
        },
        finalValidation() {
            for (let i = 0; i < this.counter; i++) {
                if (!this.specificationKey[i] && !this.specificationValue[i]) {
                    return true
                }
                if (!this.specificationKey[i]) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter banner text!"
                    });
                    return false;
                }
                if (!this.specificationValue[i]) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter link!"
                    });
                    return false;
                }
            }
            return true;
        },
        addEntity: async function () {
            if (this.finalValidation()) {
                this.specificationArray = [];
                const _specificationKey = [];
                const _specificationValue = [];
                for (let i = 0; i < this.counter; i++) {
                    if (this.specificationKey[i] && this.specificationValue[i]) {
                        this.specificationArray.push({ title: this.specificationKey[i], link: this.specificationValue[i] });
                        _specificationKey.push(this.specificationKey[i]);
                        _specificationValue.push(this.specificationValue[i]);
                    }
                }
                this.specificationKey = _specificationKey;
                this.specificationValue = _specificationValue;
                this.counter = this.specificationKey?.length;
                this.$swal.showLoading();
                let data = { 'bannerText': JSON.stringify(this.specificationArray) };
                let url = this.$serverURL + this.$api.cms.headerBannerCmsURL;
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.$swal.hideLoading();
                }
            }

        }
    }
}