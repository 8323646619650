import { authHeader } from '../../../auth';

export default {
    name: "EditWhyJoinUs",
    data() {
        return {
            editData: [],
            title: '',
            description: '',
            iconFile: '',
            previewIcon: '',
            icon: '',
            id: ''
        }
    },
    async created() {
        document.title = "Dispo - Edit Why Join Us";
    },
    mounted() {
        if(this.$route.params.id != undefined) {
            this.id = this.$route.params.id;
        }
        this.getData(this.id);
    },
    methods: {
        getData: async function(id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.whyJoinUsURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.editData = response.data.data[0];
                    this.icon = this.editData.icon;
                    this.title = this.editData.title;
                    this.description = this.editData.description;
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onIconChange(event) {
            this.iconFile = event.target.files[0];
            let input = this.$refs.icon;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewIcon = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function() {
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("icon", this.iconFile ? this.iconFile : this.icon);
            formData.append("title", this.title);
            formData.append("description", this.description);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.cms.whyJoinUsURL + '/' + this.id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.previewIcon = '';
                this.iconFile = '';
                this.$refs.icon.value = null;
                this.$router.push("/why-join-us");
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}