export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.offerrPopUpCms,
            cmsData: {},
            previewImage: "",
            pages: [
                {
                    value: "/",
                    label:"Home Page"
                },
                {
                    value: "/shop",
                    label:"Shop Page"
                },
                {
                    value: "/brand",
                    label:"Brand Page"
                },
                {
                    value: "/career",
                    label:"Career Page"
                },
                {
                    value: "/aboutus",
                    label:"About Us Page"
                },
                {
                    value: "/blog",
                    label:"Blog Page"
                }
            ],
            selectedPages: [],
        }
    },
    async created() {
        document.title = "Dispo - Offer Pop Up CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
                this.selectedPages = JSON.parse(this.cmsData?.pages) ? JSON.parse(this.cmsData?.pages) : [];
            }
            else {
                this.cmsData = {};
            }
        },
        handleUploadImage(event) {
            this.cmsData.image = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                let fromData = new FormData();
                fromData.append('image', this.cmsData.image);
                fromData.append('title', this.cmsData.title);
                fromData.append('subTitle', this.cmsData.subTitle);
                fromData.append('description', this.cmsData.description);
                fromData.append('offerButtonText', this.cmsData.offerButtonText);
                fromData.append('offerButtonLink', this.cmsData.offerButtonLink);
                fromData.append('bottomButtonText', this.cmsData.bottomButtonText);
                fromData.append('time', this.cmsData.time);
                fromData.append('pages', JSON.stringify(this.selectedPages));
                fromData.append('visitCount', this.cmsData.visitCount);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, fromData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$refs.image.value = null;
                    this.previewImage = "";
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}