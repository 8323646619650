import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            id: this.$route.params.id,

            setURL: this.$serverURL + this.$api.category.meta,
            categoryURL: this.$serverURL + this.$api.category.requestURL,

            category: [],
            categoryList: [],

            pageTitle: "",
            metaDescription: "",
            metaKeyword: "",
            canonicalTag: "",

            singleProductCategory: {},
        }

    },
    async created() {
        document.title = "Dispo - Update Product Category Meta";
        await this.getCategoryList();
        await this.getProductCategoryMetaDetails();
    },
    methods: {
        getProductCategoryMetaDetails: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.setURL + '/' + this.id,
                    headers: {
                        ...authHeader()
                    }
                };
                this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data?.data?.length) {
                            this.singleProductCategory = response.data.data[0];
                            this.category = {
                                value: {},
                                label: ''
                            };
                            const foundCategory = this.categoryList?.filter((category) => category.value.id === this.singleProductCategory.categoryId);
                            this.category = foundCategory?.[0];

                            this.category.value.id = this.singleProductCategory.categoryId;
                            this.category.value.categoryName = this.singleProductCategory.categoryName;
                            this.pageTitle = this.singleProductCategory.pageTitle;
                            this.metaDescription = this.singleProductCategory.metaDescription;
                            this.metaKeyword = this.singleProductCategory.metaKeyword;
                            this.canonicalTag = this.singleProductCategory.canonicalTag;
                        }
                    }
                }).catch(error => {
                    console.log(error.response.data.message);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getCategoryList: async function () {
            const config = {
                method: "GET",
                url: this.categoryURL,
                headers: {
                    ...authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.categoryList = response?.data?.data?.map((el) => {
                        return { value: el, label: `${this.toTitleCaseV2(el.categoryName)}` };
                    });
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        checkValidation: function () {
            if (!(Object.keys(this.category).length)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select category!"
                });
                return false;
            }
            return true;
        },
        updateProductCategoryMeta: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    const payload = {
                        categoryId: this.category.value.id,
                        categoryName: this.toTitleCaseV2(this.category.value.categoryName),
                        pageTitle: this.pageTitle,
                        metaDescription: this.metaDescription,
                        metaKeyword: this.metaKeyword,
                        canonicalTag: this.canonicalTag,
                    }

                    const config = {
                        method: 'PATCH',
                        url: this.setURL + '/' + this.id,
                        data: payload,
                        headers: {
                            ...authHeader(),
                        }
                    };
                    await this.$axios(config).then((response) => {
                        if (response.status == 200) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }
                        this.$router.push("/product-category-meta-data");
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        },
        toTitleCaseV2: function (str) {
            if (str) {
                return str.replace(/\w\S*/g, function (txt) {
                    let text = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    if (text.includes("_")) {
                        let str = text.split("_");
                        let final_str = "";
                        for (let i = 0; i < str.length; i++) {
                            let new_str = str[i].charAt(0).toUpperCase() + str[i].substr(1).toLowerCase();
                            final_str += new_str + ' ';
                        }
                        return final_str.trim();
                    }
                    return text;
                });
            } else {
                return;
            }
        },
    }
}