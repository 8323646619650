import jwt_decode from "jwt-decode";

export default function adminGuard(to, from, next) {
    const token = localStorage.getItem('token');
    if (token) {
        const decoded = jwt_decode(token);
        const role = decoded?.role;
        if (role == 2) {
            next();
        } else {
            next('/unauthorized');
        }
    }
    else {
        next('/admin-login');
    }
}