export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.canonicalTags,
            cmsData: {},
        }
    },
    async created() {
        document.title = "Dispo - Canonical Tags";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async updateCms() {
            try {
                let data = {
                    'signinPage': this.cmsData.signinPage,
                    'signupPage': this.cmsData.signupPage,
                    'homePage': this.cmsData.homePage,
                    'shopPage': this.cmsData.shopPage,
                    'productDetailsPage': this.cmsData.productDetailsPage,
                    'wishlistPage': this.cmsData.wishlistPage,
                    'cartPage': this.cmsData.cartPage,
                    'aboutUsPage': this.cmsData.aboutUsPage,
                    'categoriesPage': this.cmsData.categoriesPage,
                    'brandPage': this.cmsData.brandPage,
                    'specialsPage': this.cmsData.specialsPage,
                    'locationPage': this.cmsData.locationPage,
                    'contactUsPage': this.cmsData.contactUsPage,
                    'faqPage': this.cmsData.faqPage,
                    'careerPage': this.cmsData.careerPage,
                    'careerDepartmentPage': this.cmsData.careerDepartmentPage,
                    'careerDetailsPage': this.cmsData.careerDetailsPage,
                    'blogPage': this.cmsData.blogPage,
                    'blogDetailsPage': this.cmsData.blogDetailsPage,
                    'privacyPage': this.cmsData.privacyPage,
                    'termsPage': this.cmsData.termsPage,
                    'profilePage': this.cmsData.profilePage,
                    'successPage': this.cmsData.successPage,
                    'forgotPasswordPage': this.cmsData.forgotPasswordPage,
                    'retailerPage': this.cmsData.retailerPage,
                    'choosePlacePage': this.cmsData.choosePlacePage,
                    'resetPasswordPage': this.cmsData.resetPasswordPage,
                    'invoicePage': this.cmsData.invoicePage,
                    dealsSpecialPage: this.cmsData.dealsSpecialPage,
                    shopBrandPage: this.cmsData.shopBrandPage,
                    shopCategoryPage: this.cmsData.shopCategoryPage,
                    exclusiveBrandPage: this.cmsData.exclusiveBrandPage,
                    featuredBrandPage: this.cmsData.featuredBrandPage,
                    featuredBrandDetailsPage: this.cmsData.featuredBrandDetailsPage,
                    sitemapPage: this.cmsData.sitemapPage,
                    storePage: this.cmsData.storePage,
                    orderPage: this.cmsData.orderPage,
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}