export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.signinPageCmsURL,
            cmsData: {},
            preview: {
                imageOne: "",
                imageTwo: "",
            }
        }
    },
    async created() {
        document.title = "Dispo - Sign In Page CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                let fromData = new FormData();
                fromData.append('image', this.cmsData.image);
                fromData.append('logo', this.cmsData.logo);
                fromData.append('title', this.cmsData.title);
                fromData.append('buttonText', this.cmsData.buttonText);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, fromData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$refs.backgroundImage1.value = null;
                    this.$refs.backgroundImage2.value = null;
                    this.preview = {
                        imageOne: "",
                        imageTwo: "",
                    }
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}