// import { authHeader } from '../../../../../auth';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "AddHeroSectionSlider",
    data() {
        return {
            title: '',
            link: '',
            retailerId: this.$route.params.retailerId,
            retailerName: this.$route.params.retailerName,
            description: '',
            image: '',
            mobileImage: '',
            previewImage: null,
            previewMobileImage: null,
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            startDate: undefined,
            endDate: undefined,
            isActive: undefined,
        }
    },
    async created() {
        document.title = "Dispo - Add Hero Section Slider";
    },
    methods: {
        onImageChange: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        onMobileImageChange: function (event) {
            this.mobileImage = event.target.files[0];
            let input = this.$refs.mobileImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewMobileImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
                return false;
            }
            // if(!this.link) {
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Please enter link!"
            //     });
            //     return false;
            // }
            if (!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload image!"
                });
                return false;
            }
            // if (!this.mobileImage) {
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Please upload mobile image!"
            //     });
            //     return false;
            // }
            if (!this.startDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add start date!"
                });
                return false;
            }
            if (!this.endDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add end date!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("image", this.image);
                formData.append("mobileImage", this.mobileImage);
                formData.append("title", this.title);
                formData.append("link", this.link);
                formData.append("description", this.description);
                formData.append("retailerId", this.retailerId);
                formData.append("retailerName", this.retailerName);
                formData.append("startDate", new Date(this.startDate));
                formData.append("endDate", new Date(this.endDate));
                formData.append("isActive", this.isActive ? 1 : 0);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.home.heroSectionSlider,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                this.$swal.showLoading();
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.title = '';
                    this.link = '';
                    this.description = '';
                    this.image = '';
                    this.mobileImage = '';
                    this.$refs.image.value = null;
                    this.$refs.mobileImage.value = null;
                    this.$router.push(`/hero-section-slider-list/${this.retailerId}/${this.retailerName}`);
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
                this.$swal.hideLoading();
            }
        }
    }
}