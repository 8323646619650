<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">SEO Content CMS</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">SEO Content CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">SEO Content CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-lg-8 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="col-12 mb-3">
												<label class="form-label">Home Page SEO Section One Title:</label>
												<input type="text" v-model="cmsData.homePageSeoSectionOneTitle"
													class="form-control" id="inputPageHeaderTitle"
													placeholder="Enter Home Page SEO Section One Title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Home Page SEO Section One Description:</label>
												<ckeditor :editor="editor"
													v-model="cmsData.homePageSeoSectionOneDescription"
													:config="editorConfig"></ckeditor>
											</div>
											<div class="col-12 mb-3">
												<div class="row">
													<div class="col-12">
														<label for="inputProductTitle" class="form-label">
															Image: <strong>[Preferred Image Size: 561X599, Max Upload
																Limit: 1MB]</strong>
														</label>
													</div>
													<div class="col-12">
														<input class="form-control mt-3" type="file"
															ref="backgroundImage1" accept="image/*"
															@change="handleUploadImage('homePageSeoSectionTwoImage', 'imageOne', 'backgroundImage1', $event)" />
													</div>
												</div>
												<div class="mt-3">
													<img style="position: relative; left: 11px" v-if="preview.imageOne"
														:src="preview.imageOne" height="100" />
													<img style="position: relative; left: 11px"
														v-else-if="cmsData.homePageSeoSectionTwoImage"
														:src="cmsData.homePageSeoSectionTwoImage" height="100" />
												</div>
												<div class="col-12 mt-3 mb-3">
													<label class="form-label">Home Page SEO Section Two Title:</label>
													<input type="text" v-model="cmsData.homePageSeoSectionTwoTitle"
														class="form-control" id="inputPageHeaderTitle"
														placeholder="Home Page SEO Section Two Title" />
												</div>
												<div class="col-12 mb-3">
													<label class="form-label">Home Page SEO Section Two
														Description:</label>
													<ckeditor :editor="editor"
														v-model="cmsData.homePageSeoSectionTwoDescription"
														:config="editorConfig"></ckeditor>
												</div>
												<div class="col-12 mb-3">
													<label class="form-label">Shop Page SEO Section One Title:</label>
													<input type="text" v-model="cmsData.shopPageSeoSectionOneTitle"
														class="form-control" id="inputPageHeaderTitle"
														placeholder="Shop Page SEO Section One Title" />
												</div>
												<div class="col-12 mb-3">
													<label class="form-label">Shop Page SEO Section One
														Description:</label>
													<ckeditor :editor="editor"
														v-model="cmsData.shopPageSeoSectionOneDescription"
														:config="editorConfig"></ckeditor>
												</div>
												<div class="col-12 mb-3">
													<label class="form-label">Deals Page SEO Section One Title:</label>
													<input type="text" v-model="cmsData.dealsPageSeoSectionOneTitle"
														class="form-control" id="inputPageHeaderTitle"
														placeholder="Deals Page SEO Section One Title" />
												</div>
												<div class="col-12 mb-3">
													<label class="form-label">Deals Page SEO Section One
														Description:</label>
													<ckeditor :editor="editor"
														v-model="cmsData.dealsPageSeoSectionOneDescription"
														:config="editorConfig"></ckeditor>
												</div>
												<div class="col-12 mb-3">
													<label class="form-label">Heading:</label>
													<input type="text" v-model="cmsData.heading"
														class="form-control" id="inputPageHeaderTitle"
														placeholder="Enter heading" />
												</div>
												<div class="col-12 mb-3">
													<label class="form-label">ButtonText:</label>
													<input type="text" v-model="cmsData.buttonText"
														class="form-control" id="inputPageHeaderTitle"
														placeholder="Enter button text" />
												</div>
												<div class="col-12 mb-3">
													<label class="form-label">ButtonLink:</label>
													<input type="text" v-model="cmsData.buttonLink"
														class="form-control" id="inputPageHeaderTitle"
														placeholder="Enter button link" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="d-grid col-lg-8">
									<button type="submit" class="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/seo-content-text-cms"></script>