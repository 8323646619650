import { authHeader } from "../../../../auth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "FAQ",
  data() {
    return {
      addNew: {},
      single: {
        name: "",
        occupation: "",
        rating: "",
        description: "",
        image: null,
      },
      setURL: this.$serverURL + this.$api.cms.homev2.customerFeedbackCRUD,
      dataList: [],
      preview: {
        imageOne: null,
        imageTwo: null,
      },
      editor: ClassicEditor,
      editorConfig: {
        ckfinder: {
          uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
        },
      },
    };
  },
  async created() {
    document.title = "Dispo - Why Choose Our Dispensary FAQ";
    this.dataList = await this.getDataList(this.setURL);
    var scripts = [
      "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
      "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
      "/core/assets/js/table-datatable.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    document.head.appendChild(style);
  },
  methods: {
    async updateAnswerModalValue(answer) {
      this.answer = answer;
    },
    handleAddUploadImage(imageKey, previewImageKey, ref, event) {
      this.addNew[imageKey] = event.target.files[0]; // Save the file
      const file = event.target.files[0]; // Access the selected file

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview[previewImageKey] = e.target.result; // Set the preview
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      }
    },
    handleUploadImage(imageKey, previewImageKey, ref, event) {
      this.single[imageKey] = event.target.files[0]; // Save the file
      const file = event.target.files[0]; // Access the selected file
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview[previewImageKey] = e.target.result; // Set the preview
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      }
    },
    validate(obj) {
      for (const key in obj) {
        if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
          return false;
        }
      }
      return true;
    },
    addFaq: async function () {
      if (!this.validate(this.addNew)) {
        this.$swal.fire({
          icon: "error",
          text: `Fields can not be empty!`,
        });
      } else {
        let formData = new FormData();
        formData.append("name", this.addNew.name);
        formData.append("occupation", this.addNew.occupation);
        formData.append("image", this.addNew.image);
        formData.append("rating", this.addNew.rating);
        formData.append("description", this.addNew.description);
        let config = {
          method: "POST",
          url: this.setURL,
          data: formData,
          headers: authHeader(),
        };
        let response = await this.postData(config);
        if (response.statusCode == 201) {
          this.$refs.backgroundImage1.value = null;
          this.preview = {
            imageOne: "",
          };
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        this.addNew.name = "";
        this.addNew.occupation = "";
        this.addNew.image = "";
        this.addNew.rating = "";
        this.addNew.description = "";
        this.$refs.backgroundImage1.value = null;
        this.preview = {
          imageTwo: "",
        };
        this.dataList = await this.getDataList(this.setURL);
        document.getElementById("closeButton").click();
      }
    },
    editFaq: async function (data) {
      this.single = data;
      this.preview.imageOne = data.image;
    },
    updateFaq: async function () {
      if (!this.validate(this.single)) {
        this.$swal.fire({
          icon: "error",
          text: `Fields can not be empty!`,
        });
      } else {
        let formData = new FormData();
        formData.append("name", this.single.name);
        formData.append("occupation", this.single.occupation);
        formData.append("image", this.single.image);
        formData.append("rating", this.single.rating);
        formData.append("description", this.single.description);
        let config = {
          method: "PATCH",
          url: this.setURL + "/" + this.single.id,
          data: formData,
          headers: authHeader(),
        };
        let response = await this.postData(config);
        if (response.statusCode == 201) {
          this.$refs.backgroundImage2.value = null;
          this.preview = {
            imageTwo: "",
          };
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: response.message,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        }
        this.single.name = "";
        this.single.occupation = "";
        this.single.image = "";
        this.single.rating = "";
        this.single.description = "";
        this.$refs.backgroundImage1.value = null;
        this.preview = {
          imageOne: "",
        };
        this.dataList = await this.getDataList(this.setURL);
        document.getElementById("closeButton2").click();
      }
    },
    deleteFaq: function (id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              let config = {
                method: "DELETE",
                url: this.setURL + "/" + id,
                headers: authHeader(),
              };
              await this.deleteData(config);
              this.dataList = await this.getDataList(this.setURL);
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                text: error.response.data.message,
              });
            }
          }
        });
    },
  },
};
