<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Store Details CMS</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">Store Details CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Store Details CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-12 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="mb-3">
												<label class="form-label">Amenities Title:</label>
												<input
													type="text"
													v-model="cmsData.amenitiesTitle"
													class="form-control"
													placeholder="Enter title"
												/>
											</div>
											<div class="mb-3">
												<div class="row">
													<div class="col-12">
														<label class="form-label">Banner Image:</label>
													</div>
													<div class="col-12">
														<input
															class="form-control mt-3"
															type="file"
															ref="bannerImage"
															accept="image/*"
															@change="handleUploadImage('bannerImage','bannerImage','bannerImage', $event)"
														/>
													</div>
													<div class="mt-3">
														<img
															style="position: relative; left: 11px"
															v-if="preview.bannerImage"
															:src="preview.bannerImage"
															height="100"
														/>
														<img
															style="position: relative; left: 11px"
															v-else-if="cmsData.bannerImage"
															:src="cmsData.bannerImage"
															height="100"
														/>
													</div>
												</div>
											</div>
											<div class="mb-3">
												<div class="row">
													<div class="col-12">
														<label class="form-label">Accessable Icon:</label>
													</div>
													<div class="col-12">
														<input
															class="form-control mt-3"
															type="file"
															ref="accessableIcon"
															accept="image/*"
															@change="handleUploadImage('accessableIcon','accessableIcon','accessableIcon', $event)"
														/>
													</div>
													<div class="mt-3">
														<img
															style="position: relative; left: 11px"
															v-if="preview.accessableIcon"
															:src="preview.accessableIcon"
															height="100"
														/>
														<img
															style="position: relative; left: 11px"
															v-else-if="cmsData.accessableIcon"
															:src="cmsData.accessableIcon"
															height="100"
														/>
													</div>
												</div>
											</div>
											<div class="mb-3">
												<div class="row">
													<div class="col-12">
														<label class="form-label">ATM Icon:</label>
													</div>
													<div class="col-12">
														<input
															class="form-control mt-3"
															type="file"
															ref="atmIcon"
															accept="image/*"
															@change="handleUploadImage('atmIcon','atmIcon','atmIcon', $event)"
														/>
													</div>
													<div class="mt-3">
														<img
															style="position: relative; left: 11px"
															v-if="preview.atmIcon"
															:src="preview.atmIcon"
															height="100"
														/>
														<img
															style="position: relative; left: 11px"
															v-else-if="cmsData.atmIcon"
															:src="cmsData.atmIcon"
															height="100"
														/>
													</div>
												</div>
											</div>
											<div class="mb-3">
												<div class="row">
													<div class="col-12">
														<label class="form-label">Minimum Age Icon:</label>
													</div>
													<div class="col-12">
														<input
															class="form-control mt-3"
															type="file"
															ref="minimumageIcon"
															accept="image/*"
															@change="handleUploadImage('minimumageIcon','minimumageIcon','minimumageIcon', $event)"
														/>
													</div>
													<div class="mt-3">
														<img
															style="position: relative; left: 11px"
															v-if="preview.minimumageIcon"
															:src="preview.minimumageIcon"
															height="100"
														/>
														<img
															style="position: relative; left: 11px"
															v-else-if="cmsData.minimumageIcon"
															:src="cmsData.minimumageIcon"
															height="100"
														/>
													</div>
												</div>
											</div>
											<div class="mb-3">
												<div class="row">
													<div class="col-12">
														<label class="form-label">Brand Verified Icon:</label>
													</div>
													<div class="col-12">
														<input
															class="form-control mt-3"
															type="file"
															ref="brandVerifiedIcon"
															accept="image/*"
															@change="handleUploadImage('brandVerifiedIcon','brandVerifiedIcon','brandVerifiedIcon', $event)"
														/>
													</div>
													<div class="mt-3">
														<img
															style="position: relative; left: 11px"
															v-if="preview.brandVerifiedIcon"
															:src="preview.brandVerifiedIcon"
															height="100"
														/>
														<img
															style="position: relative; left: 11px"
															v-else-if="cmsData.brandVerifiedIcon"
															:src="cmsData.brandVerifiedIcon"
															height="100"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="d-grid">
									<button type="submit" class="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/store-details-cms.js"></script>