<template>
    <header>
        <div class="topbar d-flex align-items-center">
            <nav class="navbar navbar-expand">
                <div class="mobile-toggle-menu"><i class="bx bx-menu"></i></div>
                <div class="search-bar flex-grow-1">

                </div>
                <div class="user-box dropdown ms-auto">
                    <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="https://png.pngtree.com/element_our/png_detail/20181206/users-vector-icon-png_260862.jpg" class="user-img" alt="user avatar"/>
                        <div class="user-info ps-3">
                            <p class="user-name mb-0">{{ name }}</p>
                            <p class="designattion mb-0">{{ email }}</p>
                        </div>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                            <router-link to="/" class="dropdown-item"><i class="bx bx-home-circle"></i><span>Dashboard</span></router-link>
                        </li>
                        <li>
                            <router-link to="/change-password" class="dropdown-item"><i class="bx bx-key"></i><span>Change Password</span></router-link>
                        </li>
                        <li>
                            <a @click="logout()" class="dropdown-item" href="javascript:void(0)"><i class="bx bx-log-out-circle"></i><span>Logout</span></a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>
</template>
<script src="../js/header.js"></script>
