<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Feedback List</li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModalFeedback"
            >
              <i class="bx bxs-plus-square"></i>Add New Feedback
            </button>
          </div>
          <!-- Add Modal -->
          <div
            class="modal fade"
            id="addModalFeedback"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="addFaq">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Add Feedback</h5>
                    <button
                      type="button"
                      id="closeButton"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Name:</label>
                    <input
                          type="text"
                          v-model="addNew.name"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter name"
                        />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Occupation:</label>
                    <input
                          type="text"
                          v-model="addNew.occupation"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter occupation"
                        />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Rating:</label>
                    <input
                          type="number"
                          v-model="addNew.rating"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter rating"
                           max="5"
                        />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Description:</label>
                    <ckeditor
                      :editor="editor"
                      v-model="addNew.description"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                  <div class="modal-body">
                    <div class="col-12 mb-3">
                        <div class="row">
                          <div class="col-12">
                            <label for="inputProductTitle" class="form-label">
                              Image:
                              <strong
                                >[Preferred Image Size: 561X599, Max Upload
                                Limit: 1MB]</strong
                              >
                            </label>
                          </div>
                          <div class="col-12">
                            <input
                              class="form-control mt-3"
                              type="file"
                              ref="backgroundImage1"
                              accept="image/*"
                              @change="
                                handleAddUploadImage(
                                  'image',
                                  'imageTwo',
                                  'backgroundImage1',
                                  $event
                                )
                              "
                            />
                          </div>
                        </div>
                        <div class="mt-3">
                          <img
                            style="position: relative; left: 11px"
                            v-if="preview.imageTwo"
                            :src="preview.imageTwo"
                            height="100"
                          />
                          <img
                            style="position: relative; left: 11px"
                            v-else-if="addNew.image"
                            :src="addNew.image"
                            height="100"
                          />
                        </div>
                      </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-primary">Add</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Add Modal -->
          <!-- Edit Modal -->
          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
          <form @submit.prevent="updateFaq">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Update Feedback</h5>
                    <button
                      type="button"
                      id="closeButton"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Name:</label>
                    <input
                          type="text"
                          v-model="single.name"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter name"
                        />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Occupation:</label>
                    <input
                          type="text"
                          v-model="single.occupation"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter occupation"
                        />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Rating:</label>
                    <input
                          type="number"
                          v-model="single.rating"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter rating"
                          max="5"
                        />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Description:</label>
                    <ckeditor
                      :editor="editor"
                      v-model="single.description"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                  <div class="modal-body">
                    <div class="col-12 mb-3">
                        <div class="row">
                          <div class="col-12">
                            <label for="inputProductTitle" class="form-label">
                              Image:
                              <strong
                                >[Preferred Image Size: 561X599, Max Upload
                                Limit: 1MB]</strong
                              >
                            </label>
                          </div>
                          <div class="col-12">
                            <input
                              class="form-control mt-3"
                              type="file"
                              ref="backgroundImage2"
                              accept="image/*"
                              @change="
                                handleUploadImage(
                                  'image',
                                  'imageOne',
                                  'backgroundImage2',
                                  $event
                                )
                              "
                            />
                          </div>
                        </div>
                        <div class="mt-3">
                          <img
                            style="position: relative; left: 11px"
                            v-if="preview.imageOne"
                            :src="preview.imageOne"
                            height="100"
                          />
                          <img
                            style="position: relative; left: 11px"
                            v-else-if="single.image"
                            :src="single.image"
                            height="100"
                          />
                        </div>
                      </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                      id="closeButton2"
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-primary">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Edit Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Feedback List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Occupation</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <span
                      class="metaModal"
                      v-html="
                        data.name
                          ? data.name.length > 50
                            ? data.name.slice(0, 30) + ' ...'
                            : data.name
                          : 'No question found.'
                      "
                    >
                    </span>
                  </td>
                  <td>
                    <span
                      v-html="
                        data.occupation
                          ? data.occupation.length > 50
                            ? data.occupation.slice(0, 30) + ' ...'
                            : data.occupation
                          : 'No answer found.'
                      "
                    >
                    </span>
                  </td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        @click="editFaq(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        class="edit-button"
                      >
                        <i class="bx bxs-edit text-white"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        @click="deleteFaq(data.id)"
                        class="ms-3 delete-button"
                        ><i class="bx bxs-trash text-white"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Question Modal -->
    <div
      class="modal fade"
      id="showModal"
      tabindex="-1"
      aria-labelledby="showModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="showModalLabel">Question</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p class="form-control mb-1" type="text" v-html="question"></p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Answer Modal -->
    <div
      class="modal fade"
      id="showModal2"
      tabindex="-1"
      aria-labelledby="showModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="showModalLabel">Answer</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p class="form-control mb-1" type="text" v-html="answer"></p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/customer-feedback-crud.js"></script>

<style scoped>
@import "../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.metaModal:hover {
  cursor: pointer;
  color: #09f;
}
</style>
