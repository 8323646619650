import LvColorpicker from 'lightvue/color-picker';

export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            url: this.$serverURL + this.$api.componentUI.homePageURL,
            cmsData: {},
            dataLoaded: false,
            colors: [
                "#E91E63",
                "#F44336", 
                "#9C27B0", 
                "#673AB7", 
                "#3F51B5", 
                "#2196F3", 
                "#03A9F4", 
                "#00BCD4", 
                "#009688", 
                "#4CAF50", 
                "#8BC34A", 
                "#CDDC39", 
                "#FFEB3B", 
                "#FFC107", 
                "#FF9800", 
                "#795548", 
                "#557736" 
            ]
        }
    },
    async created() {
        document.title = "Dispo - Home ComponentUI CMS";
        await this.getData();
        this.dataLoaded = true;
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async update() {
            try {
                let data = {
                    backgroundColor: this.cmsData.backgroundColor ? this.cmsData.backgroundColor : '#FFFFFF',
                    heroSectionShopNowButtonBackgroundColor : this.cmsData.heroSectionShopNowButtonBackgroundColor  ? this.cmsData.heroSectionShopNowButtonBackgroundColor  : '#76BD43',
                    heroSectionShopNowButtonFontColor: this.cmsData.heroSectionShopNowButtonFontColor ? this.cmsData.heroSectionShopNowButtonFontColor : '#FFFFFF',
                    heroSectionShopNowButtonHoverBackgroundColor: this.cmsData.heroSectionShopNowButtonHoverBackgroundColor ? this.cmsData.heroSectionShopNowButtonHoverBackgroundColor : '#557736',
                    heroSectionShopNowButtonHoverFontColor: this.cmsData.heroSectionShopNowButtonHoverFontColor ? this.cmsData.heroSectionShopNowButtonHoverFontColor : '#FFFFFF',
                    categorySectionTitleFontColor: this.cmsData.categorySectionTitleFontColor ? this.cmsData.categorySectionTitleFontColor : '#76BD43',
                    categorySectionSubtitleFontColor: this.cmsData.categorySectionSubtitleFontColor ? this.cmsData.categorySectionSubtitleFontColor : '#212529',
                    todaysSpecialSectionTitleFontColor: this.cmsData.todaysSpecialSectionTitleFontColor ? this.cmsData.todaysSpecialSectionTitleFontColor : '#76BD43',
                    todaysSpecialSectionSubTitleFontColor: this.cmsData.todaysSpecialSectionSubTitleFontColor ? this.cmsData.todaysSpecialSectionSubTitleFontColor : '#212529',
                    todaysSpecialSectionButtonBackgroundColor: this.cmsData.todaysSpecialSectionButtonBackgroundColor ? this.cmsData.todaysSpecialSectionButtonBackgroundColor : '#76BD43',
                    todaysSpecialSectionButtonFontColor: this.cmsData.todaysSpecialSectionButtonFontColor ? this.cmsData.todaysSpecialSectionButtonFontColor : '#FFFFFF',
                    todaysSpecialSectionShopAllButtonBackgroundColor: this.cmsData.todaysSpecialSectionShopAllButtonBackgroundColor ? this.cmsData.todaysSpecialSectionShopAllButtonBackgroundColor : '#76BD43',
                    todaysSpecialSectionShopAllButtonFontColor: this.cmsData.todaysSpecialSectionShopAllButtonFontColor ? this.cmsData.todaysSpecialSectionShopAllButtonFontColor : '#FFFFFF',
                    popularBrandSectionTitleFontColor: this.cmsData.popularBrandSectionTitleFontColor ? this.cmsData.popularBrandSectionTitleFontColor : '#76BD43',
                    popularBrandSectionSubtitleFontColor: this.cmsData.popularBrandSectionSubtitleFontColor ? this.cmsData.popularBrandSectionSubtitleFontColor : '#212529',
                    flowerSectionTitleFontColor: this.cmsData.flowerSectionTitleFontColor ? this.cmsData.flowerSectionTitleFontColor : '#76BD43',
                    flowerSectionSubtitleFontColor: this.cmsData.flowerSectionSubtitleFontColor ? this.cmsData.flowerSectionSubtitleFontColor : '#212529',
                    flowerSectionButtonBackgroundColor: this.cmsData.flowerSectionButtonBackgroundColor ? this.cmsData.flowerSectionButtonBackgroundColor : '#76BD43',
                    flowerSectionButtonFontColor: this.cmsData.flowerSectionButtonFontColor ? this.cmsData.flowerSectionButtonFontColor : '#FFFFFF',
                    vaporizersSectionTitleFontColor: this.cmsData.vaporizersSectionTitleFontColor ? this.cmsData.vaporizersSectionTitleFontColor : '#76BD43',
                    vaporizersSectionSubTitleFontColor: this.cmsData.vaporizersSectionSubTitleFontColor ? this.cmsData.vaporizersSectionSubTitleFontColor : '#212529',
                    vaporizersSectionButtonBackgroundColor: this.cmsData.vaporizersSectionButtonBackgroundColor ? this.cmsData.vaporizersSectionButtonBackgroundColor : '#76BD43',
                    vaporizersSectionButtonFontColor: this.cmsData.vaporizersSectionButtonFontColor ? this.cmsData.vaporizersSectionButtonFontColor : '#FFFFFF',
                    brandSectionTitleFontColor: this.cmsData.brandSectionTitleFontColor ? this.cmsData.brandSectionTitleFontColor : '#76BD43',
                    brandSectionSubtitleFontColor: this.cmsData.brandSectionSubtitleFontColor ? this.cmsData.brandSectionSubtitleFontColor : '#212529',
                    dispoDifferenceSectionTitleFontColor: this.cmsData.dispoDifferenceSectionTitleFontColor ? this.cmsData.dispoDifferenceSectionTitleFontColor : '#76BD43',
                    dispoDifferenceSectionSubtitleFontColor: this.cmsData.dispoDifferenceSectionSubtitleFontColor ? this.cmsData.dispoDifferenceSectionSubtitleFontColor : '#212529',
                    dispoDifferenceSubSectionOneTitleFontColor: this.cmsData.dispoDifferenceSubSectionOneTitleFontColor ? this.cmsData.dispoDifferenceSubSectionOneTitleFontColor : '#214842',
                    dispoDifferenceSubSectionOneSubtitleFontColor: this.cmsData.dispoDifferenceSubSectionOneSubtitleFontColor ? this.cmsData.dispoDifferenceSubSectionOneSubtitleFontColor : '#212529',
                    dispoDifferenceSubSectionTwoTitleFontColor: this.cmsData.dispoDifferenceSubSectionTwoTitleFontColor ? this.cmsData.dispoDifferenceSubSectionTwoTitleFontColor : '#214842',
                    dispoDifferenceSubSectionTwoSubtitleFontColor: this.cmsData.dispoDifferenceSubSectionTwoSubtitleFontColor ? this.cmsData.dispoDifferenceSubSectionTwoSubtitleFontColor : '#212529',
                    dispoDifferenceSliderArrowColor: this.cmsData.dispoDifferenceSliderArrowColor ? this.cmsData.dispoDifferenceSliderArrowColor : '#000000',
                    dispoDifferenceSliderArrowBackgroundColor: this.cmsData.dispoDifferenceSliderArrowBackgroundColor ? this.cmsData.dispoDifferenceSliderArrowBackgroundColor : '#ffffff',
                    reeferAFriendSectionTitleFontColor: this.cmsData.reeferAFriendSectionTitleFontColor ? this.cmsData.reeferAFriendSectionTitleFontColor : '#76BD43',
                    reeferAFriendSectionSubtitleFontColor: this.cmsData.reeferAFriendSectionSubtitleFontColor ? this.cmsData.reeferAFriendSectionSubtitleFontColor : '#212529',
                    instagramSectionTitleFontColor: this.cmsData.instagramSectionTitleFontColor ? this.cmsData.instagramSectionTitleFontColor : '#76BD43',
                    instagramSectionSubtitleFontColor: this.cmsData.instagramSectionSubtitleFontColor ? this.cmsData.instagramSectionSubtitleFontColor : '#212529',
                    footerSectionBackgroundColor: this.cmsData.footerSectionBackgroundColor ? this.cmsData.footerSectionBackgroundColor : '#557736',
                }
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}