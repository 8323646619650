export default {
    data() {
        return {
            siteUrl: this.$serverURL + this.$api.cms.productDetailsMeta,
            metaTitleList: [
                { value: 'name', label: 'name' },
                { value: 'category', label: 'category' },
                { value: 'brand', label: 'brand' },
                { value: 'storeName', label: 'storeName' }
            ],
            selectedMetaTitles: [],
            selectedTitles: "",
            metaDescriptionList: [
                { value: 'description', label: 'description' },
                { value: 'productCannabisType', label: 'productCannabisType' },
                { value: 'metaTitle', label: 'metaTitle' },
            ],
            selectedMetaDescriptions: [],
            selectedDescriptions: "",
            productSchema: ""
        }
    },
    async mounted() {
        document.title = "Dispo - Product Meta Mapping";
    },
    async created() {
        const pageData = await this.getCmsData(this.siteUrl);
        if(pageData?.data?.length) {
            this.selectedTitles = pageData?.data?.[0]?.metaTitles;
            this.selectedDescriptions = pageData?.data?.[0]?.metaDescriptions;
            this.productSchema = pageData?.data?.[0]?.productSchema;
        }
    },
    methods: {
        async updatePageDetailsMeta() {
            try {
                if(!this.selectedTitles) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter meta titles!"
                    });
                } else if(!this.selectedDescriptions) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter meta descriptions!"
                    });
                } else {
                    const data = {
                        metaTitles: this.selectedTitles,
                        metaDescriptions: this.selectedDescriptions,
                        productSchema: this.productSchema
                    };
                    const res = await this.createUpdateCMS(this.siteUrl, data);
                    if (res.statusCode == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: res.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }

                    const pageData = await this.getCmsData(this.siteUrl);
                    if(pageData?.data?.length) {
                        this.selectedTitles = pageData?.data?.[0]?.metaTitles;
                        this.selectedDescriptions = pageData?.data?.[0]?.metaDescriptions;
                        this.productSchema = pageData?.data?.[0]?.productSchema;
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        },
    },
    watch: {
        selectedMetaTitles(newValue) {
            const value = newValue.map(x => `{{ ${x} }}`);
            this.selectedTitles = value.join(" | ");
        },
        selectedMetaDescriptions(newValue) {
            const value = newValue.map(x => `{{ ${x} }}`);
            this.selectedDescriptions = value.join(" - ");
        }
    }
}