import { authHeader } from '../../../auth';
import draggable from 'vuedraggable';

export default {
    name: "Store",
    components: {
        draggable
    },
    data() {
        return {
            retailerId: this.$route.query.id,
            retailerName: this.$route.query.name,
            dataList: [],
            setURL: this.$serverURL + this.$api.brand.requestURL,
            dragging: false,
            singleBrand: {},
            updateImage: "",
            previewImage: "",
            tempDataList: [],
            searchKey: ""
        }
    },
    async created() {
        document.title = "Dispo - Brands List";
        await this.getBrandList();
        await this.updateOrder();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getBrandList: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.setURL + "?retailerId=" + this.retailerId,
                }
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.dataList = response.data.data;
                        this.tempDataList = response.data.data;
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        searchBrand() {
            let newArr = [...this.dataList];
            let searchVal = this.searchKey.toUpperCase();
            let filteredArr = [];
            newArr.forEach((el) => {
                let brandName = el?.brandName?.toUpperCase();
                if (brandName.includes(searchVal)) {
                    filteredArr.push(el);
                }
            });
            this.tempDataList = filteredArr;
        },
        handleImageStatus(data, event) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Change Status'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const payLoad = {
                            "isShowBrandInHome": data?.isShowBrandInHome,
                            "isShowBrand": data.isShowBrand,
                            "isShowBrandInCategory": data.isShowBrandInCategory,
                            "isShowCustomBrandImage": event.target.checked,
                        }
                        let config = {
                            method: 'PATCH',
                            url: this.$serverURL + this.$api.brand.updateStatus + '/' + data.id,
                            headers: authHeader(),
                            data: payLoad,
                        }
                        await this.updateData(config);
                        this.getBrandList();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                    return;
                } else {
                    event.target.checked = !event.target.checked;
                    data.isShowCustomBrandImage = event.target.checked;
                }
            })
        },
        handleStatusChangeHomePage(data, event) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Change Status'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const payLoad = {
                            "isShowBrandInHome": event.target.checked,
                            "isShowBrand": data.isShowBrand,
                            "isShowBrandInCategory": data.isShowBrandInCategory,
                            "isShowCustomBrandImage": data.isShowCustomBrandImage,
                        }
                        let config = {
                            method: 'PATCH',
                            url: this.$serverURL + this.$api.brand.updateStatus + '/' + data.id,
                            headers: authHeader(),
                            data: payLoad,
                        }
                        await this.updateData(config);
                        this.getBrandList();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                    return;
                } else {
                    event.target.checked = !event.target.checked;
                }
            })
        },
        handleStatusChangeBrandPage(data, event) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Change Status'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const payLoad = {
                            "isShowBrandInHome": data?.isShowBrandInHome,
                            "isShowBrand": event.target.checked,
                            "isShowBrandInCategory": data.isShowBrandInCategory,
                            "isShowCustomBrandImage": data.isShowCustomBrandImage,
                        }
                        let config = {
                            method: 'PATCH',
                            url: this.$serverURL + this.$api.brand.updateStatus + '/' + data.id,
                            headers: authHeader(),
                            data: payLoad,
                        }
                        await this.updateData(config);
                        this.getBrandList();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                    return;
                } else {
                    event.target.checked = !event.target.checked;
                }
            })
        },
        handleStatusChangeCategoryPage(data, event) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Change Status'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const payLoad = {
                            "isShowBrandInHome": data?.isShowBrandInHome,
                            "isShowBrand": data.isShowBrand,
                            "isShowBrandInCategory": event.target.checked,
                            "isShowCustomBrandImage": event.target.isShowCustomBrandImage,
                        }
                        let config = {
                            method: 'PATCH',
                            url: this.$serverURL + this.$api.brand.updateStatus + '/' + data.id,
                            headers: authHeader(),
                            data: payLoad,
                        }
                        await this.updateData(config);
                        this.getBrandList();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                    return;
                } else {
                    event.target.checked = !event.target.checked
                }
            })
        },
        async updateOrder() {
            try {
                const newOrderList = this.tempDataList.map((el, index) => {
                    return { id: el.id, position: index + 1 }
                })
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.brand.brandpositionUrl,
                    headers: authHeader(),
                    data: {
                        newOrderList
                    },
                }
                await this.postData(config).then(async (response) => {
                    if (response.statusCode == 200) {
                        await this.getBrandList();
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        async onDragUpdate(event) {
            event
            await this.updateOrder();
        },
        editBrandData(data) {
            this.singleBrand = data;
        },
        onImageChange(event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.editImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        async updateBrandImage() {
            const formData = new FormData();
            formData.append("customBrandImage", this.updateImage ? this.updateImage : this.singleBrand.customBrandImage);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.brand.brandImageUpdateUrl + '/' + this.singleBrand.id,
                data: formData,
                headers: authHeader()
            };
            this.$swal.showLoading();
            await this.$axios(config).then(async (response) => {
                this.$swal.close();
                if (response.data.statusCode == 200) {
                    this.singleBrand = {};
                    this.$refs.editImage.value = "";
                    this.previewImage = "";
                    await this.getBrandList();
                    document.getElementById("close-btn").click();
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    },
    watch: {
        searchKey: function () {
            this.searchBrand();
        }
    }
}