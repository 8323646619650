import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { authHeader } from "../../../auth";
import Editor from '@tinymce/tinymce-vue';
import Multiselect from '@vueform/multiselect';

export default {
    name: "AddBlogPost",
    components: {
        Editor,
        Multiselect
    },
    data() {
        return {
            blogList: [],
            blogCategory: "",
            blogTags: "",
            tagOptions: [],
            selectedPostImage: null,
            title: null,
            urlSlug: "",
            imgAlt: "",
            pageTitle: "",
            metaDescription: "",
            shortDetails: null,
            longDetails: null,
            selectedCategory: {},
            selectedBlogTags: [],
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            previewImage: null
        }
    },
    async created() {
        document.title = "Dispo - Add Blog Post";
        var scripts = [
            "/core/assets/js/jquery.min.js",
            "/core/assets/plugins/simplebar/js/simplebar.min.js",
            "/core/assets/plugins/metismenu/js/metisMenu.min.js",
            "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
            "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
            "/core/assets/js/form-wizard.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        await this.getBlogList();
        await this.getBlogCategory();
        await this.getBlogTags();
    },
    methods: {
        getBlogList: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.postURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getBlogCategory: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.categoryURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogCategory = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getBlogTags: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.tagURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogTags = response.data.data;
                    this.tagOptions = response.data.data.map((el) => {
                        return { value: el.tagName, label: el.tagName };
                    });
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onPostImageChange(event) {
            this.selectedPostImage = event.target.files[0];
            let input = this.$refs.postImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate: function() {
            if(!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter blog title!"
                });
                return false;
            }
            if(!this.urlSlug) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Post URL Slug!"
                });
                return false;
            }
            if(!this.shortDetails) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter short details!"
                });
                return false;
            }
            if(!this.longDetails) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter long details!"
                });
                return false;
            }
            if(!this.selectedPostImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload blog image!"
                });
                return false;
            }
            if(Object.keys(this.selectedCategory).length === 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select blog category!"
                });
                return false;
            }
            if(this.selectedBlogTags.length == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select blog tags!"
                });
                return false;
            }
            return true;
        },
        save: async function() {
            if(this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("title", this.title);
                formData.append("urlSlug", this.urlSlug);
                formData.append("canonicalTag", this.canonicalTag);
                formData.append("imgAlt", this.imgAlt);
                formData.append("pageTitle", this.pageTitle);
                formData.append("metaDescription", this.metaDescription);
                formData.append("shortDetails", this.shortDetails);
                formData.append("longDetails", this.longDetails);
                formData.append("categoryId", this.selectedCategory.id);
                formData.append("categoryName", this.selectedCategory.name);
                formData.append("photo", this.selectedPostImage);
                formData.append('tags', JSON.stringify(this.selectedBlogTags));
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.blog.postURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.title = "";
                    this.urlSlug = "";
                    this.shortDetails = "";
                    this.longDetails = "";
                    this.selectedCategory = "";
                    this.$refs.postImage.value = null;
                    this.selectedPostImage = "";
                    this.$router.push("/blog-post");
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}