<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Hero Section Slider</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Hero Section Slider</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Add New Slider</h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3" v-if="retailerName != 'default'">
                                        <label class="form-label">Retailer Name:</label>
                                        <input type="text" v-model="retailerName" class="form-control" disabled>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Image: [Preferred Icon Size: 1920X661, Max Upload Limit:
                                            1MB]</label>
                                        <input class="form-control" @change="onImageChange" type="file" ref="image">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-3">
                                            <img v-if="previewImage" :src="previewImage" height="100" />
                                            <img v-else src="../../../../assets/images/default.png" height="100" />
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Mobile Image: [Preferred Icon Size: 1412X724, Max Upload
                                            Limit: 1MB]</label>
                                        <input class="form-control" @change="onMobileImageChange" type="file"
                                            ref="mobileImage">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-3">
                                            <img v-if="previewMobileImage" :src="previewMobileImage" height="100" />
                                            <img v-else src="../../../../assets/images/default.png" height="100" />
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Title:</label>
                                        <input type="text" v-model="title" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Description:</label>
                                        <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Link:</label>
                                        <input type="text" v-model="link" class="form-control">
                                    </div>
                                    <div class="col-12 col-sm-6 mb-3">
                                        <label class="form-label">Start Date:</label>
                                        <input v-model="startDate" type="date" class="form-control" />
                                    </div>
                                    <div class="col-12 col-sm-6 mb-3">
                                        <label class="form-label">End Date:</label>
                                        <input v-model="endDate" type="date" class="form-control" />
                                    </div>
                                    <div class="col-6 mb-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Is Active: </label>
                                            <div class="form-check form-switch mt-1">
                                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                                                    v-model="isActive" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="save" type="button" class="btn btn-primary">Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/add-hero-section-slider.js"></script>