<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Blog Post</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								Blog Post
							</li>
						</ol>
					</nav>
				</div>
				<div class="ms-auto">
					<div class="btn-group">
						<button type="button" class="btn btn-primary" @click="$router.push('/blog-post')">
							<i class="bx bx-coin-stack"></i>Blog Post List
						</button>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-xl-12 mx-auto">
							<h6 class="mt-2 mb-2 text-uppercase">Create New Blog</h6>
							<hr/>
							<div class="form-body mt-4">
								<div class="row mb-3">
									<div class="col-lg-8">
										<div class="border border-3 p-1 rounded">
											<div class="card-body p-5">
												<div class="card-title d-flex align-items-center">
													<div><i class="bx bxs-user me-1 font-22 text-primary"></i></div>
													<h5 class="mb-0 text-primary">Enter Blog Information</h5>
												</div>
												<hr>
												<form class="row g-3">
													<div class="col-md-12">
														<label for="postTitle" class="form-label">Post Title:</label>
														<input type="text" v-model="title" class="form-control">
													</div>
													<div class="col-md-12">
														<label for="postTitle" class="form-label">Post URL Slug:</label>
														<input type="text" v-model="urlSlug" class="form-control">
													</div>
													<div class="col-md-12">
														<label for="postTitle" class="form-label">Page Title:</label>
														<input type="text" v-model="pageTitle" class="form-control">
													</div><div class="col-md-12">
														<label for="postTitle" class="form-label">Meta Description:</label>
														<input type="text" v-model="metaDescription" class="form-control">
													</div>
													<div class="col-md-12">
														<label for="postTitle" class="form-label">Canonical URL:</label>
														<input type="text" v-model="canonicalTag" class="form-control">
													</div>
													<div class="col-12">
														<label for="shortDetails" class="form-label">Short Details:</label>
														<textarea class="form-control" v-model="shortDetails" rows="5"></textarea>
													</div>
													<div class="col-12 mb-3">
														<label class="form-label">Long Details:</label>
														<ckeditor :editor="editor" v-model="longDetails" :config="editorConfig"></ckeditor>
													</div>
													<div class="col-md-12">
														<label class="form-label">Upload Post Image: [Image Size: 1920X1280, Max Limit: 1MB]</label>
														<input class="form-control" type="file" ref="postImage" @change="onPostImageChange"/>
													</div>
                                                    <div class="col-md-12">
                                                        <img v-if="previewImage" :src="previewImage" width="400"/>
                                                        <img v-else src="../../../assets/images/default.png" height="100" width="150"/>
                                                    </div>
													<div class="col-12">
														<label for="imgAlt" class="form-label">Image Alt:</label>
														<input type="text" v-model="imgAlt" class="form-control">
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-8">
										<div class="border border-3 p-1 rounded">
											<div class="card-body p-5">
												<form class="row g-3">
													<div class="col-md-12">
														<label class="form-label">Category:</label>
														<select v-model="selectedCategory" class="form-select form-control mb-3" >
															<option class="p-1" v-for="(row, index) in blogCategory" :key="index" :value="{ id: row.id, name: row.categoryName }">
																{{ row.categoryName }}
															</option>
														</select>
													</div>
													<div class="col-md-12">
														<label class="form-label">Blog Tag:</label>
														<!-- <Multiselect v-model="selectedBlogTags" :options="tagOptions" mode="tags" :close-on-select="false" :searchable="true" :create-option="true"/> -->
														<Multiselect v-model="selectedBlogTags" :options="tagOptions" mode="tags" :close-on-select="false" :searchable="true" />
													</div>
												</form>
											</div>
										</div>
										<div class="d-grid mt-5">
											<button v-on:click="save" type="button" class="btn btn-primary">Create</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
@import '../../../../public/core/assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css';
@import '../../../../public/core/assets/plugins/metismenu/css/metisMenu.min.css';
@import '../../../../public/core/assets/plugins/smart-wizard/css/smart_wizard_all.min.css';
.custom-margin {
	margin-top: 40px;
}
.custom-margin-label {
	margin-top: 36px;
}
</style>

<script src="../js/add-blog-post.js"></script>
