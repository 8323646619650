<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Hero Section Left CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Hero Section Left CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <div style="display:flex; justify-content:space-between">
              <h5 class="card-title">Hero Section Left CMS</h5>
              <div class="d-flex order-actions">
                <div
                  style="display: flex; justify-content: center; align-items: center;cursor:pointer;"
                  @click="$router.push({ path: '/home-hero-section-left-cms-list'})"
                >
                  <span style="margin: 0 auto;">
                    <i
                      class="bx bxs-arrow-to-left text-dark"
                      style="font-size: 30px; margin: 0 auto;"
                    ></i>
                  </span>
                  <h6 style="margin: 0; cursor:pointer; font-weight:600">Go Back</h6>
                </div>
              </div>
            </div>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-8 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label class="form-label">Retailer Name:</label>
                        <input
                          type="text"
                          v-model="cmsData.retailerName"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          disabled
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.title"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter title"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Description:</label>
                        <ckeditor
                          :editor="editor"
                          v-model="cmsData.description"
                          :config="editorConfig"
                        ></ckeditor>
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Button Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonText"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter button text"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Button Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonLink"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter button link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-grid col-lg-8">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/home-hero-section-left-cms.js"></script>

<style scoped>
.edit-button {
  background-color: #17a00e;
}
</style>