import { authHeader } from '../../../auth';
export default {
    name: "HeaderBannerList",
    data() {
        return {
            dataList: [],
            banner: "",
            link: "",
            singleData: {},
            retailerId: this.$route.params.retailerId,
            retailerName: this.$route.params.retailerName,
            setURL: `${this.$serverURL}${this.$api.cms.headerBanner}/retailer/${this.$route.params.retailerId}`,
            setHeaderBannerURL: `${this.$serverURL}${this.$api.cms.headerBanner}`,
        }
    },
    async created() {
        document.title = "Dispo | Header Banner List";
        await this.getData();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        async getData() {
            this.dataList  = await this.getDataList(this.setURL);
        },
        async updateModalValue(data) {
            this.singleData.banner = data.banner;
            this.singleData.link = data.link;
            this.singleData.id = data.id;
            this.singleData.retailerId = data.retailerId;
            this.singleData.retailerName = data.retailerName;
        },
        // async handleSetDefault() {
        //     let token = localStorage.getItem('token');
        //     let data = {
        //         retailerId: this.retailerId,
        //     }
        //     let config = {
        //         method: "POST",
        //         url: this.$serverURL + this.$api.cms.home.setDefaultSlider,
        //         data: data,
        //         headers: {
        //             "Authorization": "Bearer " + token,
        //         }
        //     };
        //     await this.$axios(config).then(async (response) => {
        //         if (response.status == 200) {
        //             this.$swal.fire({
        //                 toast: true,
        //                 position: 'top-end',
        //                 text: response.data.message,
        //                 icon: "success",
        //                 showConfirmButton: false,
        //                 timer: 3000,
        //                 animation: false,
        //                 timerProgressBar: true,
        //                 didOpen: (toast) => {
        //                     toast.addEventListener('mouseenter', this.$swal.stopTimer)
        //                     toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        //                 }
        //             });
        //         }
        //         this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
        //     }).catch(error => {
        //         console.log(error);
        //     });
        // },
        checkValidation: function () {
            if (!this.banner) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter banner description!"
                });
                return false;
            }
            if (!this.link) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter link!"
                });
                return false;
            }
            return true;
        },
        checkValidationEdit: function () {
            if (!this.singleData.banner) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter banner description!"
                });
                return false;
            }
            if (!this.singleData.link) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter link!"
                });
                return false;
            }
            return true;
        },
        addBanner: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    const payload = {
                        banner: this.banner,
                        link: this.link,
                        retailerId: this.retailerId,
                        retailerName: this.retailerName,
                    }

                    const config = {
                        method: 'POST',
                        url: this.setHeaderBannerURL,
                        data: payload,
                        headers: {
                            ...authHeader(),
                        }
                    };
                    await this.$axios(config).then(async (response) => {
                        if (response.status == 201) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }
                        await document.getElementById("add-btn-close").click();
                        this.$router.go(0);
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        },
        editBanner: async function () {
            if (this.checkValidationEdit()) {
                try {
                    this.$swal.showLoading();
                    const payload = {
                        banner: this.singleData.banner,
                        link: this.singleData.link,
                        retailerId: this.singleData.retailerId,
                        retailerName: this.singleData.retailerName,
                    }

                    const config = {
                        method: 'PATCH',
                        url: this.setHeaderBannerURL + `/${this.singleData.id}`,
                        data: payload,
                        headers: {
                            ...authHeader(),
                        }
                    };
                    await this.$axios(config).then(async (response) => {
                        if (response.status == 200) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }
                        await document.getElementById("edit-btn-close").click();
                        this.$router.go(0);
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        },
        deleteContent: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setHeaderBannerURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteData(config);
                        await this.getData();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })
        }
    }
}