import { authHeader } from '../../../../auth';

export default {
    name: "WhyJoinUsPage",
    data() {
        return {
            dataList: [],
            setURL: this.$serverURL + this.$api.cms.contactUsSectionOneURLDefault,

            //for create
            retailerName: this.$route.query.name,
            retailerId: this.$route.query.id,
            title: '',
            subTitle: '',
            type: '',
            buttonText: '',
            buttonLink: '',
            icon: '',
            previewIcon: null,

            update: {
                title: '',
                subTitle: '',
                type:'',
                buttonText: '',
                buttonLink: '',
                iconFile: '',
                previewIcon: '',
                icon: '',
                id: ''
            },
            typeList: ['contact', 'email', 'chat'],
        }
    },
    async created() {
        document.title = "Dispo - Contact Us Section One";
        await this.getData();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {

        async getData() {
            this.dataList = await this.getDataList(this.setURL + "?retailerId=" + this.id);
        },

        async updateModalValue(subTitle) {
            this.subTitle = subTitle;
        },

        //for creating
        onIconChange: function (event) {
            this.icon = event.target.files[0];
            let input = this.$refs.icon;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewIcon = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if (!this.subTitle) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter subtitle!"
                });
                return false;
            }
            if (!this.type) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter type!"
                });
                return false;
            }
            if (!this.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter buttonText!"
                });
                return false;
            }
            if (!this.buttonLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter buttonLink!"
                });
                return false;
            }
            if (!this.icon) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload icon!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("icon", this.icon);
                formData.append("title", this.title);
                formData.append("subTitle", this.subTitle);
                formData.append("type", this.type);
                formData.append("buttonText", this.buttonText);
                formData.append("buttonLink", this.buttonLink);
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.title = '';
                    this.subTitle = '';
                    this.type = '';
                    this.buttonText = '';
                    this.buttonLink = '';
                    this.$refs.icon.value = null;
                    this.previewIcon = "";
                    // this.$$router.push({ path: '/section-content-list', query: { id: this.retailerId, name: this.retailerName } });
                    document.getElementById('addModalCloseButton').click();
                    await this.getData();

                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },

        //edit section

        handleEdit(data) {
            this.update = {
                title: data.title,
                subTitle: data.subTitle,
                type: data.type,
                buttonText: data.buttonText,
                buttonLink: data.buttonLink,
                iconFile: "",
                previewIcon: "",
                icon: data.icon,
                id: data.id
            };

        },

        onUpdateIconChange(event) {
            this.update.iconFile = event.target.files[0];
            let input = this.$refs.updateIcon;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.update.previewIcon = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },

        updateContent: async function () {
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("icon", this.update.iconFile ? this.update.iconFile : this.update.icon);
            formData.append("retailerId", this.retailerId);
            formData.append("retailerName", this.retailerName);
            formData.append("title", this.update.title);
            formData.append("subTitle", this.update.subTitle);
            formData.append("type", this.update.type);
            formData.append("buttonText", this.update.buttonText);
            formData.append("buttonLink", this.update.buttonLink);
            let config = {
                method: "PATCH",
                url: this.setURL + '/' + this.update.id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.dataList = [];
                this.update = {
                    title: '',
                    subTitle: '',
                    type: '',
                    buttonText: '',
                    buttonLink: '',
                    iconFile: '',
                    previewIcon: '',
                    icon: '',
                    id: ''
                }
                document.getElementById("updateModalCloseButton").click();
                await this.getData();
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },




        deleteContent: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteData(config);
                        await this.getData();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}