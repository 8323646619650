import { authHeader } from '../../../../auth';
import draggable from 'vuedraggable';
export default {
    name: "HeroSectionSlider",
    components: {
        draggable
    },
    data() {
        return {
            dataList: [],
            description: "",
            retailerId: this.$route.params.retailerId,
            retailerName: this.$route.params.retailerName,
            setURL: `${this.$serverURL}${this.$api.cms.home.heroSectionSliderRetailer}/${this.$route.params.retailerId}`,
            setHeroURL: `${this.$serverURL}${this.$api.cms.home.heroSectionSlider}`,
            tempDataList: [],
        }
    },
    async created() {
        document.title = "Dispo - Home | Hero Section Slider";
        await this.getData();
        await this.updateOrder();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    computed: {
        isDefault() {
            let newArr = this.dataList.filter((el) => (el.isDefault == true));
            return (newArr.length == this.dataList.length) ? true : false;
        },
    },
    methods: {
        async getData() {
            let data = await this.getDataList(this.setURL);
            this.dataList = data;
            this.tempDataList = data;
        },
        async updateModalValue(description) {
            this.description = description;
        },
        async updateOrder() {
            try {
                const newOrderList = this.tempDataList.map((el, index) => {
                    return { id: el.id, position: index + 1 }
                })
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.home.homeSliderReOrder,
                    headers: authHeader(),
                    data: {
                        newOrderList
                    },
                }
                await this.postData(config).then(async (response) => {
                    if (response.statusCode == 200) {
                        await this.getData();
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        async onDragUpdate(event) {
            event
            await this.updateOrder();
        },
        async handleSetDefault() {
            let token = localStorage.getItem('token');
            let data = {
                retailerId: this.retailerId,
            }
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.cms.home.setDefaultSlider,
                data: data,
                headers: {
                    "Authorization": "Bearer " + token,
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
            }).catch(error => {
                console.log(error);
            });
        },
        deleteContent: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setHeroURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteData(config);
                        await this.getData();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}